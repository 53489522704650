import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Browser } from '@capacitor/browser';
import { ModalController, NavController } from '@ionic/angular';
import { ActionsModalDto } from 'src/app/dto/shared.dto';
import { RtsGeneratorService } from 'src/app/services/docs-service/rts-generator.service';

@Component({
  selector: 'app-actions-modal',
  templateUrl: './actions-modal.component.html',
  styleUrls: ['./actions-modal.component.scss']
})
export class ActionsModalComponent {
  @Input() data: ActionsModalDto;
  isToastOpen = false;
  acceptExpeditorTerms = false;

  constructor(
    private modalCtrl: ModalController,
    private router: Router,
    private navCtrl: NavController,
    private rtsGeneratorService: RtsGeneratorService
  ) { }

  getTitleStyles() {
    if (this.data.errorTitle) {
      return {
        color: '#F8627D'
      };
    }
    return {};
  }

  confirm() {
    return this.modalCtrl.dismiss(null, 'confirm');
  }

  reject() {
    return this.modalCtrl.dismiss(null, 'reject');
  }

  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  addCarsToDay() {
    this.navCtrl.navigateForward(`${window.location.pathname}/add-new-car`);
    this.cancel();
  }

  setOpen(isOpen: boolean) {
    this.isToastOpen = isOpen;
  }

  async exportPDF() {
    this.setOpen(true);
  }

  async exportXLS() {
    if (this.data.carData && this.data.orderData) {
      await this.rtsGeneratorService.getTtn(
        this.data.carData,
        this.data.orderData
      );
      this.setOpen(true);
    }
  }

  openExpeditorTerms() {
    this.router.navigate(['expeditorTerms']);
    this.cancel();
  }

  async openFile() {
    await Browser.open({
      url: 'https://api.smartseeds.ru/uploads/2023/06/6499a9f98de17.pdf'
    });
  }
}
