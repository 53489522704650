import { Component, ViewChild } from '@angular/core';
import { MenuTabChangeService } from 'src/app/services/menu-tab-change.service';

@Component({
  selector: 'app-menu-tab',
  templateUrl: './menu-tab.component.html',
  styleUrls: ['./menu-tab.component.scss']
})
export class MenuTabComponent {
  @ViewChild('tabs') tabs: any;
  selectedTab: string;

  constructor(private tabChangeService: MenuTabChangeService) {}

  setCurrentTab() {
    this.selectedTab = this.tabs.getSelected();
    this.tabChangeService.refreshData.next(this.selectedTab);
  }
}
