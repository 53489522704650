import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigService } from './config.service';

export interface RemoveAccountResponse {
  success: boolean;
  data: {
    businessKey: string;
  }
}

export type UserRemovalStatus = 'Failed' | 'Completed' | 'InProgress';

export interface UserRemovalStatusResponse {
  success: boolean;
  data: {
    status: UserRemovalStatus
  };
}

export interface LoginResponse {
  success: boolean;
  data: {
    access_token: string;
    expires_in: number;
    refresh_token: string;
    refresh_expires_in: number;
  };
}

export interface UserDataResponce {
  success: boolean;
  data: {
    contact: string;
    email: string;
    firstName: string;
    id: number;
    inHartia: string | null;
    inn: string;
    isRegistered: boolean;
    keycloakId: string;
    lastName: string;
    name: string;
    ogrn: string;
    patronymicName: string;
    phone: string;
    roles: string[];
    type: string;
  };
}

export enum Platform {
  WEB = 'Web',
  IOS = 'iOS',
  ANDROID = 'Android',
  HUAWEI = 'Huawei'
}

@Injectable({
  providedIn: 'root'
})
export class UserManagementService {
  private baseRestURL = '';

  constructor(private http: HttpClient, private configService: ConfigService) {
    this.baseRestURL = configService.baseRestURL;
  }

  public login(
    user: string,
    password: string,
    deviceToken: string,
    platformName: string,
    group: string
  ) {
    let platform;
    switch (platformName) {
      case 'ios':
        platform = Platform.IOS;
        break;
      case 'android':
        platform = Platform.ANDROID;
        break;
      case 'huawei':
        platform = Platform.HUAWEI;
        break;
      default:
        platform = Platform.WEB;
        break;
    }

    const body = {
      user,
      password,
      deviceToken,
      platform,
      group
    };

    return this.http.post<LoginResponse>(
      `${this.baseRestURL}usermanagement/registration/platform-login`,
      body
    );
  }

  public resetPassword(email: string) {
    const params = new HttpParams().set('email', email);

    return this.http.post<LoginResponse>(
      `${this.baseRestURL}usermanagement/registration/reset-password`,
      {},
      { params, observe: 'response' }
    );
  }

  public refreshToken(refreshToken: string): Observable<any> {
    const curUrl = `${this.baseRestURL}usermanagement/registration/refresh-token`;
    const params = new HttpParams()
      .set('refreshToken', refreshToken);
    return this.http.post<any>(curUrl, params);
  }

  public killAuthSession(refreshToken: string): Observable<any> {
    const curUrl = `${this.baseRestURL}usermanagement/registration/v2/logout`;
    const params = new HttpParams()
      .set('refreshToken', refreshToken);
    return this.http.post(curUrl, params);
  }

  public getUserData() {
    return this.http.get<UserDataResponce>(`${this.baseRestURL}personal/main`);
  }

  public removeAccount(): Observable<RemoveAccountResponse> {
    return this.http.delete<RemoveAccountResponse>(
      `${this.baseRestURL}usermanagement/self-management/self-remove`
    );
  }

  public fetchRemovalStatus(businessKey: string): Observable<UserRemovalStatusResponse> {
    return this.http.get<UserRemovalStatusResponse>(
      `${this.baseRestURL}usermanagement/self-management/removal-status/${encodeURIComponent(businessKey)}`
    );
  }
}
