import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth-service.service';
import { RegistrationService } from 'src/app/services/registration.service';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  presetEmail?: string;
  private PasswordSymbolsPattern: RegExp = /^\S*$/;

  currentStep = 1;
  emailAddress: string = '';
  digits = [0, 1, 2, 3, 4, 5];
  otp: string[] = ['', '', '', '', '', ''];
  submitCodeErrorFlag: boolean = false;
  changePasswordError: boolean;
  requestAlreadySent = false;
  emailForm: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email])
  });

  passwordForm: FormGroup = new FormGroup({
    password: new FormControl('', [
      Validators.required,
      Validators.pattern(this.PasswordSymbolsPattern),
    ]),

    passwordRepeat: new FormControl('', [
      Validators.required,
      Validators.pattern(this.PasswordSymbolsPattern),
    ])
  });

  constructor(
    private router: Router,
    private authService: AuthService,
    private route: ActivatedRoute,
    private registrationService: RegistrationService
  ) {
    const passwordControl = this.passwordForm.get('password');
    const passwordRepeatControl = this.passwordForm.get('passwordRepeat');

    passwordControl!.valueChanges.subscribe(() => {
      this.checkPasswordMatch();
    });

    passwordRepeatControl!.valueChanges.subscribe(() => {
      this.checkPasswordMatch();
    });
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.presetEmail = params['email'];
      if (this.presetEmail) {
        this.emailForm.get('email')?.setValue(this.presetEmail);
        this.requestResetPassword();
      }
    });
  }

  checkPasswordMatch() {
    const password = this.passwordForm.get('password')?.value;
    const passwordRepeat = this.passwordForm.get('passwordRepeat')?.value;

    const regex = /^\S*$/; // Регулярное выражение
    let regexFlag = true;

    if (!password || !passwordRepeat || !regex.test(password) || !regex.test(passwordRepeat)) {
      regexFlag = false;
    }

    if (password === passwordRepeat && regexFlag) {
      this.passwordForm.get('passwordRepeat')!.setErrors(null);
    }
    if (password !== passwordRepeat && regexFlag) {
      this.passwordForm.get('passwordRepeat')!.setErrors({ mismatch: true });
    }
  }

  openLogin() {
    this.router.navigate(['auth']);
  }

  backToEmail() {
    this.currentStep = 1;
  }

  requestResetPassword() {
    this.registrationService
      .requestResetPassword(this.emailForm.get('email')?.value!)
      .pipe(
        catchError((error: any) => {
          if (error.status === 409) {
            this.requestAlreadySent = true;
            this.currentStep = 2;
          }
          return throwError(error);
        })
      )
      .subscribe({
        next: () => {
          this.requestAlreadySent = false;
          this.currentStep = 2;
        },
        error: () => {
        }
      });
  }

  focusNextInput(index: number) {
    // Фокусируем на следующем поле при вводе
    if (index < this.digits.length - 1 && this.otp[index]) {
      document.getElementById(`otp${index + 1}`)!.focus();
    }
    if (index > 0 && !this.otp[index]) {
      if (this.submitCodeErrorFlag) this.submitCodeErrorFlag = false;

      document.getElementById(`otp${index - 1}`)!.focus();
    }

    if (index === this.digits.length - 1 && this.otp[index]) {
      this.registrationService
        .changePasswordCheckCode(
          this.emailForm.get('email')!.value!,
          this.otp.join('')
        )
        .subscribe(
          (res) => {
            if (res.data) {
              this.currentStep = 3;
            } else {
              this.setWrongCode();
            }
          },
          () => {
            this.setWrongCode();
          }
        );
    }
  }

  // resendCode() {
  //   this.registrationService
  //     .resendCode(this.emailForm.get('email')!.value!)
  //     .subscribe();
  // }

  changePassword() {
    this.registrationService
      .changePassword(
        this.emailForm.get('email')!.value!,
        this.passwordForm.get('password')?.value!,
        this.otp.join('')
      )
      .subscribe(
        () => {
          this.changePasswordError = false;
          this.openLogin();
        },
        () => {
          this.setWrongCode();
        }
      );
  }

  setWrongCode() {
    document.getElementById('otp0')!.focus();
    this.changePasswordError = true;
    this.currentStep = 2;
    this.otp = ['', '', '', '', '', ''];
  }
}
