import { HttpResponse } from '@angular/common/http';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { debounce, timer } from 'rxjs';
import { HeaderDto } from 'src/app/dto/header.dto';
import { AuthService } from 'src/app/services/auth-service.service';
import {
  CheckInnDTO,
  RegistrationRequestData,
  RegistrationService
} from 'src/app/services/registration.service';
import { TaxNumberValidatorService } from '../../services/tax-number-validator.service';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent {
  @ViewChild('scrollToElement') scrollToElement: ElementRef; // Добавляем ViewChild

  readonly regForm: FormGroup;

  digits = [0, 1, 2, 3, 4, 5]; // Количество цифровых полей OTP
  otp: string[] = ['', '', '', '', '', '']; // Массив для хранения введенных значений

  headerDataValues: HeaderDto = {
    title: 'Регистрация'
  };

  checkInnData?: CheckInnDTO;

  showCodeForm: boolean = false;

  submitCodeErrorFlag: boolean = false;

  registrationErrorText: string;

  isToastOpen = false;

  private PasswordSymbolsPattern: RegExp = /^\S*$/;

  constructor(
    private registrationService: RegistrationService,
    private authService: AuthService,
    private router: Router,
    taxNumberValidatorService: TaxNumberValidatorService
  ) {
    this.regForm = new FormGroup({
      inn: new FormControl('', [
        Validators.required,
        Validators.minLength(12),
        taxNumberValidatorService.createValidator()
      ]),
      lastName: new FormControl('', [Validators.required]),
      firstName: new FormControl('', [Validators.required]),
      patronymicName: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required]),
      phone: new FormControl('', [Validators.required]),
      password: new FormControl('', [
        Validators.required,
        Validators.pattern(this.PasswordSymbolsPattern)
      ]),
      passwordRepeat: new FormControl('', [
        Validators.required,
        Validators.pattern(this.PasswordSymbolsPattern)
      ]),
      userDataAgreement: new FormControl(false, [Validators.required]),
      licenseAgreement: new FormControl(false, [Validators.required])
    });

    const innControl = this.regForm.get('inn');
    const emailControl = this.regForm.get('email');
    const firstNameControl = this.regForm.get('firstName');
    const lastNameControl = this.regForm.get('lastName');
    const patronymicNameControl = this.regForm.get('patronymicName');
    const phoneControl = this.regForm.get('phone');
    const passwordControl = this.regForm.get('password');
    const passwordRepeatControl = this.regForm.get('passwordRepeat');

    innControl?.statusChanges
      .pipe(debounce(() => timer(200)))
      .subscribe((status: any) => {
        const typedInn = innControl.value;

        if (status === 'VALID' && typedInn.length === 12) {
          this.registrationService.checkInn(typedInn, 'SELF_EMPLOYED').subscribe({
            next: v => {
              this.checkInnData = v;

              if (
                emailControl &&
                !emailControl.value &&
                this.checkInnData.data.email
              ) {
                emailControl?.setValue(this.checkInnData.data.email);
                emailControl.updateValueAndValidity();
              }

              if (
                firstNameControl &&
                !firstNameControl.value &&
                this.checkInnData.data.firstName
              ) {
                firstNameControl?.setValue(this.checkInnData.data.firstName);
                firstNameControl.updateValueAndValidity();
              }

              if (
                lastNameControl &&
                !lastNameControl.value &&
                this.checkInnData.data.lastName
              ) {
                lastNameControl?.setValue(this.checkInnData.data.lastName);
                lastNameControl.updateValueAndValidity();
              }

              if (
                patronymicNameControl &&
                !patronymicNameControl.value &&
                this.checkInnData.data.patronymicName
              ) {
                patronymicNameControl?.setValue(
                  this.checkInnData.data.patronymicName
                );
                patronymicNameControl.updateValueAndValidity();
              }

              if (
                phoneControl &&
                !phoneControl.value &&
                this.checkInnData.data.phone
              ) {
                phoneControl?.setValue(this.checkInnData.data.phone);
                phoneControl.updateValueAndValidity();
              }
            },
            error: e => {
              innControl?.setErrors({ incorrect: e.error_description });
            }
          });
        } else this.clearCheckInnField();
      });

    passwordControl!.valueChanges.subscribe(() => {
      this.checkPasswordMatch();
    });

    passwordRepeatControl!.valueChanges.subscribe(() => {
      this.checkPasswordMatch();
    });
  }

  checkPasswordMatch() {
    const password = this.regForm.get('password')?.value;
    const passwordRepeat = this.regForm.get('passwordRepeat')?.value;

    const regex = /^\S*$/; // Регулярное выражение
    let regexFlag = true;

    if (!password || !passwordRepeat || !regex.test(password) || !regex.test(passwordRepeat)) {
      regexFlag = false;
    }

    if (password === passwordRepeat && regexFlag) {
      this.regForm.get('passwordRepeat')!.setErrors(null);
    }
    if (password !== passwordRepeat && regexFlag) {
      this.regForm.get('passwordRepeat')!.setErrors({ mismatch: true });
    }
  }

  acceptUserDataAgreement() {
    const { userDataAgreement } = this.regForm.controls;
    userDataAgreement.setValue(!userDataAgreement.value);
  }

  acceptLicenseAgreement() {
    const { licenseAgreement } = this.regForm.controls;
    licenseAgreement.setValue(!licenseAgreement.value);
  }

  registration() {
    let phone = '';
    const inn = this.regForm.get('inn')?.value;
    const innCheckInfo = this.checkInnData;
    if (!innCheckInfo) {
      return;
    }
    const email = this.regForm.get('email')?.value;

    phone = `+${this.regForm.get('phone')?.value.replace(/\D/g, '')}`;

    const password = this.regForm.get('password')?.value;
    const requestData: RegistrationRequestData = {
      userType: 'SELF_EMPLOYED',
      inn,
      email,
      phone,
      password,
      firstName: innCheckInfo.data.firstName,
      lastName: innCheckInfo.data.lastName,
      patronymicName: innCheckInfo.data.patronymicName,
      name: innCheckInfo.data.name
    };

    this.registrationService.registration(requestData).subscribe(
      (data: HttpResponse<any>) => {
        if (data.status === 200) {
          this.showCodeForm = true;
        }
      },
      error => {
        if (error.status === 409) {
          this.scrollTo(error.error.conflictReason);
          this.regForm.get(error.error.conflictReason)?.setValue('');
          this.regForm.get(error.error.conflictReason)?.setErrors({ alreadyExist: true });
          this.regForm.get('password')?.reset();
          this.regForm.get('passwordRepeat')?.reset();
        }

        if (error.status === 418) {
          this.showCodeForm = true;
        }

        this.registrationErrorText = error.error.error_description;

        this.regForm.get('password')?.reset();
        this.regForm.get('passwordRepeat')?.reset();
      }
    );
  }

  clearCheckInnField() {
    this.checkInnData = undefined;
  }

  focusNextInput(index: number) {
    // Фокусируем на следующем поле при вводе
    if (index < this.digits.length - 1 && this.otp[index]) {
      document.getElementById(`otp${index + 1}`)!.focus();
    }
    if (index > 0 && !this.otp[index]) {
      if (this.submitCodeErrorFlag) this.submitCodeErrorFlag = false;

      document.getElementById(`otp${index - 1}`)!.focus();
    }

    if (index === this.digits.length - 1 && this.otp[index]) {
      this.sendCode();
    }
  }

  sendCode() {
    this.registrationService
      .sendCode(this.regForm.get('email')!.value!, this.otp.join(''))
      .subscribe(
        data => {
          if (data) {
            this.authService.registrationEnd(
              data.data.access_token,
              data.data.refresh_token,
              data.data.refresh_expires_in
            );
          } else {
            this.submitCodeErrorFlag = true;
          }
        },
        () => {
          this.submitCodeErrorFlag = true;
        }
      );
  }

  resendCode() {
    this.registrationService
      .resendCode(this.regForm.get('email')!.value!)
      .subscribe(() => {
        this.toastSetStatus(true);
      });
  }

  openLogin() {
    this.router.navigate(['auth']);
  }

  scrollTo(elementId: string) {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  toastSetStatus(isOpen: boolean) {
    this.isToastOpen = isOpen;
  }
}
