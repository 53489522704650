<app-header
  [showBackButton]="true"
  [headerData]="headerDataValues"
  class="full"
></app-header>
<app-offline-mode></app-offline-mode>
<ion-content *ngIf="!expeditorDocGenerating">
  <div class="content-wrapper">
    <div class="spinner" *ngIf="loading">
      <ion-spinner name="lines"></ion-spinner>
    </div>

    <div class="end-day-result">
      <span class="note">Всего отгружено:</span>
      <div class="weight-result">
        <div class="row">
          <span class="value">{{ totalWeight / 1000 }} т.</span>
        </div>

        <div class="row sub-description">
          <span>за </span>
          <span class="sub-value"> {{ request.registry.days.length }}</span>
          <span> {{ request.registry.days.length | pluralizeDays }} </span>
        </div>
      </div>

      <div class="submit-note" *ngIf="!endDayConfirmed">
        Будет сформирован отчет экспедитора
      </div>
    </div>
  </div>
</ion-content>

<ion-content *ngIf="expeditorDocGenerating">
  <div class="loading-wrapper">
    <div class="check-wrapper">
      <img src="assets/icon/checked-icon.svg" alt="" />
      <span> Будет сформирован отчет экспедитора</span>
    </div>

    <div class="spiner-wrapper">
      <img src="assets/icon/big-spiner.svg" alt="" />
      <span> Перемещение записи в архив</span>
    </div>
  </div>
</ion-content>

<ion-content *ngIf="dayEndInOffline">
  <div class="loading-wrapper">
    <div class="check-wrapper">
      <img src="assets/icon/checked-icon.svg" alt="" />
      <span
        >Заявка успешно завершена. При подключении к сети данные будут
        синхронизированы, а заявка переместится в архив</span
      >
    </div>
  </div>
</ion-content>

<ion-footer *ngIf="!endDayConfirmed">
  <app-swipe-button
    [title]="'Подтвердить и завершить'"
    (swipedEvent)="endOrder($event)"
    [isSwiped]="endDayConfirmed"
    (clickEvent)="endOrder(false)"
    [swipedText]="'Закрыть'"
  >
  </app-swipe-button>
</ion-footer>
