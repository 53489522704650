import {
  Component,
  EventEmitter,
  Input,
  OnChanges, OnDestroy, OnInit,
  Output
} from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { RtsVehicle } from 'src/app/dto/get-rts-result.dto';
import { Order } from 'src/app/dto/order.dto';
import { OrderChangesService } from 'src/app/services/order-changes.service';
import { filter, Subject, switchMap, takeUntil } from 'rxjs';
import { TaxNumberValidatorService } from '../../services/tax-number-validator.service';
import { RegistrationService } from '../../services/registration.service';

export interface CarEditorData {
  registrationNumber: string;
  trailerRegistrationNumber: string;
  driverName: string;
  driverPhone: string;
  carrierName: string;
  carrierInn: string;
}

type CarEditorFormData = {
  [K in keyof CarEditorData]: FormControl<CarEditorData[K]>;
};

@Component({
  selector: 'app-car-editor',
  templateUrl: './car-editor.component.html',
  styleUrls: ['./car-editor.component.scss']
})
export class CarEditorComponent implements OnChanges, OnInit, OnDestroy {
  @Output() formValueSubmitted: EventEmitter<Partial<CarEditorData>> = new EventEmitter();
  @Output() vehicleDataUpdated: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  @Input() currentCarData?: RtsVehicle;

  @Input() errorTrailerRegNumbField: boolean;

  dayId: string = '';
  targetUrl = window.location.pathname.split('/')[1];
  updatedOrder: Order;

  readonly form: FormGroup<CarEditorFormData>;

  private readonly destroy$: Subject<void> = new Subject();

  constructor(
    private orderChangesService: OrderChangesService,
    public route: ActivatedRoute,
    private readonly registrationService: RegistrationService,
    taxNumberValidatorService: TaxNumberValidatorService
  ) {
    this.form = new FormGroup<CarEditorFormData>({
      registrationNumber: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
      trailerRegistrationNumber: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
      driverName: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
      driverPhone: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
      carrierName: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
      carrierInn: new FormControl('', {
        nonNullable: true,
        validators: [
          Validators.required,
          taxNumberValidatorService.createValidator()
        ]
      })
    });
  }

  ngOnInit(): void {
    this.watchForCarrierInnChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnChanges(): void {
    this.orderChangesService.getOrderSubject(this.targetUrl).subscribe(r => {
      if (r) {
        this.updatedOrder = r;
      }
    });

    if (this.currentCarData) {
      const carData = this.currentCarData;
      this.form.patchValue({
        registrationNumber: carData.registrationNumber,
        trailerRegistrationNumber: carData.trailerRegistrationNumber,
        driverName: carData.driverName,
        driverPhone: carData.driverPhone,
        carrierName: carData.carrierName,
        carrierInn: carData.carrierInn ? String(carData.carrierInn) : '',
      });
    }

    if (this.errorTrailerRegNumbField) {
      this.form.controls.trailerRegistrationNumber.markAsTouched();
      this.form.controls.trailerRegistrationNumber.setErrors({ incorrect: true });
      this.form.updateValueAndValidity();
    }
  }

  saveChanges() {
    if (this.form.valid && this.form.value) {
      if (this.currentCarData) {
        const dayId =
          window.location.pathname.split('/')[
          window.location.pathname
            .split('/')
            .findIndex(el => el === 'day') + 1
          ];

        this.updatedOrder = this.updatedOrder.editVehiclesBaseData(
          dayId,
          this.currentCarData.id,
          this.form.value
        );

        this.orderChangesService.updateRequests(
          this.updatedOrder,
          this.targetUrl
        );

        this.vehicleDataUpdated.emit(true);
      } else this.formValueSubmitted.emit(this.form.value);
    }
  }

  private watchForCarrierInnChanges(): void {
    const { carrierInn } = this.form.controls;
    carrierInn.valueChanges.pipe(
      filter(() => carrierInn.valid),
      switchMap(inn => this.registrationService.checkInn(inn)),
      takeUntil(this.destroy$)
    ).subscribe(response => {
      if (response.success) {
        this.form.patchValue({ carrierName: response.data.name });
      }
    });
  }
}
