import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { HeaderDto } from 'src/app/dto/header.dto';
import { OrderChangesService } from 'src/app/services/order-changes.service';
import { Order } from 'src/app/dto/order.dto';
import { TtnGeneratorService } from 'src/app/services/docs-service/ttn-generator.service';
import { TrnGeneratorService } from 'src/app/services/docs-service/trn-generator.service';
import { RtsVehicle } from 'src/app/dto/get-rts-result.dto';
import { EditCarComponent } from '../edit-car/edit-car.component';
import { SdizNumberValidatorService } from '../../../../services/sdiz-number-validator.service';

@Component({
  selector: 'app-load-car',
  templateUrl: './load-car.component.html',
  styleUrls: ['./load-car.component.scss']
})
export class LoadCarComponent implements OnInit {
  headerDataValues: HeaderDto = {
    title: 'День '
  };

  carInfo: RtsVehicle;
  carIndex: number;
  vehicleId: string = '';
  requestDay: string = '';
  formGroup: FormGroup;
  targetUrl = window.location.pathname.split('/')[1];
  currentDayId: string;
  order: Order;
  phoneHref: string;
  emptytrailerRegistrationNumberError: boolean = false;
  ttnDocumentGeneratingButtonSpiner: boolean = false;
  trnDocumentGeneratingButtonSpiner: boolean = false;

  constructor(
    private modalCtrl: ModalController,
    private route: Router,
    private orderChangesService: OrderChangesService,
    private ttnGeneratorService: TtnGeneratorService,
    private trnGeneratorService: TrnGeneratorService,
    private readonly sdizValidatorService: SdizNumberValidatorService
  ) { }

  ngOnInit() {
    this.carIndex = this.route.getCurrentNavigation()?.extras?.state?.['carIndex'];
    this.currentDayId =
      window.location.pathname.split('/')[
      window.location.pathname.split('/').findIndex(el => el === 'day') + 1
      ];

    this.vehicleId =
      window.location.pathname.split('/')[
      window.location.pathname
        .split('/')
        .findIndex(el => el === 'load-car') + 1
      ];

    this.orderChangesService.getOrderSubject(this.targetUrl).subscribe(r => {
      if (r) {
        this.order = r;
        const dayData = r.getDayById(this.currentDayId);

        if (dayData) {
          const dayNumber = this.order.getDayNumberById(dayData.orderNumber);
          this.headerDataValues.title = `День ${dayNumber}`;
        }

        if (
          this.emptytrailerRegistrationNumberError &&
          this.carInfo.trailerRegistrationNumber &&
          !this.carInfo.hasTrailer
        ) {
          this.emptytrailerRegistrationNumberError = false;
        }
      }
    });

    this.requestDay =
      window.location.pathname.split('/')[
      window.location.pathname.split('/').findIndex(el => el === 'day') + 1
      ];

    this.formGroup = new FormGroup({
      tareWeight: new FormControl('', Validators.required),
      grossWeight: new FormControl('', Validators.required),
      netWeight: new FormControl('', Validators.required),
      quarantineCertificateNumber: new FormControl('', Validators.required),
      quarantineCertificateDate: new FormControl('', [Validators.required]),
      sdizNumber: new FormControl('', [
        Validators.required,
        this.sdizValidatorService.createValidator()
      ]),
      declarationOfConformityNumber: new FormControl('', Validators.required),
      declarationOfConformityDate: new FormControl('', [Validators.required]),
      testingProtocolNumber: new FormControl('', Validators.required),
      testingProtocolDate: new FormControl('', [Validators.required])
    });

    this.formGroup
      .get('tareWeight')
      ?.valueChanges.subscribe((tareWeight: number) => {
        this.updateNetWeightIfValid(
          tareWeight,
          this.formGroup.get('grossWeight')?.value
        );
      });

    this.formGroup
      .get('grossWeight')
      ?.valueChanges.subscribe((grossWeight: number) => {
        this.updateNetWeightIfValid(
          this.formGroup.get('tareWeight')?.value,
          grossWeight
        );
      });

    this.getVehicleDetails(this.vehicleId);
  }

  updateNetWeightIfValid(tareWeight: number, grossWeight: number) {
    if (tareWeight && grossWeight && grossWeight > tareWeight) {
      const netWeight = grossWeight - tareWeight;
      this.formGroup.get('netWeight')?.setValue(netWeight);
    }
    if (tareWeight && grossWeight && grossWeight <= tareWeight) {
      this.formGroup.get('netWeight')?.reset();
    }
  }

  getVehicleDetails(vehicleId: string): void {
    const vehicle: RtsVehicle | null = this.order.getVehicleById(vehicleId);
    if (vehicle) {
      this.carInfo = vehicle;

      this.phoneHref = `tel:${this.carInfo.driverPhone}`;

      if (vehicle?.loadingData?.lastUpdate) {
        const {
          tareWeight,
          grossWeight,
          netWeight,
          quarantineCertificateDate,
          quarantineCertificateNumber,
          sdizNumber,
          declarationOfConformityNumber,
          declarationOfConformityDate,
          testingProtocolNumber,
          testingProtocolDate
        } = vehicle.loadingData;

        this.formGroup.patchValue({
          tareWeight,
          grossWeight,
          netWeight,
          quarantineCertificateNumber,
          quarantineCertificateDate,
          sdizNumber,
          declarationOfConformityNumber,
          declarationOfConformityDate,
          testingProtocolNumber,
          testingProtocolDate
        });
      }
    }
  }

  async generateTtn() {
    const updatedRequest = this.order.updateVehicle(
      this.requestDay,
      this.vehicleId,
      this.formGroup.value
    );
    this.ttnDocumentGeneratingButtonSpiner = true;
    try {
      await this.ttnGeneratorService.getTtn(updatedRequest, this.carInfo, this.carIndex);
    } catch (error) {
      // console.error('Error handling Excel file:', error);
    } finally {
      this.ttnDocumentGeneratingButtonSpiner = false;
    }

    this.orderChangesService.updateRequests(updatedRequest, this.targetUrl);
  }

  async generateTrn() {
    const updatedRequest = this.order.updateVehicle(
      this.requestDay,
      this.vehicleId,
      this.formGroup.value
    );

    this.trnDocumentGeneratingButtonSpiner = true;
    try {
      await this.trnGeneratorService.getTtn(this.order, this.carInfo);
    } catch (error) {
      // console.error('Error handling Excel file:', error);
    } finally {
      this.trnDocumentGeneratingButtonSpiner = false;
    }

    this.orderChangesService.updateRequests(updatedRequest, this.targetUrl);
  }

  async openRTCActions() {
    const modal = await this.modalCtrl.create({
      component: EditCarComponent,
      componentProps: {
        editVehicleData: this.carInfo,
        filltrailerRegistrationNumber: this.emptytrailerRegistrationNumberError
      }
    });
    modal.present();
  }
}
