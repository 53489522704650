import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import {
  ARCHIVED_REQUESTS,
  OPEN_REQUESTS
} from '../utils/constants/router.constants';
import { tableOrdersInEditStatus } from '../db-description';
import { BaseHttpService } from './base-http.service';
import { ConfigService } from './config.service';
import { Order } from '../dto/order.dto';
import { BackupService } from './backup.service';

@Injectable({
  providedIn: 'root'
})
export class OrderChangesService extends BaseHttpService {
  private openOrderSubject: BehaviorSubject<Order | null> =
    new BehaviorSubject<Order | null>(null);

  private workOrderSubject: BehaviorSubject<Order | null> =
    new BehaviorSubject<Order | null>(null);

  private archiveOrderSubject: BehaviorSubject<Order | null> =
    new BehaviorSubject<Order | null>(null);

  constructor(
    protected dbService: NgxIndexedDBService,
    private superHttp: HttpClient,
    private superConfig: ConfigService,
    private backupService: BackupService,
  ) {
    super(superHttp, superConfig);
  }

  /*
   * Возвращаем целевой BehaviorSubject
   * @param targetUrl - используем для определения какой у нас сейчас компонент.
   */
  public getOrderSubject(targetUrl: string): BehaviorSubject<Order | null> {
    switch (targetUrl) {
      case OPEN_REQUESTS:
        return this.openOrderSubject;
      case ARCHIVED_REQUESTS:
        return this.archiveOrderSubject;
      default:
        return this.workOrderSubject;
    }
  }

  public updateRequests(updatedOrder: Order, targetUrl: string) {
    // меняем в списке в базе измененных заказов
    this.checkAndUpdateOrder(updatedOrder);
    this.setOrder(updatedOrder, targetUrl);
  }

  // Функция для проверки наличия записи в IndexedDB и ее обновления или вставки
  private checkAndUpdateOrder(updatedOrder: Order) {
    this.dbService
      .getByKey(tableOrdersInEditStatus, updatedOrder.id)
      .subscribe({
        next: (existingRecord: any) => {
          if (existingRecord) {
            existingRecord.Order = updatedOrder;
            existingRecord.orderBackupVersion = +existingRecord.orderBackupVersion + 1;

            if (!existingRecord.user) {
              const currentLogin = localStorage.getItem('login')
              existingRecord.user = currentLogin;
            }

            this.dbService
              .update(tableOrdersInEditStatus, existingRecord)
              .subscribe({
                next: () => {
                  this.backupService.saveBackup(existingRecord.Id);
                },
                error: (error) => { }
                // console.log('Ошибка при обновлении записи', error)
              });
          } else {
            const newRecord = { Id: updatedOrder.id, Order: updatedOrder, orderBackupVersion: 1 };

            this.dbService.add(tableOrdersInEditStatus, newRecord).subscribe({
              next: () => {
                this.backupService.saveBackup(updatedOrder.id);
              },
              error: (error) => { }
              // console.error('Ошибка при добавлении записи:', error)
            });
          }
        }
      });
  }

  // Метод для передачи нового экземпляра order в сервис
  public setOrder(newOrder: Order, targetUrl: string): void {
    const currentSubj = this.getOrderSubject(targetUrl);
    // new Order review
    currentSubj.next(new Order(newOrder));
  }
}
