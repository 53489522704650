<div class="container">
  <div class="title">Отсутствует соединение с интернетом!</div>

  <img src="../../../assets/images/offline.svg" alt="image" />

  <div class="message">
    До появления соединения с интернетом приложение будет работать в
    <strong>автономном режиме</strong>. Данные будут сохраняться в кэш
    приложения. <strong>Некоторые функции могут быть недоступны.</strong> При
    появлении подключения, данные загрузятся на наш сервер. Не очищайте кэш
    приложения при отсутствии интернета.
  </div>

  <ion-button class="cancel large" mode="md" (click)="cancel()"
    >Продолжить в автономном режиме</ion-button
  >
</div>
