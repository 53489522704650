import { ModalController } from '@ionic/angular';
import { ActionsModalComponent } from '../../../shared/actions-modal/actions-modal.component';

export class OrderCommonComponent {
    constructor(protected modalCtrl: ModalController) {
    }

    async noContentRequest() {
        const modal = await this.modalCtrl.create({
            component: ActionsModalComponent,
            breakpoints: [0, 1],
            initialBreakpoint: 1,
            componentProps: {
                data: {
                    message:
                        'Данная заявка уже принята в работу . Попробуйте принять в работу другую заявку.',
                    title: 'Это заявка уже была принята другим экспедитором',
                    imageSrc: '../../../assets/images/no-content.svg',
                    errorTitle: true
                }
            }
        });
        await modal.present();
    }
}
