<app-header
  [showBackButton]="true"
  [headerData]="headerDataValues"
  class="full"
></app-header>

<app-offline-mode></app-offline-mode>

<ion-content *ngIf="carInfo">
  <div id="needTrailerRegNumbNote" class="car-info-block">
    <div class="key-info">
      <div
        [ngClass]="{ 'breath-button': emptytrailerRegistrationNumberError }"
        (click)="openRTCActions()"
        class="edit-button"
      >
        <img
          width="24px"
          height="24px"
          src="../../../../../assets/icon/edit-icon.svg"
          alt=""
        />
      </div>
      <div *ngIf="vehicleId" class="car-index">Машина</div>
      <div *ngIf="carInfo.registrationNumber" class="car-number">
        {{ carInfo.registrationNumber }}
      </div>
    </div>
    <div class="additional-info">
      <div class="row">
        <span>Прицеп: </span>
        <span *ngIf="carInfo.hasTrailer" class="bold">
          {{ carInfo.trailerRegistrationNumber }}
        </span>
        <span
          [ngStyle]="{
            color: emptytrailerRegistrationNumberError ? '#FB7991' : '#EDAE18'
          }"
          *ngIf="!carInfo.hasTrailer"
          class="bold"
        >
          Необходимо заполнить поле
        </span>
      </div>

      <div class="row">
        <span>Водитель: </span>
        <span class="bold">{{ carInfo.driverName }}</span>
      </div>
      <div class="row">
        <span>Владелец: </span>
        <span class="bold">{{ carInfo.carrierName }}</span>
      </div>
      <div class="row">
        <span>Телефон: </span>
        <span *ngIf="carInfo.driverPhone" class="bold">
          <a class="underline" [attr.href]="phoneHref">
            {{ carInfo.driverPhone }}
          </a>
        </span>
      </div>
    </div>
  </div>

  <div class="form-wrapper">
    <div class="line"></div>
    <div class="form-header">Заполните данные:</div>

    <div class="submit-note">
      <img src="assets/icon/warning-icon.svg" alt="" />
      <div class="note-text">
        <span class="note-title"> Внимание! </span>
        <span class="note-info"
          >Вес «Нетто» формируется из разницы между весом брутто и весом тары.
          Заполните данные поля!</span
        >
      </div>
    </div>

    <form [formGroup]="formGroup" class="field-wrapper">
      <div class="weight-block">
        <div class="calc-block">
          <app-base-input
            style="width: 100%"
            [title]="'Вес тары:'"
            [type]="'weight'"
            [control]="formGroup.get('tareWeight')"
            formControlName="tareWeight"
          ></app-base-input>
          <app-base-input
            style="width: 100%"
            [title]="'Вес брутто:'"
            [type]="'weight'"
            [control]="formGroup.get('grossWeight')"
            formControlName="grossWeight"
          ></app-base-input>
        </div>

        <div class="net-result">
          <span>Вес нетто: </span>
          <span class="net-value">{{ formGroup.get('netWeight')!.value }}</span>
          <span>кг</span>
        </div>
      </div>

      <app-base-input
        style="width: 100%"
        [title]="'Номер карантинного сертификата'"
        [boldInput]="true"
        [type]="'numeric'"
        [control]="formGroup.get('quarantineCertificateNumber')"
        formControlName="quarantineCertificateNumber"
      ></app-base-input>

      <app-base-input
        style="width: 100%"
        [title]="'Дата карантинного сертификата'"
        [type]="'calendar'"
        [boldInput]="true"
        [control]="formGroup.get('quarantineCertificateDate')"
        formControlName="quarantineCertificateDate"
      ></app-base-input>

      <app-base-input
        style="width: 100%"
        [title]="'Номер СДИЗ'"
        [type]="'sdiz'"
        [boldInput]="true"
        [control]="formGroup.get('sdizNumber')"
        formControlName="sdizNumber"
      ></app-base-input>

      <app-base-input
        style="width: 100%"
        [title]="'Декларация о соответствии ЕАЭС'"
        [boldInput]="true"
        [control]="formGroup.get('declarationOfConformityNumber')"
        formControlName="declarationOfConformityNumber"
      ></app-base-input>

      <app-base-input
        style="width: 100%"
        [title]="'Дата декларации о соответствии ЕАЭС'"
        [type]="'calendar'"
        [boldInput]="true"
        [control]="formGroup.get('declarationOfConformityDate')"
        formControlName="declarationOfConformityDate"
      ></app-base-input>

      <app-base-input
        style="width: 100%"
        [title]="'Протокол испытаний'"
        [type]="'numeric'"
        [boldInput]="true"
        [control]="formGroup.get('testingProtocolNumber')"
        formControlName="testingProtocolNumber"
      ></app-base-input>

      <app-base-input
        style="width: 100%"
        [title]="'Дата протокола испытаний'"
        [type]="'calendar'"
        [boldInput]="true"
        [control]="formGroup.get('testingProtocolDate')"
        formControlName="testingProtocolDate"
      ></app-base-input>

      <div class="submit-note">
        <img src="../../../../../assets/icon/warning-icon.svg" alt="" />
        <div class="note-text">
          <span class="note-title"> Внимание! </span>
          <span class="note-info"
            >Формируя ТТН и ТРН вы подтверждаете достоверность введенной
            информации, которая влияет на вознаграждение</span
          >
        </div>
      </div>

      <div class="submit-wrapper">
        <ion-button
          [disabled]="!formGroup.valid || trnDocumentGeneratingButtonSpiner"
          (click)="generateTtn()"
          type="submit"
          class="primary large"
          mode="md"
        >
          <ion-spinner
            class="wait-spiner"
            *ngIf="ttnDocumentGeneratingButtonSpiner"
            name="lines"
          ></ion-spinner>
          <span class="button-text" *ngIf="!ttnDocumentGeneratingButtonSpiner">
            Сформировать ТТН
          </span>
        </ion-button>
        <ion-button
          [disabled]="!formGroup.valid || ttnDocumentGeneratingButtonSpiner"
          (click)="generateTrn()"
          type="submit"
          class="primary large"
          mode="md"
        >
          <ion-spinner
            class="wait-spiner"
            *ngIf="trnDocumentGeneratingButtonSpiner"
            name="lines"
          ></ion-spinner>
          <span class="button-text" *ngIf="!trnDocumentGeneratingButtonSpiner">
            Сформировать ТРН
          </span>
        </ion-button>
      </div>
    </form>
  </div>
</ion-content>
