import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberSeparator'
})
export class NumberSeparatorPipe implements PipeTransform {
  transform(value: number): string | null {
    if (Number.isNaN(value)) {
      return null;
    }

    const numberString = value.toString();
    const parts = [];
    let part = '';

    for (let i = numberString.length - 1; i >= 0; i -= 1) {
      part = numberString[i] + part;
      if (part.length === 3 || i === 0) {
        parts.unshift(part);
        part = '';
      }
    }

    return parts.join(' ');
  }
}
