import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ModalController, NavController } from '@ionic/angular';
import { first } from 'rxjs';
import { ActionsModalComponent } from 'src/app/shared/actions-modal/actions-modal.component';
import { ForwarderNoteService } from 'src/app/services/docs-service/forwarder-note.service';
import { RtsDay, RtsVehicle } from 'src/app/dto/get-rts-result.dto';
import { OrdersService } from 'src/app/services/orders.service';
import { HeaderDto } from '../../../dto/header.dto';
import { Order } from '../../../dto/order.dto';
import { Status } from '../../../dto/status.enum';
import { OrderChangesService } from '../../../services/order-changes.service';

@Component({
  selector: 'app-day-details',
  templateUrl: './day-details.component.html',
  styleUrls: ['./day-details.component.scss']
})
export class DayDetailsComponent implements OnInit {
  headerDataValues: HeaderDto;
  loading: boolean;
  dayDetails: RtsDay;
  order: Order;
  carsList: RtsVehicle[];
  isDayStarted: boolean;
  isRequestInWork: boolean;
  currentDayId: string = '';
  disableDayStartSwipe: boolean = false;
  dayNumber: number;
  targetUrl = window.location.pathname.split('/')[1];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private navCtrl: NavController,
    private modalCtrl: ModalController,
    private orderChangesService: OrderChangesService,
    private forwarderNoteService: ForwarderNoteService,
    private ordersService: OrdersService
  ) { }

  ngOnInit(): void {
    this.route.params.pipe(first()).subscribe(parameter => {
      this.currentDayId = parameter['id'];
    });

    this.orderChangesService
      .getOrderSubject(this.targetUrl)
      .subscribe(currentRequest => {
        if (currentRequest) {
          this.order = currentRequest;
          this.dataUpdate();
        }
      });
  }

  dataUpdate() {
    let totalAmount = 0;
    this.loading = true;

    const dayData = this.order.getDayById(this.currentDayId);

    if (dayData) {
      this.dayDetails = dayData;

      if (this.order.status !== Status.OPEN) {
        this.dayDetails.vehicles.forEach((vehicle: any) => {
          if (vehicle?.loadingData?.netWeight) {
            totalAmount += vehicle.loadingData.netWeight;
          }
        });
      } else {
        totalAmount = this.dayDetails.vehicles.length * 25000;
      }
    }

    this.isRequestInWork =
      this.order.status.includes(Status.ACCEPTED) ||
      this.order.status.includes(Status.IN_WORK);

    this.carsList = [...this.dayDetails.vehicles];

    this.dayNumber = this.order.getDayNumberById(this.dayDetails.orderNumber);

    this.headerDataValues = {
      title: `День ${this.dayNumber}, РТС`,
      subtitle: {
        cars: this.dayDetails.vehicles.length,
        amount: totalAmount
      },
      options: !this.order.status.includes(Status.OPEN),
      carsData: this.carsList,
      orderData: this.order
    };

    this.checkDayStartOptions();
    this.isDayStarted = this.dayDetails.started;
    this.loading = false;
  }

  dayStart(event: boolean) {
    if (event) {
      if (this.order.checkFirstDayStarted()) {
        this.ordersService.startForwarding(this.order.id).subscribe();
      }
      const updatedRequest = this.order.startDay(this.dayDetails.orderNumber);
      this.orderChangesService.updateRequests(updatedRequest, this.targetUrl);
    }
  }

  async dayEnd(event: boolean) {
    if (event) {
      const modal = await this.modalCtrl.create({
        component: ActionsModalComponent,
        breakpoints: [0, 1],
        initialBreakpoint: 1,
        componentProps: {
          data: {
            confirmButton: true,
            confirmButtonText: 'Завершить',
            message:
              'Не завершайте день, если не загружены все автомобили из списка РТС.',
            title: 'Хотите завершить день?',
            imageSrc: '../../../assets/images/end-day-car.svg'
          }
        }
      });
      modal.present();

      const { role } = await modal.onWillDismiss();

      if (role === 'confirm') {
        this.navCtrl.navigateForward(`${window.location.pathname}/end-day`);
      }
    }
  }

  checkDayStartOptions() {
    this.order.registry?.days.forEach(day => {
      if (
        day.started &&
        !day.completed &&
        day.orderNumber.replace('|', '') !== this.currentDayId
      ) {
        this.disableDayStartSwipe = true;
      }
    });
  }

  openCarDetails(car: RtsVehicle, carIndex: number) {
    if (
      this.dayDetails.started ||
      this.order.status === Status.COMPLETED ||
      this.order.status === Status.CLOSED
    ) {
      const navigationExtras: NavigationExtras = {
        state: {
          car,
          dayId: this.currentDayId,
          carIndex
        }
      };
      this.navCtrl.navigateForward(
        `${window.location.pathname}/load-car/${car.id}`,
        navigationExtras
      );
    } else {
      this.startDayModal();
    }
  }

  async downloadExpeditorNote() {
    // this.trnDocumentGeneratingButtonSpiner = true;
    try {
      await this.forwarderNoteService.getForwarderNote(
        this.carsList,
        this.order,
        this.dayDetails
      );
    } catch (error) {
      // console.error('Error handling Excel file:', error);
    } finally {
      // this.trnDocumentGeneratingButtonSpiner = false;
    }
  }

  async startDayModal() {
    const modal = await this.modalCtrl.create({
      component: ActionsModalComponent,
      breakpoints: [0, 1],
      initialBreakpoint: 1,
      componentProps: {
        data: {
          message:
            'Для того, что бы заполнять информацию по отгрузке, добавлять или изменять параметры, необходимо начать день.',
          title: 'Для работы с машиной необходимо начать день!',
          imageSrc: '../../../assets/images/error-picture.svg',
          closeButton: true,
          confirmButton: !this.disableDayStartSwipe && this.isRequestInWork,
          confirmButtonText: 'Начать день'
        }
      }
    });
    modal.present();

    const { role } = await modal.onWillDismiss();

    if (role === 'confirm') {
      this.dayStart(true);
      this.isDayStarted = true;
    }
  }
}
