<div
  [ngClass]="{
    'untouched-input': !isInvalid && !isValid,
    'invalid-input': isInvalid && !isValid,
    'valid-input': isValid && !isInvalid,
    'round-corner': roundCorner
  }"
  class="input-container"
>
  <div *ngIf="!!title" class="label">{{ title }}</div>

  <ion-input
    *ngIf="!type"
    [ngStyle]="{ 'font-weight': boldInput ? '700' : '400' }"
    [(ngModel)]="inputValue"
    placeholder="{{ placeholder }}"
    (ngModelChange)="updateValue()"
  ></ion-input>

  <ion-input
    *ngIf="type === 'secretKey'"
    [ngStyle]="{ 'font-weight': boldInput ? '700' : '400' }"
    [(ngModel)]="inputValue"
    placeholder="{{ placeholder }}"
    (ngModelChange)="updateValue()"
  ></ion-input>

  <ion-input
    *ngIf="type === 'email'"
    [ngStyle]="{ 'font-weight': boldInput ? '700' : '400' }"
    [(ngModel)]="inputValue"
    placeholder="{{ placeholder }}"
    (ngModelChange)="updateValue()"
  ></ion-input>

  <ion-input
    type="{{ showPassword ? 'text' : 'password' }}"
    *ngIf="type === 'password'"
    [ngStyle]="{ 'font-weight': boldInput ? '700' : '400' }"
    placeholder="{{ placeholder }}"
    [(ngModel)]="inputValue"
    (ngModelChange)="updateValue()"
  >
    <div (click)="toggleShowPassword()" class="password-icon">
      <img [src]="showPassword ? 'assets/icon/eye-crossed.svg' : 'assets/icon/eye.svg'" alt="" />
    </div>
  </ion-input>

  <ion-input
    *ngIf="type === 'numeric'"
    [ngStyle]="{ 'font-weight': boldInput ? '700' : '400' }"
    [(ngModel)]="inputValue"
    (ngModelChange)="updateValue()"
    type="number"
    pattern="[0-9]*"
    [inputmode]="'numeric'"
  ></ion-input>

  <ion-input
    *ngIf="type === 'inn'"
    [(ngModel)]="inputValue"
    (ngModelChange)="updateValue()"
    [maxlength]="12"
    [minlength]="10"
    [inputmode]="'numeric'"
  >
  </ion-input>

  <app-sdiz-input *ngIf="type === 'sdiz'"
                  [formControl]="control">
  </app-sdiz-input>

  <ion-input
    *ngIf="type === 'carNumber'"
    placeholder="A123БВ 123"
    [minlength]="7"
    [maskito]="carNumberMask"
    [maskitoElement]="maskPredicate"
    [(ngModel)]="inputValue"
    (ngModelChange)="updateValue()"
  ></ion-input>

  <ion-input
    *ngIf="type === 'trailerNumber'"
    placeholder="AА1234 999"
    [minlength]="8"
    [maskito]="trailerNumberMask"
    [maskitoElement]="maskPredicate"
    [(ngModel)]="inputValue"
    (ngModelChange)="updateValue()"
  ></ion-input>

  <ion-input
    *ngIf="type === 'phone'"
    placeholder="+7 (xxx) xxx-xxxx"
    [maskito]="phoneMask"
    [inputmode]="'numeric'"
    [maskitoElement]="maskPredicate"
    [(ngModel)]="inputValue"
    (ngModelChange)="updateValue()"
  ></ion-input>

  <div *ngIf="type === 'calendar'">
    <div
      (click)="openDatePicker()"
      [ngClass]="{
        'calendar-filled': !!inputValue,
        'calendar-reset': !inputValue && control.dirty
      }"
      class="calendar"
    >
      <img src="../../../assets/icon/calendar.svg" alt="" />
      <span class="selectedDate" *ngIf="inputValue">{{
        inputValue | date : 'dd.MM.yyyy'
      }}</span>
      <span
        *ngIf="!inputValue"
        [ngClass]="{ 'calendar-error-text': control.dirty }"
        >ДД/ММ/ГГГГ</span
      >
    </div>
  </div>

  <div *ngIf="showDatePicker">
    <div [@fadeIn] class="calendar-wrapper">
      <ion-datetime
        [firstDayOfWeek]="1"
        [(ngModel)]="datePickerValue"
        (ionChange)="setDate()"
        presentation="date"
        [showDefaultButtons]="false"
        (ionCancel)="clearDatePick()"
        [max]="currentDate"
      >
      </ion-datetime>
    </div>
  </div>

  <div *ngIf="type === 'weight'" class="weight-input">
    <img class="weight-icon" src="../../../assets/icon/scale-icon.svg" alt="" />
    <span>кг</span>
    <ion-input
      [inputmode]="'numeric'"
      [(ngModel)]="inputValue"
      (ngModelChange)="weightInputValueChanged()"
      type="number"
      pattern="[0-9]*"
      [min]="1"
    ></ion-input>
  </div>

  <div
    class="error-message"
    *ngIf="isInvalid && type && !customErrorsFieldTypes.includes(type)"
  >
    Неправильно заполнено поле
  </div>
  <div
    class="error-message"
    *ngIf="isInvalid && type === 'password' && control.errors.pattern"
  >
    Пароль содержит недопустимые символы
  </div>
  <div
    class="error-message"
    *ngIf="isInvalid && type === 'password' && control.errors.mismatch"
  >
    Пароли не совпадают
  </div>
  <div
    class="error-message"
    *ngIf="isInvalid && type === 'inn' && control.errors.alreadyExist"
  >
    Пользователь с таким ИНН уже зарегистрирован
  </div>
  <div
    class="error-message"
    *ngIf="isInvalidTaxNumber"
  >
    Неправильный ИНН
  </div>
  <div
    class="error-message"
    *ngIf="isInvalid && type === 'email' && control.errors.alreadyExist"
  >
    Пользователь с таким Email уже зарегистрирован
  </div>
  <div
    class="error-message"
    *ngIf="isInvalid && type === 'secretKey' && control.errors.shortCode"
  >
    Секретный код должен содержать 32 символа
  </div>
  <div class="error-message" *ngIf="type === 'sdiz' && control.touched && control.invalid">
    Неправильно заполнено поле
  </div>

</div>
