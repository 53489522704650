import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { v4 as uuid } from 'uuid';
import * as JSZip from 'jszip';
import {
  BehaviorSubject,
  Observable,
  Subject,
  catchError,
  concatMap,
  defaultIfEmpty,
  filter,
  firstValueFrom,
  from,
  groupBy,
  map,
  mergeMap,
  of,
  switchMap,
  tap,
  throwError,
  toArray
} from 'rxjs';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { Router } from '@angular/router';
import { Order } from '../dto/order.dto';
import {
  BackupActions,
  BackupActionsInterface,
  OrderInEditStatusInterface,
  tableOrdersInEditStatus
} from '../db-description';
import { ErrorToastService } from './error-toast.service';
import { BackupApiService } from './backup-api.service';

export interface ConflictVersionModalData {
  localOrder: Order;
  localVersion: number;
  serverVersion: number;
}

@Injectable({
  providedIn: 'root'
})
export class BackupService {
  zip = new JSZip();
  email: string;
  public currentSecretCode$: BehaviorSubject<string | null> =
    new BehaviorSubject<string | null>(null);

  public versionConflictModalTrigger$: Subject<ConflictVersionModalData> =
    new Subject<ConflictVersionModalData>();

  constructor(
    private dbService: NgxIndexedDBService,
    private router: Router,
    private errorToastService: ErrorToastService,
    private backupApiService: BackupApiService
  ) { }

  /**
   * Записываем ключ шифрования в хранилище
   * @returns Observable<any>
   * @param secretKey
   */
  setSecretKey(secretKey: string): Observable<any> {
    const email = localStorage.getItem('login');
    if (email) this.email = email;

    const secretKeyTableData = {
      user: this.email,
      secretKey
    };

    return this.dbService
      .getByIndex('SecretKeyTable', 'user', secretKeyTableData.user)
      .pipe(
        switchMap((existingUser) => {
          if (existingUser) {
            return this.dbService.update('SecretKeyTable', {
              ...existingUser,
              ...secretKeyTableData
            });
          }
          return this.dbService.add('SecretKeyTable', secretKeyTableData);
        }),
        tap(() => this.currentSecretCode$.next(secretKey)),
        catchError((error) => throwError(error))
      );
  }

  /**
   * проверяем наличия ключа шифрования в хранилище и возвращаем результат
   * если ключ есть записываем его в currentSecretCode$
   * @returns {any}
   */
  checkSecretCode(): Observable<string | null> {
    const email = localStorage.getItem('login');
    if (email) this.email = email;

    return this.dbService.getByKey('SecretKeyTable', this.email).pipe(
      map((existingUser: any) => {
        if (existingUser?.secretKey) {
          this.currentSecretCode$.next(existingUser.secretKey);
        } else {
          this.currentSecretCode$.next(null);
        }
        const userData = existingUser as { secretKey: string; user: string };
        return userData?.secretKey || null;
      })
    );
  }

  // /**
  //  * отправляем на сервер бекапы которые есть ТОЛЬКО на клиенте
  //  * @param {any} uniqueLocalBackups:number[]
  //  * @returns {any}
  //  */
  // async sendUniqueBackups(uniqueLocalBackups: number[]) {
  //   await uniqueLocalBackups.forEach((uniqueId: number, index: number) => {
  //     this.getOrderDataFromLocalStore(uniqueId).subscribe(
  //       (uniqueOrder: any) => {
  //         if (uniqueOrder) {
  //           const idempotencyKey = uuid();
  //           this.writeBackupAction(
  //             uniqueOrder.Order.id,
  //             Number(uniqueOrder.orderBackupVersion),
  //             BackupActions.SYNC_FROM_CLIENT,
  //             idempotencyKey,
  //             uniqueOrder.Order
  //           ).subscribe();
  //         }
  //       }
  //     );
  //   });
  // }

  /**
   * Проверяем клиент на наличие несинхронизированных и устаревших бекапов
   * @returns {any}
   */
  async checkUpdates() {
    const serverBackupsData = await firstValueFrom(
      this.backupApiService.getAllBackupListWithVersion()
    );
    const serverBackupsOrderNumber = serverBackupsData.data.backupVersions.map(
      (el) => el.id
    );

    if (serverBackupsData) {
      // const uniqueLocalBackups = await this.checkUniqueLocalBackups(
      //   serverBackupsOrderNumber
      // );

      // if (uniqueLocalBackups.length) {
      //   await this.sendUniqueBackups(uniqueLocalBackups);
      // }

      from(serverBackupsData.data.backupVersions)
        .pipe(
          concatMap((backupRecord) => {
            const serverVersion = backupRecord.version;

            return this.getOrderDataFromLocalStore(backupRecord.id).pipe(
              concatMap((existingRecord: any) => {
                if (existingRecord) {
                  return this.comparBackupVersion(
                    backupRecord.id,
                    serverVersion,
                    Number(existingRecord.orderBackupVersion),
                    existingRecord.Order
                  );
                }
                return this.comparBackupVersion(
                  backupRecord.id,
                  serverVersion,
                  0
                );
              })
            );
          }),
          toArray()
        )
        .subscribe({
          next: () => {
            this.executeBackupActions();
          }
        });
    }
  }

  /**
   * проверка что записать локальный или серверный бекап
   * @returns {any}
   * @param id
   * @param serverVersion
   * @param localVersion
   * @param localOrder
   */
  async comparBackupVersion(
    id: number,
    serverVersion: number,
    localVersion: number,
    localOrder?: Order
  ) {
    const idempotencyKey = uuid();

    if (localVersion === 0) {
      return firstValueFrom(
        this.writeBackupAction(
          id,
          serverVersion,
          BackupActions.SYNC_FROM_SERVER,
          idempotencyKey
        )
      );
    }

    if (serverVersion < localVersion) {
      return firstValueFrom(
        this.writeBackupAction(
          id,
          localVersion,
          BackupActions.SYNC_FROM_CLIENT,
          idempotencyKey,
          localOrder
        )
      );
    }

    if (serverVersion === localVersion) {
      return from([]);
    }

    if (serverVersion > localVersion && localOrder) {
      this.versionConflictModalTrigger$.next({
        localOrder,
        localVersion,
        serverVersion
      });
    }
    return from([]);
  }

  /**
   * возвращаем Observable всех  записей таблицы OrdersInEditStatus
   * @returns {any}
   */
  getAllLocalBackups() {
    return this.dbService.getAll(tableOrdersInEditStatus);
  }

  /**
   * возвращаем Observable запись из таблицы OrdersInEditStatus по его ID
   * @param {any} orderId:number
   * @returns {any}
   */
  getOrderDataFromLocalStore(
    orderId: number
  ): Observable<OrderInEditStatusInterface | undefined> {
    return this.dbService.getByKey(tableOrdersInEditStatus, orderId);
  }

  // // проверка уникальных локальных бекапов
  // // TODO: потенциальная ошибка.В DB orderInEdit status нет поля user т.е. запрос вернет уникальные
  // // заявки других пользователей.
  // /**
  //  * Проверяем локальное хранилище на наличие УНИКАЛЬНЫХ бекапов заявок
  //  * @returns {any}
  //  * @param allServerBackupsOrderNumber
  //  */
  // async checkUniqueLocalBackups(
  //   allServerBackupsOrderNumber: number[]
  // ): Promise<number[]> {
  //   const uniqueLocalBackups: number[] = [];
  //   const allLocalBackups = await firstValueFrom(this.getAllLocalBackups());
  //   let localBackups = allLocalBackups as {
  //     Id: number;
  //     Order: Order;
  //     orderBackupVersion: string;
  //     user: string;
  //   }[];

  //   // не тестировалось
  //   const localUser = localStorage.getItem('login');
  //   localBackups = localBackups.filter((el) => el.user === localUser)
  //   //

  //   localBackups.forEach((localBackup) => {
  //     if (
  //       !allServerBackupsOrderNumber.find(
  //         (serverBackupOrderNumber) =>
  //           serverBackupOrderNumber === localBackup.Id
  //       )
  //     ) {
  //       uniqueLocalBackups.push(localBackup.Id);
  //     }
  //   });

  //   return uniqueLocalBackups;
  // }

  /**
   * Записываем бекап с сервера в локальное хранилище
   * @param {any} orderId:number
   * @returns {any}
   */
  writeServerBackupToLocalStore(orderId: number): Observable<any> {
    const currentLogin = localStorage.getItem('login')

    return this.backupApiService.getBackupById(orderId).pipe(
      concatMap((backupBlob) => {
        const newVersion = backupBlob.headers.get('Backup-Version');

        return from(this.checkZipAndDecrypt(backupBlob.body)).pipe(
          concatMap((decryptedData) => {
            if (decryptedData) {
              return this.getOrderDataFromLocalStore(orderId).pipe(
                concatMap((existingRecord: any) => {
                  if (existingRecord) {

                    if (!existingRecord.user) {
                      existingRecord.user = currentLogin;
                    }

                    existingRecord.Order = decryptedData;
                    existingRecord.orderBackupVersion = Number(newVersion);
                    return this.dbService.update(
                      tableOrdersInEditStatus,
                      existingRecord
                    );
                  }
                  const newRecord = {
                    Id: orderId,
                    Order: decryptedData,
                    orderBackupVersion: Number(newVersion),
                    user: currentLogin
                  };
                  return this.dbService.add(tableOrdersInEditStatus, newRecord);
                }),
                catchError((error) => {
                  // console.error(
                  //   'Ошибка при работе с локальным хранилищем:',
                  //   error
                  // );
                  return throwError(() => new Error(error));
                })
              );
            }
            return of(null);
          })
        );
      }),
      catchError((error) => {
        // console.error('Ошибка при получении бэкапа с сервера:', error);
        return throwError(() => new Error(error));
      })
    );
  }

  decryptData(encryptedData: string, secretKey: string): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
        const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        resolve(decryptedData);
      } catch (error) {
        reject(error);
      }
    });
  }

  async checkZipAndDecrypt(r: Blob): Promise<Order | null> {
    try {
      const zip = await this.zip.loadAsync(r);
      const file = zip.file('encryptDataFile');

      if (!file) {
        // console.error('Файл "encryptDataFile" не найден в архиве');
        return null;
      }

      const blob = await file.async('blob');
      const reader = new FileReader();
      const encryptedData: string = await new Promise<string>((resolve, reject) => {
        reader.onload = () => {
          if (reader.result !== null) {
            resolve(reader.result.toString());
          } else {
            // console.error('Ошибка: reader.result равен null');
            reject('');
          }
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsText(blob);
      });

      if (!encryptedData) {
        // console.error('Ошибка: данные для расшифровки пусты');
        return null;
      }

      const secretKey = this.currentSecretCode$.getValue();
      if (!secretKey) {
        // console.error('Ошибка: секретный ключ отсутствует');
        return null;
      }

      try {
        const decryptedData = await this.decryptData(encryptedData, secretKey) as Order;
        return decryptedData;
      } catch (error) {
        // console.error('Ошибка при расшифровке данных:', error);
        return null;
      }
    } catch (error) {
      // console.error('Произошла ошибка:', error);
      return null;
    }
  }

  /**
   * Сохраняем бекап на сервер
   * @param {any} id:number
   * @returns {any}
   */
  saveBackup(id: number) {
    this.getOrderDataFromLocalStore(id).subscribe({
      next: (existingRecord: any) => {
        const idempotencyKey = uuid();
        if (existingRecord) {
          this.writeBackupAction(
            existingRecord.Order.id,
            existingRecord.orderBackupVersion,
            BackupActions.SYNC_FROM_CLIENT,
            idempotencyKey,
            existingRecord.Order
          ).subscribe(() => {
            this.executeBackupActions();
          });
        } else {
          this.errorToastService.errorSubject.next(500);
        }
      }
    });
  }

  /**
   * Записываем запись  из локального хранилища на сервер
   * @param {any} order:Order
   * @param {any} localVersion:number
   * @param {any} idempotencyKey:string
   * @param {any} overwrite:boolean
   * @returns {any}
   */
  writeLocalBackupToServer(
    order: Order,
    localVersion: number,
    idempotencyKey: string,
    overwrite: boolean
  ): Observable<any> {
    return this.encryptData(order).pipe(
      switchMap((encryptedData) => {
        if (!encryptedData) {
          return of(null);
        }
        return from(this.packToZip(encryptedData)).pipe(
          switchMap((zipBlob: Blob) =>
            this.backupApiService
              .sendBackup(
                order.id.toString(),
                zipBlob,
                localVersion,
                overwrite,
                idempotencyKey
              )
              .pipe(
                catchError((error) => {
                  if (error.status === 412) {
                    const serverVersion = Number(error.error.error);
                    this.versionConflictModalTrigger$.next({
                      localOrder: order,
                      localVersion,
                      serverVersion
                    });
                  }
                  return throwError(
                    () => new Error('Precondition Failed (412) error')
                  );
                })
              )
          ),
          catchError((error) => {
            // console.error('Error packing data into zip:', error);
            return throwError(() => new Error(error));
          })
        );
      })
    );
  }

  /**
   * архивируем зашифрованный файл
   * @returns {any}
   * @param encrypted
   */
  packToZip(encrypted: any) {
    this.zip.file('encryptDataFile', encrypted, {
      compression: 'DEFLATE',
      compressionOptions: { level: 5 }
    });
    return this.zip.generateAsync({ type: 'blob' });
  }

  /**
   * шифруем файл
   * @param {any} data:any
   * @returns {any}
   */
  encryptData(data: any): Observable<string | null> {
    return this.checkSecretCode().pipe(
      switchMap((secretKey) => {
        if (secretKey) {
          const encryptedData = CryptoJS.AES.encrypt(
            JSON.stringify(data),
            secretKey
          ).toString();
          return of(encryptedData);
        }
        return of(null);
      })
    );
  }

  /**
   * записываем действие по синхронизации бекапа в хранилище
   * @param {any} id:number
   * @param {any} version:number
   * @param {any} action:BackupActions
   * @param {any} idempotencyKey:string
   * @param {any} order?:Order
   * @returns {any}
   */
  writeBackupAction(
    id: number,
    version: number,
    action: BackupActions,
    idempotencyKey: string,
    order?: Order
  ) {
    const email = localStorage.getItem('login');
    if (email) this.email = email;

    const BackupActionTableData = {
      user: this.email,
      Id: id,
      Order: order,
      orderBackupVersion: version,
      backupAction: action,
      idempotencyKey
    };

    return this.dbService
      .getByKey('BackupActionsTable', [this.email, id, version])
      .pipe(
        switchMap((existingRecord) => {
          const currentRecord = existingRecord as BackupActionsInterface;
          if (currentRecord) {
            return this.dbService.update('BackupActionsTable', {
              ...currentRecord,
              ...BackupActionTableData
            });
          }
          return this.dbService.add(
            'BackupActionsTable',
            BackupActionTableData
          );
        }),
        catchError((error) => throwError(error))
      );
  }

  /**
   * Функция для запуска всех операций резервного копирования и обработки результатов
   * @returns {any}
   */
  async executeBackupActions() {
    this.runAllBackupActions().subscribe();
  }

  /**
   * Запускаем все отложенные действия для синхронизации бекапов
   * @returns {any}
   */
  runAllBackupActions(): Observable<any> {
    return this.getLastVersionOfBackupActions().pipe(
      concatMap((actions) => {
        if (!actions || actions.length === 0) {
          return of(null);
        }
        return from(actions).pipe(
          concatMap((action) => {
            const loggedInUser = localStorage.getItem('login');

            if (action.user !== loggedInUser) {
              return from(this.deleteBackupActionsByVersion(
                action.Id,
                Number(action.orderBackupVersion)
              )).pipe(
                catchError(error => {
                  return of(null);  // Возвращаем of(null) чтобы продолжить выполнение
                })
              );
            }

            let operation$: Observable<any>;

            switch (action.backupAction) {
              case BackupActions.SYNC_FROM_CLIENT:
                operation$ = from(this.writeLocalBackupToServer(
                  action.Order,
                  Number(action.orderBackupVersion),
                  action.idempotencyKey,
                  false
                )).pipe(
                  concatMap(() =>
                    from(this.deleteBackupActionsByVersion(
                      action.Id,
                      Number(action.orderBackupVersion)
                    ))
                  )
                );
                break;
              case BackupActions.OVERWRITE_SERVER:
                operation$ = from(this.writeLocalBackupToServer(
                  action.Order,
                  Number(action.orderBackupVersion),
                  action.idempotencyKey,
                  true
                )).pipe(
                  concatMap(() =>
                    from(this.deleteBackupActionsByVersion(
                      action.Id,
                      Number(action.orderBackupVersion)
                    ))
                  )
                );
                break;
              case BackupActions.SYNC_FROM_SERVER:
                operation$ = from(this.writeServerBackupToLocalStore(action.Id)).pipe(
                  concatMap(() =>
                    from(this.deleteBackupActionsByVersion(
                      action.Id,
                      Number(action.orderBackupVersion)
                    ))
                  )
                );
                break;
              default:
                operation$ = of(null);
                break;
            }

            return operation$.pipe(
              catchError(error => {
                return of(null);  // Возвращаем of(null) чтобы продолжить выполнение
              })
            );
          })
        );
      }),
      catchError((error) => {
        // Обработка ошибки при получении actions или других глобальных ошибок
        console.error('Ошибка при выполнении операций резервного копирования:', error);
        return of(null);
      })
    );
  }



  async deleteBackupActionsByVersion(id: number, version: number) {
    const email = localStorage.getItem('login');
    try {
      await this.dbService
        .getAllByIndex('BackupActionsTable', 'Id', IDBKeyRange.only(id))
        .pipe(
          mergeMap((records) => records),
          filter(
            (record: any) =>
              record.Id === id &&
              record.user === email &&
              record.orderBackupVersion <= version
          ),
          toArray()
        )
        .subscribe((filteredRecord) => {
          filteredRecord.forEach((recordForDelete) => {
            this.dbService
              .delete('BackupActionsTable', [
                recordForDelete.user,
                recordForDelete.Id,
                recordForDelete.orderBackupVersion
              ])
              .subscribe();
          });
        });
    } catch (error) {
      // console.error('Error deleting backup actions:', error);
    }
  }

  /**
   * Получаем последнюю версию каждого действия синхронизации от
   * @returns {any}
   */
  getLastVersionOfBackupActions() {
    let originalActions = 0;
    let resultActions: any[] = [];
    const currentUser = localStorage.getItem('login');

    return this.dbService.getAll('BackupActionsTable').pipe(
      catchError((error) => {
        // console.error('Error fetching backup actions:', error);
        return of([]); // Создаем Observable из пустого массива
      }),
      mergeMap((actions) => actions),
      filter((record: any) => record.user === currentUser),
      groupBy((record: any) => record.Id),
      mergeMap((group$) =>
        group$.pipe(
          toArray(),
          tap(() => (originalActions += 1)),
          map((records) =>
            records.sort(
              (a: any, b: any) =>
                Number(b.orderBackupVersion) - Number(a.orderBackupVersion)
            )
          ),
          map((sortedRecords) => sortedRecords[0]),
          tap((r) => resultActions.push(r))
        )
      ),
      toArray(), // Собираем все элементы в массив
      tap(() => {
        if (originalActions !== resultActions.length) {
          resultActions = []; // Очищаем результат, если длины массивов не совпадают
        }
      }),
      map(() => resultActions), // Возвращаем массив resultActions
      defaultIfEmpty([])
    );
  }

  updateLocalOrderBackupVersion(id: number, version: number) {
    this.getOrderDataFromLocalStore(id).subscribe((localOrder) => {
      if (localOrder) {
        localOrder.orderBackupVersion = version;
        this.dbService.update(tableOrdersInEditStatus, localOrder).subscribe();
      }
    });
  }
}
