import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { RtsVehicle } from 'src/app/dto/get-rts-result.dto';
import { HeaderDto } from 'src/app/dto/header.dto';

@Component({
  selector: 'app-edit-car',
  templateUrl: './edit-car.component.html',
  styleUrls: ['./edit-car.component.scss']
})
export class EditCarComponent implements OnInit {
  @Input() editVehicleData?: RtsVehicle;
  @Input() filltrailerRegistrationNumber: boolean;

  setErrorTrailerRegNumbField: boolean;

  constructor(private modalCtrl: ModalController) {}

  ngOnInit(): void {
    if (this.filltrailerRegistrationNumber) {
      const anchorElement = document.getElementById('trailerRegNumbField');
      if (anchorElement) anchorElement.scrollIntoView({ behavior: 'smooth' });
      this.setErrorTrailerRegNumbField = true;
    }
  }

  headerDataValues: HeaderDto = {
    title: 'Изменить информацию о машине'
  };

  vehicleDataUpdated() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  closeModal() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }
}
