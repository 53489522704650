import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pluralizeDays'
})
export class PluralizeDaysPipe implements PipeTransform {
  transform(days: number): string {
    if (days === 1) {
      return 'день';
    }
    if (days > 1 && days < 5) {
      return ' дня';
    }
    return 'дней';
  }
}
