import { DBConfig } from 'ngx-indexed-db';
import { Order } from './dto/order.dto';

export const ExpeditorDB: string = 'ExpeditorDB';
export const tableOrders: string = 'orders';
export const tableOrdersInWork: string = 'ordersInWork';
export const tableOrdersCompleted: string = 'ordersCompleted';

export const tableOrdersInEditStatus: string = 'ordersInEditStatus';

export enum BackupActions {
  OVERWRITE_SERVER = 'OVERWRITE_SERVER',
  SYNC_FROM_SERVER = 'SYNC_FROM_SERVER',
  SYNC_FROM_CLIENT = 'SYNC_FROM_CLIENT',
}
export interface BackupActionsInterface {
  Id: number,
  Order?: Order,
  orderBackupVersion: number,
  backupAction: BackupActions
}

export interface BackupActionsTableInterface {
  user: string;
  Id: number,
  orderBackupVersion: number,
}

export interface OrderInEditStatusInterface {
  Id: number,
  Order: Order,
  orderBackupVersion: number,
}

const backupSyncActionsConf = {
  keyPath: ['user', 'Id', 'orderBackupVersion'],
  autoIncrement: false
};

const pkOrderConf = {
  keyPath: ['pageId', 'filter', 'user'],
  autoIncrement: false
};

const pageIdField = {
  name: 'pageId',
  keypath: 'pageId',
  options: { unique: false }
};

const filterField = {
  name: 'filter',
  keypath: 'filter',
  options: { unique: false }
};

const responseField = {
  name: 'response',
  keypath: 'response',
  options: { unique: false }
};

const userField = { name: 'user', keypath: 'user', options: { unique: false } };

const pkOrdersInEditStatusConf = { keyPath: 'Id', autoIncrement: false };

const orderIdField = { name: 'Id', keypath: 'Id', options: { unique: true } };

const orderBackupVersion = { name: 'orderBackupVersion', keypath: 'orderBackupVersion', options: { unique: false } };

const orderField = {
  name: 'Order',
  keypath: 'Order',
  options: { unique: false }
};

const dataProviderLogin = {
  name: 'login',
  keypath: 'login',
  options: { unique: false }
};
const dataProviderPassword = {
  name: 'password',
  keypath: 'password',
  options: { unique: false }
};

// const syncActionsType = {
//   name: 'syncAction',
//   keypath: 'syncAction',
//   options: { unique: false }
// };

// const actionTargetValue = {
//   name: 'targetValue',
//   keypath: 'targetValue',
//   options: { unique: false }
// };

const syncActions = {
  name: 'syncActions',
  keypath: 'syncActions',
  options: { unique: false, multiEntry: true }
};

const secretKey = {
  name: 'secretKey',
  keypath: 'secretKey',
  options: { unique: false }
};

const backupAction = {
  name: 'backupAction',
  keypath: 'backupAction',
  options: { unique: false }
};

const idempotencyKey = {
  name: 'idempotencyKey',
  keypath: 'idempotencyKey',
  options: { unique: false }
};

const BackupActionsTable = {
  store: 'BackupActionsTable',
  storeConfig: {
    keyPath: ['user', 'Id', 'orderBackupVersion'],
    autoIncrement: false
  },
  storeSchema: [userField, orderIdField, orderField, orderBackupVersion, backupAction, idempotencyKey]
};

const SecretKeyTable = {
  store: 'SecretKeyTable',
  storeConfig: {
    keyPath: 'user',
    autoIncrement: false
  },
  storeSchema: [userField, secretKey]
};

const SmartSeedsTable = {
  store: 'SmartSeedsTable',
  storeConfig: {
    keyPath: 'user',
    autoIncrement: false
  },
  storeSchema: [
    dataProviderLogin,
    dataProviderPassword,
    userField
  ]
};

const SyncActionsTable = {
  store: 'SyncActionsTable',
  storeConfig: {
    keyPath: 'user',
    autoIncrement: false
  },
  storeSchema: [userField, syncActions]
};

export const baseOrderStore = {
  store: tableOrders,
  storeConfig: pkOrderConf,
  storeSchema: [pageIdField, filterField, responseField, userField]
};

export const ordersInWorkStore = { ...baseOrderStore };
ordersInWorkStore.store = tableOrdersInWork;

export const ordersCompletedStore = { ...baseOrderStore };
ordersCompletedStore.store = tableOrdersCompleted;

function saveDeleteObjectStore(db: IDBDatabase, tableName: string) {
  // Проверяем наличие объектного хранилища
  const { objectStoreNames } = db;
  if (objectStoreNames.contains(tableName)) {
    db.deleteObjectStore(tableName);
  }
}

function recreateOrdersObject(db: IDBDatabase, tableName: string) {
  saveDeleteObjectStore(db, tableName);

  const newObjectStore = db.createObjectStore(tableName, pkOrderConf);
  newObjectStore.createIndex(
    pageIdField.name,
    pageIdField.keypath,
    pageIdField.options
  );
  newObjectStore.createIndex(
    filterField.name,
    filterField.keypath,
    filterField.options
  );
  newObjectStore.createIndex(
    responseField.name,
    responseField.keypath,
    responseField.options
  );
  newObjectStore.createIndex(
    userField.name,
    userField.keypath,
    userField.options
  );
}

function createBackupActionsTable(db: IDBDatabase): void {
  const tableName = 'BackupActionsTable';
  saveDeleteObjectStore(db, tableName);

  const newObjectStore = db.createObjectStore(
    tableName,
    backupSyncActionsConf
  );

  newObjectStore.createIndex(
    userField.name,
    userField.keypath,
    { unique: false }
  );

  newObjectStore.createIndex(
    orderIdField.name,
    orderIdField.keypath,
    { unique: false }
  );

  newObjectStore.createIndex(
    orderField.name,
    orderField.keypath,
    orderField.options
  );

  newObjectStore.createIndex(
    orderBackupVersion.name,
    orderBackupVersion.keypath,
    { unique: false }
  );

  newObjectStore.createIndex(
    backupAction.name,
    backupAction.keypath,
    backupAction.options,
  );

  newObjectStore.createIndex(
    idempotencyKey.name,
    idempotencyKey.keypath,
    idempotencyKey.options
  );
}

function createOrdersInEditStatusObject(db: IDBDatabase): void {
  const tableName = tableOrdersInEditStatus;
  saveDeleteObjectStore(db, tableName);

  const newObjectStore = db.createObjectStore(
    tableName,
    pkOrdersInEditStatusConf
  );
  newObjectStore.createIndex(
    orderIdField.name,
    orderIdField.keypath,
    orderIdField.options
  );
  newObjectStore.createIndex(
    orderField.name,
    orderField.keypath,
    orderField.options
  );
  newObjectStore.createIndex(
    orderBackupVersion.name,
    orderBackupVersion.keypath,
    orderBackupVersion.options
  );
}

function createSmartSeedsTable(db: IDBDatabase): void {
  const tableName = 'SmartSeedsTable';
  saveDeleteObjectStore(db, tableName);

  const newObjectStore = db.createObjectStore(tableName, {
    keyPath: 'user',
    autoIncrement: false
  });

  newObjectStore.createIndex(
    'login',
    dataProviderLogin.keypath,
    dataProviderLogin.options
  );

  newObjectStore.createIndex(
    'password',
    dataProviderPassword.keypath,
    dataProviderPassword.options
  );

  newObjectStore.createIndex('user', userField.keypath, userField.options);
}

function createSyncActionsTable(db: IDBDatabase): void {
  const tableName = 'SyncActionsTable';
  saveDeleteObjectStore(db, tableName);

  const newObjectStore = db.createObjectStore(tableName, {
    keyPath: 'user',
    autoIncrement: false
  });

  newObjectStore.createIndex('user', 'user', { unique: true });

  newObjectStore.createIndex(
    'syncActions',
    ['syncActionsType', 'actionTargetValue']
  );
}

function createSecretKeyTable(db: IDBDatabase): void {
  const tableName = 'SecretKeyTable';
  saveDeleteObjectStore(db, tableName);

  const newObjectStore = db.createObjectStore(tableName, {
    keyPath: 'user',
    autoIncrement: false
  });

  newObjectStore.createIndex('user', 'user', { unique: true });

  newObjectStore.createIndex(
    'secretKey',
    secretKey.keypath,
    secretKey.options
  );
}

function createDB(db: IDBDatabase): void {
  recreateOrdersObject(db, tableOrders);
  recreateOrdersObject(db, tableOrdersInWork);
  recreateOrdersObject(db, tableOrdersCompleted);
  createOrdersInEditStatusObject(db);
  createBackupActionsTable(db);
  createSmartSeedsTable(db);
  createSyncActionsTable(db);
  createSecretKeyTable(db);
}

export function migrationFactory() {
  return {
    1: (db: IDBDatabase /* , transaction: IDBTransaction */) => {
      createDB(db);
    },
    2: (db: IDBDatabase, transaction: IDBTransaction) => {
      migrateToVersion2(db, transaction);
    }
  };
}

function migrateToVersion2(db: IDBDatabase, transaction: IDBTransaction): void {
  const store = transaction.objectStore('ordersInEditStatus');
  store.createIndex(userField.name, userField.keypath, userField.options);
}

export const dbConfig: DBConfig = {
  name: 'ExpeditorDB',
  version: 2,
  objectStoresMeta: [
    baseOrderStore,
    ordersInWorkStore,
    ordersCompletedStore,
    SmartSeedsTable,
    SyncActionsTable,
    SecretKeyTable,
    BackupActionsTable
  ],
  migrationFactory
};
