<app-header
  [showBackButton]="true"
  [headerData]="headerDataValues"
  class="full"
></app-header>
<app-offline-mode></app-offline-mode>
<app-car-editor
  style="width: 100%; height: 100%"
  (formValueSubmitted)="addNewCar($event)"
></app-car-editor>
