<ion-content>
  <div class="forgot-password-wrapper">
    <ng-container
      style="width: 100%"
      [ngTemplateOutlet]="
        currentStep === 1
          ? emailStep
          : currentStep === 2
          ? codeStep
          : passwordStep
      "
    ></ng-container>
  </div>
</ion-content>

<ng-template #emailStep>
  <div class="content">
    <div class="title">Введите свой Email</div>
    <form style="width: 100%" [formGroup]="emailForm">
      <app-base-input
        style="width: 100%"
        [boldInput]="true"
        [placeholder]="'Введите Email'"
        formControlName="email"
      ></app-base-input>
    </form>

    <div class="email-note">
      На данный адрес, будет выслан проверочный код для восстановление пароля
    </div>
  </div>

  <div class="button-block">
    <ion-button
      [disabled]="emailForm.invalid"
      class="large login-text"
      mode="md"
      (click)="requestResetPassword()"
    >
      Отправить запрос
    </ion-button>
    <ion-button class="large light" mode="md" (click)="openLogin()">
      Закрыть
    </ion-button>
  </div>
</ng-template>

<ng-template #codeStep>
  <div class="content">
    <div class="title">Введите проверочный код</div>
    <div class="note">
      Письмо с проверочным кодом отправлено на ваш email адрес
      <span class="note" style="font-weight: 600">
        {{ emailForm.get('email')?.value! }}
      </span>
    </div>

    <div *ngIf="requestAlreadySent" style="color: #DD9200" class="note">
      Обратите внимание, письмо с проверочным кодом уже было запрошено ранее.
      Чтобы запросить новое письмо повторите попытку позже
    </div>

    <div *ngIf="changePasswordError" style="color: #f8627d" class="note">
      Что-то пошло не так проверьте правильность введенных данных
    </div>

    <div class="otp-input">
      <div *ngFor="let digit of digits; let i = index">
        <input
          (input)="focusNextInput(i)"
          maxlength="1"
          inputmode="numeric"
          type="text"
          [(ngModel)]="otp[i]"
          id="otp{{ i }}"
          [ngClass]="{
            'error-code-otp': submitCodeErrorFlag && !!otp[i],
            'filled-field': !submitCodeErrorFlag && !!otp[i]
          }"
        />
      </div>
    </div>

    <div class="error-code-text" *ngIf="submitCodeErrorFlag">
      Введен неверный код!
    </div>

    <!--
    <div (click)="resendCode()" class="note resend-code">
      Отправить код еще раз
    </div> -->
  </div>

  <div class="button-block">
    <ion-button
      *ngIf="!presetEmail"
      class="large light"
      mode="md"
      (click)="backToEmail()"
    >
      Назад
    </ion-button>

    <ion-button
      *ngIf="presetEmail"
      class="large light"
      mode="md"
      (click)="openLogin()"
    >
      Закрыть
    </ion-button>
  </div>
</ng-template>

<ng-template #passwordStep>
  <div class="content">
    <div class="title">Введите проверочный код</div>
    <form class="password-form" [formGroup]="passwordForm">
      <div class="form-block">
        <span>Введите новый пароль</span>
        <app-base-input
          type="password"
          style="width: 100%"
          [control]="passwordForm.get('password')"
          formControlName="password"
        ></app-base-input>
      </div>

      <div class="form-block">
        <span>Подтвердите пароль</span>
        <app-base-input
          type="password"
          style="width: 100%"
          [control]="passwordForm.get('passwordRepeat')"
          formControlName="passwordRepeat"
        ></app-base-input>
      </div>
    </form>
  </div>
  <div class="button-block">
    <ion-button
      [disabled]="passwordForm.invalid"
      class="large login-text"
      mode="md"
      (click)="changePassword()"
    >
      Подтвердить
    </ion-button>
    <ion-button class="large light" mode="md" (click)="openLogin()">
      Закрыть
    </ion-button>
  </div>
</ng-template>
