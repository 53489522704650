<script src="../../account-page/account-page.component.ts"></script>
<ion-item-sliding
    #slidingItem
    [disabled]="inWork || isCompleted"
>
    <ion-item-options
        [side]="isAcceptable ? 'start' : 'end'"
        [style.background-color]="getBackgroundColor()"
    >
        <ion-item-option
            *ngIf="isAcceptable"
            class="end"
            color="success"
            mode="md"
            (click)="acceptRequest(order.id)"
            expandable
        >
            Принять заявку
        </ion-item-option>



        <ion-item-option
            *ngIf="isRemovable"
            class="start"
            color="danger"
            mode="md"
            (click)="removeRequest(order.id)"
            expandable
        >
            Отказаться
        </ion-item-option>
    </ion-item-options>
    <ion-item
        class="ion-no-padding"
        button="true"
        mode="md"
        detail="false"
        (click)="openDaysList(order.id)"
    >
        <div class="label-row left">
            <div
                *ngIf="isAcceptable"
                class="label green"
            ></div>
        </div>



        <ion-card
            mode="md"
            class="card"
        >
            <div class="freight-data">
                <div class="text-block">
                    <div class="card-title">{{ order.freightName }}</div>
                    <div *ngFor="let item of order.freightAttributes">
                        <span *ngIf="item.name !== 'ГОСТ'">
                            <span class="freight-attributes">{{ item.name }}:</span>
                            <strong>{{ item.value }} {{item.unit}}</strong>
                        </span>
                    </div>
                    <div>
                        <span class="freight-attributes">Год урожая:</span>
                        <strong>{{ order.cropYear }}</strong>
                    </div>
                  <div>
                    <span class="freight-attributes">Объем перевозки:</span>
                    <strong>{{ order.requestedWeightInTons }}</strong>
                    т.
                  </div>
                </div>
                <div class="icon-block">
                    <div
                        class="freight-icon"
                        *ngIf="orderImagesPath !== '' && !isCompleted && !inWork"
                    >
                        <img
                            [width]="50"
                            alt
                            [height]="50"
                            [src]="orderImagesPath"
                        >
                    </div>
                    <div
                        *ngIf="inWork"
                        class="in-work-label"
                    >
                        В работе
                    </div>
                    <div *ngIf="isCompleted">
                        <ion-icon
                            class="reload-icon"
                            (click)="restoreRequest($event, order.id)"
                            src="../../../../assets/icon/reload-icon.svg"
                        ></ion-icon>
                    </div>
                </div>
            </div>



            <ion-card-content>
                <div class="row section">
                    <div
                        *ngIf="order.sampling"
                        class="half  loading-info"
                    >
                        <strong >
                          <span style="margin-right: 4px;">
                            Погрузка:
                          </span>
                            {{order.shipmentDates.length}}
                            <span class="day-count-text">
                                {{order.shipmentDates.length  | pluralizeDays }}
                            </span>
                        </strong>
                        <div>
                            <strong class="max-ton-per-day">
                                {{order.loading.maxTonPerDay | numberSeparator}}
                            </strong>
                            <span class="metrics-postfix">т / сутки</span>
                        </div>
                        <div>
                            <strong>Начало:</strong>
                            <span class="start-date">
                                {{ order.shipmentDates[0]  | date: 'dd.MM.yyyy' }}
                            </span>
                        </div>
                    </div>
                    <div
                        *ngIf="order.quarantineCertificate"
                        class="half fee-info"
                    >
                        <strong class="marginRight10">Вознаграждение:</strong>
                        <div class="total-fee">
                            {{calculateTotalFee() | numberSeparator }} ₽
                        </div>
                        <div class="fee-per-tone">
                            {{order.forwarderFee | numberSeparator }}
                            <span class="metrics-postfix">₽ / т</span>
                        </div>
                    </div>
                </div>



                <div class="section">
                    <div class="region-name">{{ order.loading.regionName }}</div>
                </div>



                <div class="row space-between">
                    <div class="loading-days">{{generateLoadingsDate()}}</div>
                    <div>
                        <strong>Заявка №</strong>
                        {{ order.id }}
                    </div>
                </div>
            </ion-card-content>
        </ion-card>
        <div class="label-row right">
            <div
                *ngIf="isRemovable"
                class="label red"
            ></div>
        </div>
        <div
            *ngIf="inWork"
            class="label-row right"
        >
            <div class="label disabled"></div>
        </div>
    </ion-item>
</ion-item-sliding>
