<ion-content>
  <div class="screen-wrapper">
    <div class="content">
      <div class="header">
        <h1>Интеграция со</h1>

        <img src="assets/images/smartseed-white-logo.svg" alt="" />
      </div>

      <ng-container
        style="width: 100%"
        [ngTemplateOutlet]="loginStep"
      ></ng-container>

      <div class="note-block">
        <div class="text">
          Интеграция необходима для полноценной работы нашего приложения.
        </div>

        <div class="text">
          Вы можете пропустить шаг и подключиться позже через
          <span> настройки вашего аккаунта. </span>
          Без прохождения этой процедуры не все функции приложения будут
          доступны.
        </div>
      </div>
    </div>

    <span class="inegration-error" *ngIf="integrationError">
      Во время интеграции произошла ошибка. Попробуйте еще раз
    </span>

    <div class="button-block">
      <ion-button
        *ngIf="!awaitFlag && !completedFlag"
        [disabled]="loginForm.invalid"
        class="large login-text"
        mode="md"
        (click)="checkData()"
      >
        Продолжить
      </ion-button>

      <div *ngIf="awaitFlag" class="fake-button connect-to-system">
        <span>Подключение к системе</span>
        <img src="assets/icon/smartseeds-spiner.svg" alt="" />
      </div>

      <div *ngIf="completedFlag" class="fake-button success-connect">
        <img src="assets/icon/checked-icon.svg" alt="" />
        <span>Проверка пройдена</span>
      </div>

      <ion-button
        *ngIf="!this.registrationFlag"
        class="large light"
        mode="md"
        (click)="close()"
      >
        <span> Закрыть </span>
      </ion-button>
      <ion-button
        *ngIf="this.registrationFlag"
        class="large light"
        mode="md"
        (click)="skip()"
      >
        <span> Пропустить </span>
      </ion-button>
    </div>
  </div>
</ion-content>

<ng-template #loginStep>
  <div class="form-title">Введите параметры подключения</div>
  <form style="width: 100%" [formGroup]="loginForm">
    <div class="form-block">
      <span>Логин SmartSeeds</span>
      <app-base-input
        style="width: 100%"
        [placeholder]="'Логин в системе SmartSeeds'"
        formControlName="login"
      ></app-base-input>
    </div>
    <div class="form-block">
      <span>Пароль SmartSeeds</span>
      <app-base-input
        [type]="'password'"
        [placeholder]="'Пароль в системе SmartSeeds'"
        style="width: 100%"
        formControlName="password"
      ></app-base-input>
      <span
        style="font-weight: 400; color: red"
        *ngIf="
          loginForm.get('password')?.hasError('invalidData') &&
          loginForm.get('password')?.dirty
        "
        >Проверьте правильность введенных данных</span
      >
    </div>
  </form>
</ng-template>
