import { Component, Input, OnDestroy } from '@angular/core';
import { v4 as uuid } from 'uuid';
import { ModalController } from '@ionic/angular';
import { BackupService, ConflictVersionModalData } from '../../services/backup.service';
import { BackupActions } from '../../db-description';

@Component({
  selector: 'app-version-conflict-modal',
  templateUrl: './version-conflict-modal.component.html',
  styleUrls: ['./version-conflict-modal.component.scss'],
})
export class VersionConflictModalComponent implements OnDestroy {
  @Input() data: ConflictVersionModalData;
  @Input() id: string;

  localLoading: boolean;
  serverLoading: boolean;

  constructor(
    private backupService: BackupService,
    private modalCtrl: ModalController,
  ) {
  }

  ngOnDestroy(): void {
    this.modalCtrl.dismiss();
  }

  closeFunc() {
    this.modalCtrl.dismiss();
  }

  confirmLocal() {
    this.localLoading = true;
    const idempotencyKey = uuid();
    this.backupService.writeBackupAction(
      this.data.localOrder.id,
      this.data.serverVersion + 1,
      BackupActions.OVERWRITE_SERVER,
      idempotencyKey,
      this.data.localOrder
    ).subscribe(() => {
      // this.localLoading = false;
      this.writeBackupActionCallback();
    });
  }

  confirmServer() {
    this.serverLoading = true;
    const idempotencyKey = uuid();
    this.backupService.writeBackupAction(
      this.data.localOrder.id,
      this.data.serverVersion + 1,
      BackupActions.SYNC_FROM_SERVER,
      idempotencyKey
    ).subscribe(() => {
      // this.serverLoading = false;
      this.writeBackupActionCallback();
    });
  }

  writeBackupActionCallback() {
    this.backupService.updateLocalOrderBackupVersion(
      this.data.localOrder.id,
      this.data.serverVersion + 1
    );
    this.backupService.executeBackupActions();
    this.closeFunc();
  }
}
