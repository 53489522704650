<app-header
  [headerData]="headerDataValues"
  (searchTerms)="getSearchTerms($event)"
  class="full"
></app-header>
<app-offline-mode></app-offline-mode>
<ion-content [scrollY]="false">
  <app-orders
    [ordersListData]="ordersListDataValues"
    [tabName]="'requests'"
    (totalCount)="getTotalCount($event)"
    class="ion-content-scroll-host"
  ></app-orders>
</ion-content>
