import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { HttpClient } from '@angular/common/http';
import jwt_decode from 'jwt-decode';
import { BaseHttpService } from './base-http.service';
import { ConfigService } from './config.service';
import { OfflineModeService } from './offline-mode.service';
import { Status } from '../dto/status.enum';
import { AuthService } from './auth-service.service';
import { SmartSeedsService } from './smart-seeds.service';

@Injectable({
  providedIn: 'root'
})
export class BaseCacheService extends BaseHttpService {
  private curEmail: string;
  constructor(
    protected dbService: NgxIndexedDBService,
    private superHttp: HttpClient,
    private superConfig: ConfigService,
    protected offlineService: OfflineModeService,
    public authService: AuthService,
    protected modalCtrl: ModalController,
    protected smartSeedsService: SmartSeedsService
  ) {
    super(superHttp, superConfig);
  }

  protected getCurOrdersDbName(status?: Status[]): string {
    let storeName: string;
    if (status?.includes(Status.OPEN)) {
      storeName = 'orders';
    } else if (status?.includes(Status.IN_WORK)) {
      storeName = 'ordersInWork';
    } else {
      storeName = 'ordersCompleted';
    }

    return storeName;
  }

  protected getCurEmail(): string {
    if (!this.curEmail) {
      const curToken = this.authService.getAccessToken();
      const jwtDecoded = this.getDecodedAccessToken(curToken);
      this.curEmail = jwtDecoded.email;
      return jwtDecoded.email;
    }
    return this.curEmail;
  }

  private getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (curError) {
      return null;
    }
  }
}
