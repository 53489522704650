import { Component, Input, OnChanges, OnInit, forwardRef, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MaskitoElementPredicate, MaskitoOptions } from '@maskito/core';
import { Platform } from '@ionic/angular';
import {
  trigger,
  state,
  style,
  transition,
  animate
} from '@angular/animations';

@Component({
  selector: 'app-base-input',
  templateUrl: './base-input.component.html',
  styleUrls: ['./base-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BaseInputComponent),
      multi: true
    }
  ],
  animations: [
    trigger('fadeIn', [
      state('void', style({ opacity: 0 })),
      state('*', style({ opacity: 1 })),
      transition('void => *', animate('500ms ease-in'))
    ])
  ]
})
export class BaseInputComponent implements OnInit, OnChanges {
  @Input() title?: string = '';
  @Input() placeholder?: string = '';
  @Input() type?: string = '';
  @Input() boldInput?: boolean;
  @Input() roundCorner?: boolean;
  @Input() control: any;

  customErrorsFieldTypes = ['email', 'inn', 'password', 'secretKey', 'sdiz'];
  showPassword: boolean = false;
  inputValue: string;
  isAndroid: boolean;
  showDatePicker: boolean;
  datePickerValue: string;
  isValid?: boolean = false;
  isInvalid?: boolean = false;
  currentDate: string = new Date().toISOString();

  readonly carNumberMask: MaskitoOptions = {
    mask: [
      /[a-zA-Za-яA-Я]/,
      /\d/,
      /\d/,
      /\d/,
      /[a-zA-Za-яA-Я]/,
      /[a-zA-Za-яA-Я]/,
      /\d/,
      /\d/,
      /\d?/
    ]
  };

  readonly trailerNumberMask: MaskitoOptions = {
    mask: [
      /[a-zA-Za-яA-Я]/,
      /[a-zA-Za-яA-Я]/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d?/
    ]
  };

  readonly dateMask: MaskitoOptions = {
    mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]
  };

  readonly phoneMask: MaskitoOptions = {
    mask: [
      '+',
      '7',
      ' ',
      '(',
      /\d/,
      /\d/,
      /\d/,
      ')',
      ' ',
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/
    ]
  };

  private onChange: any;
  private onTouched: any;

  constructor(private platform: Platform) { }

  get isInvalidTaxNumber(): boolean {
    return this.isInvalid && this.type === 'inn' && (
      this.control.errors.invalidTaxNumber
        || this.control.errors.minlength
        || this.control.errors.maxlength
    );
  }

  readonly maskPredicate: MaskitoElementPredicate = async el =>
    (el as HTMLIonInputElement).getInputElement();

  ngOnInit() {
    this.isAndroid = this.platform.is('android');
  }

  ngOnChanges(): void {
    if (this.control) {
      this.isValid = this.control.valid;
      this.isInvalid = this.control.invalid && this.control.touched;

      this.control.statusChanges.subscribe(() => {
        this.isValid = this.control.valid;
        this.isInvalid = this.control.invalid && this.control.touched;
      });
    }
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: any): void {
    if (this.type === 'calendar' && value) {
      const dateObj = new Date(Number(value));
      const isoDateString = dateObj.toISOString();
      this.datePickerValue = isoDateString;
    }

    this.inputValue = value;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setDisabledState?(isDisabled: boolean): void {
    // Дополнительная логика, если требуется
  }

  updateValue() {
    if (this.type === 'carNumber' || this.type === 'trailerNumber') {
      this.inputValue = this.inputValue.toUpperCase();
    }
    this.onChange(this.inputValue);

    this.onTouched();
    this.validate();
  }

  weightInputValueChanged() {
    const numericValue = parseInt(this.inputValue, 10);
    if (numericValue <= 0) {
      this.inputValue = '1';
    }
    this.onChange(this.inputValue);
    this.onTouched();
    this.validate();
  }

  openDatePicker() {
    if (this.showDatePicker) this.showDatePicker = false;
    else this.showDatePicker = true;
  }

  setDate() {
    this.inputValue = Date.parse(this.datePickerValue).toString();
    this.onChange(this.inputValue);
    this.showDatePicker = false;
    this.validate();
  }

  clearDatePick() {
    this.inputValue = '';
    this.onChange(this.inputValue);
    this.datePickerValue = '';
    this.showDatePicker = false;
  }

  validate() {
    if (this.inputValue) {
      switch (this.type) {
        case 'carNumber':
          if (this.inputValue.length < 7) {
            this.control.setErrors({ incorrect: true });
          }
          break;
        case 'trailerNumber':
          if (this.inputValue.length < 8) {
            this.control.setErrors({ incorrect: true });
          }
          break;
        case 'phone':
          if (this.inputValue.length !== 17) {
            this.control.setErrors({ incorrect: true });
          }
          break;
        default:
          if (this.control) {
            this.control.updateValueAndValidity({ onlySelf: true });
          }
          break;
      }
    }
  }
}
