<app-header
    [showBackButton]="true"
    [headerData]="headerDataValues"
    class="full"
></app-header>
<app-offline-mode></app-offline-mode>


<ion-content>
    <div
        *ngIf="loading"
        class="main-loader"
    >
        <div class="skeleton">
            <ion-skeleton-text
                style="height: 79px; width: 90vw"
                [animated]="true"
            ></ion-skeleton-text>
            <ion-skeleton-text
                style="height: 79px; width: 90vw"
                [animated]="true"
            ></ion-skeleton-text>
            <ion-skeleton-text
                style="height: 79px; width: 90vw"
                [animated]="true"
            ></ion-skeleton-text>
        </div>


        <div class="skeleton-footer">
            <div class="skeleton-wrapper">
                <div class="skeleton-block">
                    <ion-skeleton-text
                        style="height: 10px; width: 120px"
                        [animated]="true"
                    ></ion-skeleton-text>
                    <ion-skeleton-text
                        style="height: 10px; width: 65vw"
                        [animated]="true"
                    ></ion-skeleton-text>
                    <ion-skeleton-text
                        style="height: 10px; width: 50px"
                        [animated]="true"
                    ></ion-skeleton-text>
                </div>


                <div class="skeleton-block">
                    <ion-skeleton-text
                        style="height: 10px; width: 250px"
                        [animated]="true"
                    ></ion-skeleton-text>
                    <ion-skeleton-text
                        style="height: 10px; width: 150px"
                        [animated]="true"
                    ></ion-skeleton-text>
                </div>


                <div class="skeleton-block">
                    <ion-skeleton-text
                        style="height: 10px; width: 120px"
                        [animated]="true"
                    ></ion-skeleton-text>
                    <ion-skeleton-text
                        style="height: 10px; width: 80px"
                        [animated]="true"
                    ></ion-skeleton-text>
                </div>
            </div>
        </div>
    </div>


    <ng-container *ngIf="
      !loading && daysList && daysList.length === 0 && request.status !== 'OPEN'
    ">
        <div class="no-rts-wrapper">
            <div class="title">Невозможно загрузить РТС</div>
            <img
                src="../../../assets/images/no-rts-modal.svg"
                alt
            >
            <div class="note-wrapper">
                <div class="title">РТС для данного дня отсутствует.</div>
                <div class="text">
                    Для загрузки РТС, необходимо подключение к интернету.
                </div>
            </div>
        </div>
    </ng-container>


    <ng-container *ngIf="request?.status !== 'OPEN'">
        <ion-refresher
            [disabled]="isRefresherDisabled && loading"
            class="refresher"
            slot="fixed"
            (ionRefresh)="handleRefresh($event)"
        >
            <ion-refresher-content
                pullingIcon
                refreshingSpinner="lines"
            >
            </ion-refresher-content>
        </ion-refresher>


        <ng-container *ngIf="!loading">
            <ion-item
                class="item ion-no-padding"
                *ngFor="let item of daysList; index as i"
                button="true"
                mode="md"
                (click)="openDayDetails(item.orderNumber)"
            >
                <div class="item-container">
                    <div class="icon">
                        <ion-icon
                            class="checkmark"
                            src="../../../assets/icon/checked-icon.svg"
                            *ngIf="item.completed; else unchecked"
                        ></ion-icon>
                        <ng-template #unchecked>
                            <ion-icon
                                class="checkmark"
                                src="../../../assets/icon/unchecked-icon.svg"
                            ></ion-icon>
                        </ng-template>
                    </div>


                    <div class="day-info">
                        <div class="day-details">
                            <div class="day-number">День {{ i + 1 }}</div>
                            <div>
                                {{ item.shipmentDate.split(' ')[0] }}
                                <div
                                    class="rts-flag red-flag"
                                    *ngIf="!item.vehicles.length"
                                >
                                    Нет РТС
                                </div>
                                <div
                                    class="rts-flag"
                                    *ngIf="item.vehicles.length"
                                >
                                    Есть РТС
                                </div>
                            </div>
                        </div>
                        <div
                            *ngIf="item.vehicles.length"
                            class="car-amount"
                        >
                            Количество машин:
                            <div class="amount">{{ item.vehicles.length }}</div>
                        </div>
                    </div>
                </div>
            </ion-item>
        </ng-container>


        <div
            *ngIf="!loading && !request.statusIsArchive()"
            class="contact-info"
        >
            <div>
                <div class="row">
                    <div>
                        <strong>
                            Номер заказа "SmartSeeds":
                            <span class="smart-number">
                                {{
                                request.smartseedsOrderId }}
                            </span>
                        </strong>
                    </div>
                </div>
                <div class="row">
                    <div>
                        <strong>Пункт погрузки:</strong>
                    </div>
                    <div style="margin-top: 8px">
                        {{ request.loading.title
                        }}
                    </div>
                    <div
                        class="underlined"
                        (click)="copyText(request.loading.address)"
                    >
                        {{ request.loading.address }}
                    </div>
                </div>
                <div class="row">
                    <div>
                        <strong>Организация:</strong>
                    </div>
                    <div
                        class="underlined"
                        (click)="copyText(request.seller.orgName)"
                    >
                        {{ request.seller.orgName }}
                    </div>
                </div>
                <div class="row">
                    <div>
                        <strong>Контакты:</strong>
                    </div>
                    <div *ngIf="request.seller.phone">
                        <a
                            class="phone"
                            [attr.href]="phoneHref"
                        >
                            {{
                            request.seller.phone | phoneNumber
                            }}
                        </a>
                    </div>
                </div>
            </div>
            <a
                [href]="'http://maps.apple.com/?q=' + request.loading.address"
                class="location-section"
            >
                <ion-button
                    class="light square ion-activatable ion-selectable ion-focusable"
                    mode="md"
                >
                    <ion-icon
                        class="location"
                        src="../../../assets/icon/location-icon.svg"
                    ></ion-icon>
                </ion-button>
            </a>
        </div>
        <div
            class="accept-order-block"
            *ngIf="!loading && showAcceptBlock"
        >
            <ion-button
                (click)="acceptOrder()"
                class="primary large"
                mode="md"
            >
                Принять заявку
            </ion-button>
        </div>
        <div
            class="accept-order-block"
            *ngIf="!loading && showEndOrderBlock"
        >
            <ion-button
                (click)="endOrder()"
                class="primary large"
                mode="md"
            >
                Завершить заявку
            </ion-button>
        </div>


        <div
            *ngIf="
        !loading &&
        request.statusIsArchive() &&
        request.deliveredWeightInKilograms === 0
      "
            class="archive-footer-wrapper"
        >
            <div class="wait-data-banner">
                <div class="title">Ожидаем данные по выгрузке</div>


                <div class="spiner-wrapper">
                    <img
                        src="assets/icon/big-spiner.svg"
                        alt
                    >
                </div>


                <div class="unload-location-note">
                    <span class="note-title">Пункт разгрузки:</span>
                    <span class="address">{{ request.unloading.title }}</span>
                    <span class="address">{{ request.unloading.address }}</span>
                </div>
            </div>
        </div>
    </ng-container>


    <ng-container *ngIf="
      !loading &&
      request.statusIsArchive() &&
      request.deliveredWeightInKilograms !== 0
    ">
        <div class="archive-footer-wrapper">
            <div class="additional-button-wrapper">
                <div
                    (click)="downloadForwarderReport()"
                    class="download-button"
                >
                    <img
                        src="assets/icon/download-file-icon.svg"
                        alt
                    >
                    <span>Отчет экспедитора</span>
                </div>
            </div>
        </div>
    </ng-container>


    <ng-container *ngIf="!loadingSpinner && !loading && request.status === 'OPEN'">
        <div class="section ">
            <div class="freight-data">
                <div class="text-block">
                    <div class="card-title">{{ request.freightName }}</div>
                    <div *ngFor="let item of request.freightAttributes">
                        <span *ngIf="item.name !== 'ГОСТ'">
                            <span class="freight-attributes">
                                {{ item.name
                                }}:
                            </span>
                            <strong>{{ item.value }} {{item.unit}}</strong>
                        </span>
                    </div>
                    <div>
                        <span class="freight-attributes">Год урожая:</span>
                        <strong>{{ request.cropYear }}</strong>
                        т.
                    </div>
                </div>
                <div class="icon-block">
                    <div
                        class="freight-icon"
                        *ngIf="generateOrderImgPath()"
                    >
                        <img
                            [width]="50"
                            [height]="50"
                            [src]="generateOrderImgPath()"
                        >
                    </div>
                </div>
            </div>
        </div>


        <div class="loading-block">
            <div class="start-loading">
                <div class="title">Начало погрузки:</div>
                <div class="value">
                    {{ request.shipmentDates[0] | date: 'dd.MM.yyyy' }}
                </div>
            </div>
            <div class="split-section">
                <div class="weight-block">
                    <div class="total-weight">
                        <div class="title">Вес товара:</div>
                        <div class="value">
                            <span style="color: #00bfa8; font-weight: 700">
                                {{request.requestedWeightInTons
                                }}
                            </span>
                            тонн
                        </div>
                    </div>
                    <div class="total-weight">
                        <div class="title">Скорость погрузки:</div>
                        <div class="value">
                            <span style="font-weight: 700">
                                {{request.loading.maxTonPerDay |
                                numberSeparator}}
                            </span>
                            т / сутки
                        </div>
                    </div>
                  <div class="total-weight">
                    <div class="title">Вознаграждение экспедитора:</div>
                    <div class="value">
                        <span style="color: #00bfa8; font-weight: 700">
                        {{calculateTotalFee() | numberSeparator }} ₽
                        </span>
                    </div>
                  </div>

                </div>
                <div class="section-divider"></div>
                <div class="loading-data">
                    <div class="title">Кол-во дней погрузки:</div>
                    <span class="day-count">
                        <span class="count">
                            {{ request.shipmentDates.length
                            }}
                        </span>
                        {{ request.shipmentDates.length | pluralizeDays }}
                    </span>


                    <div
                        class="loading-dates"
                        *ngFor="let monthYear of (request.shipmentDates | groupDates | keyvalue)"
                    >
                        <div class="month">{{ monthYear.key }}:</div>
                        <div class="days">{{ monthYear.value }}</div>
                    </div>
                </div>
            </div>
        </div>


        <div class="section">
            <div
                class="document-flags"
                *ngIf="request.sampling"
            >
                <img
                    src="../../../assets/icon/checked-icon-round.svg"
                    alt
                >
                <div class="text">Проба есть</div>
            </div>
            <div
                class="document-flags"
                *ngIf="request.quarantineCertificate"
            >
                <img
                    src="../../../assets/icon/checked-icon-round.svg"
                    alt
                >
                <div class="text">Карантинный сертификат есть</div>
            </div>


            <div
                class="document-flags"
                *ngIf="!request.sampling"
            >
                <img
                    src="../../../assets/icon/warning-icon.svg"
                    alt
                >
                <div class="text">Необходима проба</div>
            </div>
            <div
                class="document-flags"
                *ngIf="!request.quarantineCertificate"
            >
                <img
                    src="../../../assets/icon/warning-icon.svg"
                    alt
                >
                <div class="text">Необходим карантинный сертификат</div>
            </div>


        </div>


        <div class="section">
            <div class="title">Пункт погрузки:</div>
            <div
                class="address"
                style="font-weight: 600; text-decoration: none !important"
            >
                {{ request.loading.title }}
            </div>
            <div class="address">{{ request.loading.address }}</div>
            <a
                [href]="'http://maps.apple.com/?q=' + request.loading.address"
                class="location-section"
            >
                <ion-button
                    class="light square ion-activatable ion-selectable ion-focusable"
                    mode="md"
                >
                    <ion-icon
                        class="location"
                        src="../../../assets/icon/location-icon.svg"
                    ></ion-icon>
                </ion-button>
            </a>
        </div>


        <div class="section">
            <div class="title">Организация продавца:</div>
            <div class="value">
                {{ request.seller.orgName }}
                <span style="margin-left: 4px;" *ngIf="request.seller.inn">ИНН {{ request.seller.inn}}</span>
            </div>
            <div class="title">Контакты продавца товара:</div>
            <a
                class="phone-number"
                href="tel:{{  request.seller.phone  }}"
            >
                {{ request.seller.phone | phoneNumber }}
            </a>
        </div>
        <div
            class="accept-order-block"
            *ngIf="!loading && showAcceptBlock"
        >
            <ion-button
                (click)="acceptOrder()"
                class="primary large"
                mode="md"
            >
                Принять заявку
            </ion-button>
        </div>
    </ng-container>
</ion-content>


<ion-toast
    [isOpen]="isToastOpen"
    message="Скопировано"
    [duration]="3000"
    (didDismiss)="toastSetStatus(false)"
    [animated]="true"
></ion-toast>
