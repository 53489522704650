import {Component, OnInit} from '@angular/core';
import { SearchItem } from '../../../dto/search-item.dto';
import { Status } from '../../../dto/status.enum';
import { HeaderDto } from '../../../dto/header.dto';
import { OrdersListDto } from '../../../dto/orders-list.dto';

@Component({
  selector: 'app-active-orders',
  templateUrl: './active-orders.component.html',
  styleUrls: ['./active-orders.component.scss']
})
export class ActiveOrdersComponent implements OnInit {
  headerDataValues: HeaderDto = {
    statuses: [Status.OPEN],
    totalCount: 0,
    title: 'Заявки',
    tabRoute: 'requests'
  };

  ordersListDataValues: OrdersListDto = {
    statuses: [Status.OPEN],
    searchTerms: []
  };

  ngOnInit(): void {
  }

  getSearchTerms(searchTerms: SearchItem[]) {
    this.ordersListDataValues = {
      ...this.ordersListDataValues,
      searchTerms: [...searchTerms]
    };
  }

  getTotalCount(totalCount: number) {
    this.headerDataValues = {
      ...this.headerDataValues,
      totalCount
    };
  }
}
