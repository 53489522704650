import { CanDeactivateFn, UrlTree } from "@angular/router";
import { Observable } from "rxjs";

export type CanDeactivateType = Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree;


export interface CanComponentDeactivate {
  canDeactivate: () => CanDeactivateType;
  secretKeyAccepted :boolean;
  smartSeedsAccepted:boolean;
}

export const canDeactivateGuard: CanDeactivateFn<CanComponentDeactivate> = (component: CanComponentDeactivate) => {
  return component.secretKeyAccepted;
};

