import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class BaseHttpService {
  public readonly baseRestURL: string = '';

  constructor(protected http: HttpClient, protected config: ConfigService) {
    this.baseRestURL = config.baseRestURL;
  }
}
