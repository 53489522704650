<ion-content *ngIf="!showCodeForm">
  <app-header
    [showBackButton]="true"
    [headerData]="headerDataValues"
    class="full"
  ></app-header>
  <div class="reg-form">
    <div class="reg-note">
      <img src="assets/icon/warning-icon.svg" alt="" />
      <div class="text">
        <b>Внимание!</b>
        <br />
        Все поля — обязательны для заполнения!
      </div>
    </div>

    <form [formGroup]="regForm">
      <app-base-input
        style="width: 100%"
        [title]="'ИНН ИП'"
        [type]="'inn'"
        [control]="regForm.get('inn')"
        formControlName="inn"
        id="inn"
      ></app-base-input>

      <app-base-input
        style="width: 100%"
        [title]="'Фамилия'"
        formControlName="lastName"
      ></app-base-input>

      <app-base-input
        style="width: 100%"
        [title]="'Имя'"
        formControlName="firstName"
      ></app-base-input>


      <app-base-input
        style="width: 100%"
        [title]="'Отчество'"
        formControlName="patronymicName"
      ></app-base-input>

      <app-base-input
        [type]="'email'"
        style="width: 100%"
        [title]="'Email'"
        [control]="regForm.get('email')"
        formControlName="email"
        id="email"
      ></app-base-input>

      <app-base-input
        type="phone"
        style="width: 100%"
        [title]="'Контактный телефон'"
        [control]="regForm.get('phone')"
        formControlName="phone"
      ></app-base-input>

      <app-base-input
        type="password"
        style="width: 100%"
        [title]="'Пароль'"
        [control]="regForm.get('password')"
        formControlName="password"
      ></app-base-input>

      <app-base-input
        type="password"
        style="width: 100%"
        [title]="'Подтверждение пароля'"
        [control]="regForm.get('passwordRepeat')"
        formControlName="passwordRepeat"
      ></app-base-input>

      <div class="checkbox-block">
        <ion-checkbox
          (click)="acceptUserDataAgreement()"
          formControlName="userDataAgreement"
          labelPlacement="end"
        >
        </ion-checkbox>
        <span>
          <span style="cursor: pointer" (click)="acceptUserDataAgreement()">
            Я принимаю
          </span>
          <span class="pseudo-link"
            >условия обработки пользовательских данных
          </span>
        </span>
      </div>

      <div class="checkbox-block">
        <ion-checkbox
          (click)="acceptLicenseAgreement()"
          formControlName="licenseAgreement"
        ></ion-checkbox>
        <span>
          <span style="cursor: pointer" (click)="acceptLicenseAgreement()">
            Я принимаю
          </span>
          <span class="pseudo-link">условия пользовательского соглашения </span>
        </span>
      </div>

      <ion-button
        [disabled]="
          regForm.invalid ||
          !regForm.get('userDataAgreement')!.value ||
          !regForm.get('licenseAgreement')!.value
        "
        class="large login-text"
        mode="md"
        (click)="registration()"
        >Регистрация</ion-button
      >
    </form>
  </div>
</ion-content>

<ion-content *ngIf="showCodeForm">
  <div class="code-page-wrapper">
    <div class="code-form">
      <div class="title">Введите проверочный код</div>
      <div class="note">
        Письмо с проверочным кодом отправлено на ваш email адрес
        <span class="note" style="font-weight: 600">
          {{ regForm.get('email')?.value! }}
        </span>
      </div>

      <div class="otp-input">
        <div *ngFor="let digit of digits; let i = index">
          <input
            (input)="focusNextInput(i)"
            maxlength="1"
            inputmode="numeric"
            type="text"
            [(ngModel)]="otp[i]"
            id="otp{{ i }}"
            [ngClass]="{
              'error-code-otp': submitCodeErrorFlag && !!otp[i],
              'filled-field': !submitCodeErrorFlag && !!otp[i]
            }"
          />
        </div>
      </div>

      <div class="error-code-text" *ngIf="submitCodeErrorFlag">
        Введен неверный код!
      </div>

      <div (click)="resendCode()" class="note resend-code">
        Отправить код еще раз
      </div>
    </div>

    <ion-button class="large light" mode="md" (click)="openLogin()"
      >Закрыть</ion-button
    >
  </div>
</ion-content>

<ion-toast
  [isOpen]="isToastOpen"
  position="top"
  message="Код повторно отправлен на почту"
  [duration]="2000"
  (didDismiss)="toastSetStatus(false)"
  [animated]="true"
></ion-toast>
