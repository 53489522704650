/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { convert as convertNumberToWordsRu } from 'number-to-words-ru';
import { RtsVehicle } from 'src/app/dto/get-rts-result.dto';
import { Order } from '../../dto/order.dto';
import { ExcelGeneratorData } from '../../dto/excel-generation-data.dto';
import { XlsxTemplateFillerServiceService } from './xlsx-template-filler-service.service';
import { DateTimeService } from './date-time.service';

@Injectable({
  providedIn: 'root'
})
export class TtnGeneratorService {
  private readonly ttnTemplatePath: string = 'assets/docs/ttn-template.xlsx';

  constructor(
    private xlsxTemplateFillerServiceService: XlsxTemplateFillerServiceService,
    private dateTimeService: DateTimeService
  ) { }

  async getTtn(order: Order, carInfo: RtsVehicle, carIndex: number): Promise<void> {
    const data = await this.generateTtnDataStructure(order, carInfo, carIndex);
    await this.xlsxTemplateFillerServiceService.generateAndOpenNewExcelFile(
      this.ttnTemplatePath,
      data,
      `ТТН-${order.id}`

    );
  }

  private async generateTtnDataStructure(order: Order, carInfo: RtsVehicle, carIndex: number) {
    const generateTtnData: ExcelGeneratorData = {
      cellDataArray: []
    };

    const today = new Date();
    generateTtnData.cellDataArray.push({
      address: 'AA5',
      value: `${order.id}-${(today.getMonth() + 1).toString().padStart(2, '0')}${(today.getDate()).toString().padStart(2, '0')}${(carIndex).toString().padStart(2, '0')}`,
      name: 'id',
      pageNumber: 1
    });

    generateTtnData.cellDataArray.push({
      address: 'I6',
      value: `${new Date().getDate()}`,
      name: 'day',
      pageNumber: 1
    });
    generateTtnData.cellDataArray.push({
      address: 'L6',
      value: this.dateTimeService.getMonthString(),
      name: 'month',
      pageNumber: 1
    });

    generateTtnData.cellDataArray.push({
      address: 'V6',
      value: `${new Date().getFullYear() % 100}`,
      name: 'year',
      pageNumber: 1
    });

    generateTtnData.cellDataArray.push({
      address: 'AK7',
      value: `${new Date().getDate()}`,
      name: 'create-day',
      pageNumber: 1
    });
    generateTtnData.cellDataArray.push({
      address: 'AL7',
      value: this.dateTimeService.getMonthNumberString(),
      name: 'create-month',
      pageNumber: 1
    });

    generateTtnData.cellDataArray.push({
      address: 'AO7',
      value: `${new Date().getFullYear()}`,
      name: 'year',
      pageNumber: 1
    });

    if (order.forwarder) {
      let val = '';
      if (order.forwarder.orgName) val += `${order.forwarder.orgName} `;
      if (order.forwarder.inn) val += ` ${order.forwarder.inn} `;
      if (order.forwarder.address) val += ` ${order.forwarder.address} `;
      if (order.forwarder.phone) val += ` тел. ${order.forwarder.phone}`;

      generateTtnData.cellDataArray.push({
        address: 'F9',
        value: val,
        name: 'expeditor-data',
        pageNumber: 1
      });

      generateTtnData.cellDataArray.push({
        address: 'H21',
        value: `${order.forwarder.orgName}, ${order.forwarder.inn}, ${order.forwarder.address}; тел. ${order.forwarder.phone}`,
        name: 'expeditor-data2',
        pageNumber: 1
      });
    }

    if (carInfo.model) {
      generateTtnData.cellDataArray.push({
        address: 'H10',
        value: `${carInfo.model}`,
        name: 'car-name',
        pageNumber: 1
      });
    }

    if (carInfo.registrationNumber) {
      generateTtnData.cellDataArray.push({
        address: 'V10',
        value: `${carInfo.registrationNumber}`,
        name: 'car-number',
        pageNumber: 1
      });
    }

    if (carInfo) {
      let val = '';
      if (carInfo.carrierName) val += carInfo.carrierName;
      if (carInfo.carrierInn) val += carInfo.carrierInn;

      generateTtnData.cellDataArray.push({
        address: 'O12',
        value: val,
        name: 'owner-info',
        pageNumber: 1
      });
    }

    if (carInfo.driverName) {
      generateTtnData.cellDataArray.push({
        address: 'E15',
        value: `${carInfo.driverName}`,
        name: 'driver-name',
        pageNumber: 1
      });
    }

    if (order.buyer) {
      let val = '';
      if (order.buyer?.orgName) val += order.buyer.orgName;
      if (order.buyer?.inn) val += order.buyer.inn;
      if (order.buyer?.address) val += order.buyer.address;
      if (order.buyer?.phone) val += `тел. ${order.buyer.phone}`;

      generateTtnData.cellDataArray.push({
        address: 'F18',
        value: val,
        name: 'buyer-data',
        pageNumber: 1
      });

      generateTtnData.cellDataArray.push({
        address: 'H29',
        value: val,
        name: 'buyer-data',
        pageNumber: 1
      });
    }

    if (order.loading.address) {
      generateTtnData.cellDataArray.push({
        address: 'E25',
        value: `${order.loading.address}`,
        name: 'seller-location',
        pageNumber: 1
      });
    }

    if (order.unloading.address) {
      generateTtnData.cellDataArray.push({
        address: 'F32',
        value: `${order.unloading.address}`,
        name: 'buyer-location',
        pageNumber: 1
      });
    }

    if (carInfo.trailerRegistrationNumber) {
      generateTtnData.cellDataArray.push({
        address: 'U36',
        value: `${carInfo.trailerRegistrationNumber}`,
        name: 'trail-number',
        pageNumber: 1
      });
    }

    if (order.freightName) {
      generateTtnData.cellDataArray.push({
        address: 'F42',
        value: `${order.freightName}`,
        name: 'product-data',
        pageNumber: 1
      });
    }

    if (order.freightName && order.cropYear) {
      generateTtnData.cellDataArray.push({
        address: 'F42',
        value: `${order.freightName}, год урожая ${order.cropYear}`,
        name: 'product-data',
        pageNumber: 1
      });
    }

    if (order.freightName) {
      generateTtnData.cellDataArray.push({
        address: 'F45',
        value: `${order.freightName.split(' ').slice(1).join(' ')}`,
        name: 'product-quality',
        pageNumber: 1
      });
    }

    generateTtnData.cellDataArray.push({
      address: 'R50',
      value: `${carInfo.loadingData.grossWeight}`,
      name: 'gross-weight',
      pageNumber: 1
    });

    generateTtnData.cellDataArray.push({
      address: 'V50',
      value: `${carInfo.loadingData.tareWeight}`,
      name: 'tare-weight',
      pageNumber: 1
    });

    generateTtnData.cellDataArray.push({
      address: 'Z50',
      value: `${carInfo.loadingData.netWeight}`,
      name: 'net-weight',
      pageNumber: 1
    });

    generateTtnData.cellDataArray.push({
      address: 'AB67',
      value: convertNumberToWordsRu(carInfo.loadingData.netWeight, {
        showNumberParts: {
          fractional: false
        },
        showCurrency: {
          integer: false
        }
      }).toLocaleLowerCase(),
      name: 'net-weight-words',
      pageNumber: 1
    });

    if (order.forwarder.orgName) {
      generateTtnData.cellDataArray.push({
        address: 'AF64',
        value: `${order.forwarder.orgName}`,
        name: 'forwarder-name',
        pageNumber: 1
      });

      generateTtnData.cellDataArray.push({
        address: 'O70',
        value: `${order.forwarder.orgName}`,
        name: 'forwarder-name',
        pageNumber: 1
      });
    }

    if (carInfo.driverName) {
      generateTtnData.cellDataArray.push({
        address: 'AK70',
        value: `${carInfo.driverName}`,
        name: 'driver-name',
        pageNumber: 1
      });
    }

    generateTtnData.cellDataArray.push({
      address: 'F73',
      value: `Протокол испытания номер № ${carInfo.loadingData.testingProtocolNumber} от ${this.dateTimeService.getDateFromTimestamp(carInfo.loadingData.testingProtocolDate)},
       Декларация соответствия ${carInfo.loadingData.declarationOfConformityNumber} от ${this.dateTimeService.getDateFromTimestamp(carInfo.loadingData.declarationOfConformityDate)},
      Карантинный сертификат ${carInfo.loadingData.quarantineCertificateNumber} от ${this.dateTimeService.getDateFromTimestamp(carInfo.loadingData.quarantineCertificateDate)}, СДИЗ ${carInfo.loadingData.sdizNumber} `,
      name: 'additional-docs',
      pageNumber: 1
    });

    if (order.forwarder.orgName) {
      generateTtnData.cellDataArray.push({
        address: 'F16',
        value: `${order.forwarder.orgName}`,
        name: 'forwarder-name',
        pageNumber: 2
      });
    }

    if (order.buyer.orgName) {
      generateTtnData.cellDataArray.push({
        address: 'F16',
        value: `${order.buyer.orgName}`,
        name: 'buyer-name',
        pageNumber: 2
      });
    }

    order.generateTtnActions(generateTtnData.cellDataArray);

    return generateTtnData;
  }
}
