import {
  ChangeDetectionStrategy,
  Component, OnDestroy,
  Optional,
  Self,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import {
  Maskito,
  MaskitoOptions,
} from '@maskito/core';
import { maskitoWithPlaceholder } from '@maskito/kit';
import { IonInput } from '@ionic/angular';
import { distinctUntilChanged, Subject, Subscription } from 'rxjs';
import { TaxNumberValidatorService } from '../../services/tax-number-validator.service';

type ChangeCallbackFn = (value: string) => void;
type TouchedCallbackFn = () => void;

@Component({
  selector: 'app-sdiz-input',
  templateUrl: './sdiz-input.component.html',
  styleUrls: ['./sdiz-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SdizInputComponent implements ControlValueAccessor, OnDestroy {
  @ViewChild(IonInput, { static: true }) ionInputEl: IonInput;

  readonly placeholderLong: string = '____________/__/_';
  readonly maskLong: MaskitoOptions = {
    ...maskitoWithPlaceholder(this.placeholderLong, true),
    mask: [
      ...Array(12).fill(/\d/),
      '/',
      ...Array(2).fill(/\d/),
      '/',
      ...Array(7).fill(/\d/)
    ],
  };

  readonly placeholderShort: string = '__________/__/_';
  readonly maskShort: MaskitoOptions = {
    ...maskitoWithPlaceholder(this.placeholderShort, true),
    mask: [
      ...Array(10).fill(/\d/),
      '/',
      ...Array(2).fill(/\d/),
      '/',
      ...Array(7).fill(/\d/)
    ],
  };

  private readonly maskChange: Subject<MaskitoOptions>;
  private readonly maskChangeSubscription: Subscription;

  private maskito?: Maskito;

  constructor(
    @Self() @Optional() private readonly ngControl: NgControl,
    private readonly taxNumberValidator: TaxNumberValidatorService
  ) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
    this.maskChange = new Subject();
    this.maskChangeSubscription = this.maskChange
      .pipe(distinctUntilChanged())
      .subscribe(maskOptions => this.initMaskito(maskOptions));
  }

  get formControl(): FormControl {
    return <FormControl> this.ngControl.control;
  }

  onChange: ChangeCallbackFn = (): void => { };
  onTouch: TouchedCallbackFn = (): void => { };

  writeValue(value: string): void {
    if (value != null && value !== '') {
      this.updateMask(value);
    }
  }

  registerOnChange(fn: ChangeCallbackFn): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: TouchedCallbackFn): void {
    this.onTouch = fn;
  }

  ngOnDestroy(): void {
    if (this.maskito) {
      this.maskito.destroy();
    }
    this.maskChangeSubscription.unsubscribe();
  }

  onInput(event: Event): void {
    const { value } = <HTMLInputElement> event.target;
    if (value != null && value !== '') {
      this.updateMask(value);
    }
  }

  private async initMaskito(mask: MaskitoOptions): Promise<void> {
    if (this.maskito) {
      this.maskito.destroy();
      delete this.maskito;
    }
    if (this.ionInputEl) {
      const inputEl = await this.ionInputEl.getInputElement();
      this.maskito = new Maskito(inputEl, mask);
      // NOTE: see maskito/projects/core/src/lib/mask.ts
      // NOTE: see maskito/projects/kit/src/lib/processors/with-placeholder.ts
      inputEl.dispatchEvent(new Event('input', { bubbles: true }));
      inputEl.dispatchEvent(new Event('focus', { bubbles: true }));
    }
  }

  private updateMask(value: string): void {
    const cleanValue: string = value?.replace(/\D+/g, '');
    const shortTaxNumLength: number = 10;
    let maskOptions: MaskitoOptions;
    if (cleanValue.length < shortTaxNumLength) {
      maskOptions = this.maskLong;
    } else {
      const longTaxNumberLength: number = 12;
      const isValidShortTaxNumber: boolean = this.taxNumberValidator.checkIfValidTaxNumber(
        cleanValue.substring(0, shortTaxNumLength)
      );
      const isValidLongTaxNumber: boolean = cleanValue.length >= longTaxNumberLength
        && this.taxNumberValidator.checkIfValidTaxNumber(
          cleanValue.substring(0, longTaxNumberLength)
        );
      if (isValidShortTaxNumber && isValidLongTaxNumber) {
        const lastTwoDigits: string = cleanValue.substring(shortTaxNumLength, longTaxNumberLength);
        maskOptions = this.isValidYearIndicator(lastTwoDigits)
          ? this.maskShort
          : this.maskLong;
      } else if (isValidShortTaxNumber) {
        maskOptions = this.maskShort;
      } else {
        maskOptions = this.maskLong;
      }
    }
    this.maskChange.next(maskOptions);
  }

  private isValidYearIndicator(year: string): boolean {
    let valid: boolean = false;
    if (year.length === 2) {
      const shortYear: number = new Date().getFullYear() % 1000;
      valid = Number(year) <= shortYear;
    }
    return valid;
  }
}
