import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { BehaviorSubject } from 'rxjs';
import { BaseHttpService } from './base-http.service';
import { ConfigService } from './config.service';

export enum AppModeStatus {
  ONLINE = 'online',
  OFFLINE = 'offline'
}

@Injectable({
  providedIn: 'root'
})
export class OfflineModeService extends BaseHttpService {
  public appModeStatus$: BehaviorSubject<AppModeStatus>
    = new BehaviorSubject(AppModeStatus.ONLINE as AppModeStatus);

  constructor(
    protected dbService: NgxIndexedDBService,
    private superHttp: HttpClient,
    private superConfig: ConfigService,
    protected modalCtrl: ModalController
  ) {
    super(superHttp, superConfig);
  }
}
