import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { BaseHttpService } from './base-http.service';
import { ConfigService } from './config.service';
import { LoginResponse } from './user-management.service';

export interface RegistrationRequestData {
  userType: 'SELF_EMPLOYED';
  firstName: string;
  lastName: string;
  patronymicName: string;
  inn: string;
  email: string;
  phone: string;
  password: string;
  name: string;
}
interface ChangePasswordCheckResponse {
  success: true;
  data: false;
}

export interface CheckInnDTO {
  success: boolean;
  data: {
    name: string;
    firstName: string;
    lastName: string;
    patronymicName: string;
    email: string;
    phone: string;
  };
}

@Injectable({
  providedIn: 'root'
})
export class RegistrationService extends BaseHttpService {
  constructor(
    private superHttp: HttpClient,
    private superConfig: ConfigService
  ) {
    super(superHttp, superConfig);
  }

  registration(registrationRequestData: RegistrationRequestData) {
    return this.http.post<any>(
      `${this.baseRestURL}usermanagement/registration/mobile`,
      registrationRequestData,
      { observe: 'response' }
    );
  }

  checkInn(inn: string, userType?: 'SELF_EMPLOYED' | 'LEGAL_ENTITY'): Observable<CheckInnDTO> {
    const curUrl = `${this.baseRestURL}usermanagement/registration/validate-inn`;
    let type: string = userType || '';
    if (!userType) {
      type = inn.length === 12 ? 'SELF_EMPLOYED' : 'LEGAL_ENTITY';
    }
    const options = {
      type,
      inn,
      checkUnique: false
    };
    return this.http
      .post<CheckInnDTO>(curUrl, options)
      .pipe(catchError(err => throwError(err.error)));
  }

  sendCode(email: string, code: string) {
    const curUrl = `${this.baseRestURL}usermanagement/registration/verify-email/code`;
    return this.http.post<LoginResponse>(curUrl, { email, code });
  }

  resendCode(email: string) {
    const curUrl = `${this.baseRestURL}usermanagement/registration/verify-email/resend/${email}`;
    return this.http.post<any>(curUrl, {});
  }

  requestResetPassword(email: string) {
    const params = new HttpParams().set('email', email);
    const curUrl = `${this.baseRestURL}usermanagement/registration/reset-password/mobile`;
    return this.http.post<any>(curUrl, {}, { params, observe: 'response' });
  }

  changePassword(email: string, password: string, code: string) {
    return this.http.post<any>(
      `${this.baseRestURL}usermanagement/registration/change-password/mobile`,
      { email, password, code },
      { observe: 'response' }
    );
  }

  changePasswordCheckCode(
    email: string,
    code: string
  ): Observable<ChangePasswordCheckResponse> {
    return this.http.post<ChangePasswordCheckResponse>(
      `${this.baseRestURL}usermanagement/registration/validate-code/password`,
      { email, code }
    );
  }
}
