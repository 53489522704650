<div class="conflict-wrap">

  <div class="title">
    Возник конфликт версий
  </div>

  <img alt="" src="assets/images/version-conflict.svg">

  <span class="text-content">
          При синхронизации данных заявки <b>{{ data.localOrder.id }}</b> возник конфликт версий. Выберите версию для загрузки на устройство.
          <b>Внимание:</b> действие невозможно отменить и оно может привести
          к потере данных.
        </span>


  <ion-button
    (click)="confirmLocal()"
    [disabled]="serverLoading&&localLoading"
    class=" large custom-col"
  >
    <span *ngIf="!localLoading">
      Загрузить локальную версию
    </span>
    <ion-spinner *ngIf="localLoading" name="dots"></ion-spinner>
  </ion-button>

  <ion-button
    (click)="confirmServer()"
    [disabled]="serverLoading&&localLoading"
    class=" large custom-col"
  >
    <span *ngIf="!serverLoading">
      Загрузить серверную версию
    </span>
    <ion-spinner *ngIf="serverLoading" name="dots"></ion-spinner>
  </ion-button
  >
</div>
