<ion-header>
  <ion-toolbar>
    <div class="container">
      <div class="left-block">
        <ion-back-button
          defaultHref="request"
          *ngIf="showBackButton"
          text=""
          icon="../../../assets/icon/back-button-light.svg"
        ></ion-back-button>
      </div>


      <div class="center-block">
        <h2>{{ headerData.title }}</h2>
      </div>


      <div class="right-block">
        <div class="count">
          <h4>{{ headerData.totalCount }}</h4>
        </div>

        <div
          *ngIf="headerData.options"
          (click)="openRTCActions()"
          class="options"
        >
          <ion-icon
            src="../../../assets/icon/options-icon-light.svg"
          ></ion-icon>
        </div>

        <div
          *ngIf="headerData.downloadOption"
          class="ion-activatable ripple-parent circle"
        >
          <ion-icon src="../../../assets/icon/download-icon.svg"></ion-icon>
          <ion-ripple-effect> </ion-ripple-effect>
        </div>
      </div>

      <div
        *ngIf="closableIcon"
        (click)="hideModalWindow()"
        class="ion-activatable ripple-parent circle close-icon"
      >
        <img src="assets/icon/close-icon.svg" alt="" />
        <ion-ripple-effect> </ion-ripple-effect>
      </div>
    </div>
    <div *ngIf="headerData.subtitle" class="subtitle">
      <div class="green">{{ headerData.subtitle.cars }}</div>
      машин
      <div class="green">{{ headerData.subtitle.amount }}</div>
      кг
    </div>
    <div class="searchBar-container">
      <ion-searchbar
        class="light"
        *ngIf="showSearchBar"
        placeholder="Поиск"
        [(ngModel)]="searchbarValue"
        (ionFocus)="ionFocus()"
        (ionClear)="searchbarClear()"
      ></ion-searchbar>

      <div *ngIf="showActionButtons" class="actions">
        <ion-button
          (click)="showLoadingArchive()"
          [class.primary]="!loadingArchiveButtonIsPrimary"
          [class.secondary]="loadingArchiveButtonIsPrimary"
          class="square small left"
          mode="md"
          >Завершенные</ion-button
        >
        <ion-button
          (click)="showUnloadingArchive()"
          [class.primary]="!unloadingArchiveButtonIsPrimary"
          [class.secondary]="unloadingArchiveButtonIsPrimary"
          class="square small right"
          mode="md"
          >В пути</ion-button
        >
      </div>
    </div>
  </ion-toolbar>
</ion-header>
