import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as fs from 'file-saver';
import * as ExcelJS from 'exceljs';
import { Capacitor } from '@capacitor/core';
import { Directory, Filesystem } from '@capacitor/filesystem';
import * as moment from 'moment';
import { ExcelGeneratorData } from '../../dto/excel-generation-data.dto';

declare let cordova: any;

@Injectable({
  providedIn: 'root'
})
export class XlsxTemplateFillerServiceService {
  private xlsxFileMIMEType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

  constructor(private http: HttpClient) { }

  async generateAndOpenNewExcelFile(
    filePath: string,
    dataForTemplate: ExcelGeneratorData,
    fileName: string,
  ): Promise<void> {
    try {
      const blob: Blob | undefined = await this.fetchExcelBlob(filePath);
      if (blob) await this.handleExcelFile(blob, dataForTemplate, fileName);
    } catch (error) {
      // console.error('Error handling Excel file:', error);
    }
  }

  private async fetchExcelBlob(filePath: string): Promise<Blob | undefined> {
    return this.http.get(filePath, { responseType: 'blob' }).toPromise();
  }

  private async handleExcelFile(
    fileBlob: Blob,
    dataForTemplate: ExcelGeneratorData,
    fileName: string,

  ): Promise<void> {
    const workbook = new ExcelJS.Workbook();
    const arrayBuffer = await this.blobToArrayBuffer(fileBlob);
    const excelBuffer = new Uint8Array(arrayBuffer);
    await workbook.xlsx.load(excelBuffer);

    const filledWorkbook = this.fillTemplate(workbook, dataForTemplate);

    const generatedExcelBuffer = await filledWorkbook.xlsx.writeBuffer();
    this.saveGeneratedFile(generatedExcelBuffer, fileName);
  }

  private fillTemplate(
    workbook: ExcelJS.Workbook,
    dataForTemplate: ExcelGeneratorData
  ) {
    dataForTemplate.cellDataArray.forEach((cellData) => {
      const editWorksheet = workbook.getWorksheet(cellData.pageNumber);
      if (editWorksheet) {
        const editedCell = editWorksheet.getCell(cellData.address);

        editedCell.value = cellData.value;
      }
    });

    if (dataForTemplate.additionalOptions?.removeCellPoints) {
      dataForTemplate.additionalOptions.removeCellPoints.forEach((rmPoints) => {
        const editWorksheet = workbook.getWorksheet(rmPoints.pageNumber);
        if (editWorksheet) {
          for (let i = rmPoints.startRow; i <= rmPoints.endRow; i += 1) {
            editWorksheet.spliceRows(rmPoints.startRow, 1);
          }
        }
      });
    }

    return workbook;
  }

  private async blobToArrayBuffer(blob: Blob): Promise<ArrayBuffer> {
    return new Promise<ArrayBuffer>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event: ProgressEvent<FileReader>) => {
        if (event.target?.result instanceof ArrayBuffer) {
          resolve(event.target.result);
        } else {
          reject(new Error('Error converting blob to array buffer.'));
        }
      };
      reader.onerror = () => {
        reject(new Error('Error converting blob to array buffer.'));
      };
      reader.readAsArrayBuffer(blob);
    });
  }

  private async saveGeneratedFile(
    generatedExcelBuffer: ExcelJS.Buffer,
    fileName: string,
  ) {
    // Создаем Blob и сохраняем файл
    const generatedBlob = new Blob([generatedExcelBuffer], {
      type: this.xlsxFileMIMEType
    });

    const base64String = (generatedExcelBuffer as Buffer).toString('base64');

    if (Capacitor.getPlatform() === 'web') {
      fs.saveAs(generatedBlob, `${fileName} ${moment().format('DD.MM.YY HH-mm-ss')}.xlsx`);
    } else {
      const configSnap = this.getConfiguration(fileName);
      await Filesystem.writeFile({
        ...configSnap,
        data: base64String
      });

      await this.openFile(configSnap.path, this.xlsxFileMIMEType);
    }
  }

  private getConfiguration(fileName: string) {
    let config;
    if (Capacitor.getPlatform() === 'ios') {
      config = {
        directory: Directory.Documents,
        path: `${fileName} ${moment().format('DD.MM.YY HH-mm-ss')}.xlsx`,
        recursive: true
      };
    } else {
      config = {
        directory: Directory.Library,
        path:
          `${fileName} ${moment().format('DD.MM.YY HH-mm-ss')}.xlsx`,
        recursive: true
      };
    }
    return config;
  }

  private async openFile(
    filePath: string,
    fileMIMEType: string
  ): Promise<void> {
    try {
      if (Capacitor.getPlatform() === 'ios') {
        const fullFilePath = await Filesystem.getUri({
          directory: Directory.Documents,
          path: filePath
        });
        cordova.plugins.fileOpener2.open(fullFilePath.uri, fileMIMEType, {
          error: () => {
          },
          success: () => {
          }
        });
      } else {
        const fullFilePath = await Filesystem.getUri({
          directory: Directory.Library,
          path: filePath
        });
        cordova.plugins.fileOpener2.open(fullFilePath.uri, fileMIMEType, {
          error: () => {
          },
          success: () => {
          }
        });
      }
    } catch (error) {
    }
  }
}
