import { Injectable } from '@angular/core';
import { ValidationErrors, ValidatorFn } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class TaxNumberValidatorService {
  public createValidator(): ValidatorFn {
    return (control): ValidationErrors | null => {
      const { value } = control;
      if (value) {
        if (![10, 12].includes(value.length) || !this.checkIfValidTaxNumber(value)) {
          return { invalidTaxNumber: true };
        }
      }
      return null;
    };
  }

  public checkIfValidTaxNumber(str: string): boolean {
    let valid: boolean = false;
    const controlDigitsBase: number[] = [2, 4, 10, 3, 5, 9, 4, 6, 8];
    const strDigits: number[] = Array(str.length).fill('').map(
      (_, index) => Number(str.charAt(index))
    );
    if (str.length === 10) {
      const checkSum: number = this.calcCheckSum(strDigits, controlDigitsBase);
      valid = checkSum === strDigits[str.length - 1];
    } else if (str.length > 10) {
      const controlDigits1: number[] = [7, ...controlDigitsBase];
      const checkSum1: number = this.calcCheckSum(strDigits, controlDigits1);
      valid = checkSum1 === strDigits[10];
      if (valid && str.length > 11) {
        const controlDigits2: number[] = [3, ...controlDigits1];
        const checkSum2: number = this.calcCheckSum(strDigits, controlDigits2);
        valid = checkSum2 === strDigits[11];
      }
    }
    return valid;
  }

  private calcCheckSum(userDigits: number[], controlDigits: number[]): number {
    return controlDigits.reduce((accumulator, currentValue, currentIndex) => {
      const k: number = currentValue * userDigits[currentIndex];
      return accumulator + k;
    }, 0) % 11 % 10;
  }
}
