<ion-content [scrollY]="false">
  <form [formGroup]="form" class="from-wrapper" (ngSubmit)="saveChanges()">
    <div class="input-group">
      <app-base-input
        style="width: 100%"
        [title]="'Номер машины'"
        [type]="'carNumber'"
        [control]="form.controls.registrationNumber"
        formControlName="registrationNumber"
      ></app-base-input>
      <app-base-input
        id="trailerRegNumbField"
        style="width: 100%"
        [title]="'Номер прицепа'"
        [type]="'trailerNumber'"
        [control]="form.controls.trailerRegistrationNumber"
        formControlName="trailerRegistrationNumber"
      ></app-base-input>
      <app-base-input
        style="width: 100%"
        [title]="'ФИО водителя'"
        [control]="form.controls.driverName"
        formControlName="driverName"
      ></app-base-input>
      <app-base-input
        style="width: 100%"
        [type]="'phone'"
        [title]="'Телефон водителя'"
        [control]="form.controls.driverPhone"
        formControlName="driverPhone"
      ></app-base-input>
      <app-base-input
        style="width: 100%"
        [type]="'inn'"
        [title]="'ИНН владельца'"
        [control]="form.controls.carrierInn"
        formControlName="carrierInn"
      ></app-base-input>
      <app-base-input
        style="width: 100%"
        [title]="'Владелец'"
        [control]="form.controls.carrierName"
        formControlName="carrierName"
      ></app-base-input>
      <!-- <app-base-input
        [type]="'numeric'"
        style="width: 100%"
        [title]="'Грузоподъемность'"
        [control]="form.get('weightCapacity')"
        formControlName="weightCapacity"
      ></app-base-input> -->
    </div>
    <div class="save-button-wrapper">
      <ion-button
        type="submit"
        [disabled]="!form.valid"
        class="primary large"
        mode="md"
      >
        Сохранить
      </ion-button>
    </div>
  </form>
</ion-content>
