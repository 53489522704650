import {
  HostListener,
  Directive,
  ElementRef,
  Renderer2,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnChanges
} from '@angular/core';

type MouseAndTouchEvent = MouseEvent & TouchEvent;

@Directive({ selector: '[appSwiper]' })
export class SwiperButtonDirective implements OnInit, OnChanges {
  @Input() swipedText: string;
  @Input() isSwiped: boolean;
  @Input() swipeId: string;
  @Output() swipedEvent: EventEmitter<boolean> = new EventEmitter();
  initialMouse: number = 0;
  slideMovementTotal: number = 0;
  mouseIsDown: boolean = false;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    if (this.isSwiped) {
      const slider = document.getElementById(`${this.swipeId}slider`);
      slider?.classList.add('unlocked');
      slider!.innerHTML = this.swipedText;
    }
  }

  ngOnChanges(): void {
    if (this.isSwiped) {
      const slider = document.getElementById(`${this.swipeId}slider`);
      slider?.classList.add('unlocked');
      slider!.innerHTML = this.swipedText;
    }
  }

  @HostListener('mousedown', ['$event'])
  @HostListener('touchstart', ['$event'])
  onMouseDown(event: MouseAndTouchEvent) {
    this.mouseIsDown = true;
    this.slideMovementTotal =
      document.getElementById(this.swipeId)?.offsetWidth ||
      0 - this.el.nativeElement.width() + 10;
    this.initialMouse = event.clientX || event.touches[0].pageX;
  }

  @HostListener('mouseup', ['$event'])
  @HostListener('touchend', ['$event'])
  @HostListener('document:mouseup', ['$event'])
  @HostListener('document:touchend', ['$event'])
  onMouseUp(event: MouseAndTouchEvent) {
    const slider = document.getElementById(`${this.swipeId}slider`);
    if (!this.mouseIsDown) return;
    this.mouseIsDown = false;
    const currentMouse = event.clientX || event.changedTouches[0].pageX;
    const relativeMouse = currentMouse - this.initialMouse;
    if (relativeMouse < this.slideMovementTotal - 50) {
      this.renderer.setStyle(slider, 'left', '-10px');
      return;
    }

    slider?.classList.add('unlocked');
    slider!.innerHTML = this.swipedText;
    this.swipedEvent.emit(true);
  }

  @HostListener('document:mousemove', ['$event'])
  @HostListener('document:touchmove', ['$event'])
  onMouseMove(event: MouseAndTouchEvent) {
    if (!this.mouseIsDown) return;

    const slider = document.getElementById(`${this.swipeId}slider`);
    const currentMouse = event.clientX || event.touches[0].pageX;
    const relativeMouse = currentMouse - this.initialMouse;

    if (relativeMouse <= 0) {
      this.renderer.setStyle(slider, 'left', '-10px');
      return;
    }

    if (relativeMouse >= this.slideMovementTotal - 40) {
      this.renderer.setStyle(
        slider,
        'left',
        `${this.slideMovementTotal - 50}px`
      );
      return;
    }
    this.renderer.setStyle(slider, 'left', `${relativeMouse - 10}px`);
  }
}
