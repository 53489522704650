import { Component } from '@angular/core';
import { HeaderDto } from '../../../dto/header.dto';
import { OrdersListDto } from '../../../dto/orders-list.dto';
import { SearchItem } from '../../../dto/search-item.dto';
import { Status } from '../../../dto/status.enum';

@Component({
  selector: 'app-orders-in-work',
  templateUrl: './orders-in-work.component.html',
  styleUrls: ['./orders-in-work.component.scss']
})
export class OrdersInWorkComponent {
  headerDataValues: HeaderDto = {
    statuses: [Status.IN_WORK, Status.ACCEPTED],
    totalCount: 0,
    searchTerms: [],
    title: 'В работе',
    tabRoute: 'requests-in-work'
  };

  ordersListDataValues: OrdersListDto = {
    statuses: [Status.IN_WORK, Status.ACCEPTED],
    searchTerms: []
  };

  getSearchTerms(searchTerms: SearchItem[]) {
    this.headerDataValues = {
      ...this.headerDataValues,
      searchTerms: [...searchTerms]
    };

    this.ordersListDataValues = {
      ...this.ordersListDataValues,
      searchTerms: [...searchTerms]
    };
  }

  getTotalCount(totalCount: number) {
    this.headerDataValues = {
      ...this.headerDataValues,
      totalCount
    };
  }
}
