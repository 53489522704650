import { Injectable } from '@angular/core';
import { ExcelGeneratorData } from 'src/app/dto/excel-generation-data.dto';
import { Order } from 'src/app/dto/order.dto';
import { RtsVehicle } from 'src/app/dto/get-rts-result.dto';
import { XlsxTemplateFillerServiceService } from './xlsx-template-filler-service.service';
import { DateTimeService } from './date-time.service';

@Injectable({
  providedIn: 'root'
})
export class RtsGeneratorService {
  private readonly ttnTemplatePath: string = 'assets/docs/rts-template.xlsx';

  constructor(
    private xlsxTemplateFillerServiceService: XlsxTemplateFillerServiceService,
    private dateTimeService: DateTimeService
  ) { }

  async getTtn(vehicleArr: RtsVehicle[], order: Order): Promise<void> {
    const data = await this.generateRTSDataStructure(vehicleArr, order);
    await this.xlsxTemplateFillerServiceService.generateAndOpenNewExcelFile(
      this.ttnTemplatePath,
      data,
      `РТС-${order.id}`
    );
  }

  private async generateRTSDataStructure(
    vehicleArr: RtsVehicle[],
    order: Order
  ) {
    const generateRtsData: ExcelGeneratorData = {
      cellDataArray: []
    };

    generateRtsData.cellDataArray.push({
      address: 'D2',
      value: `${order.id}`,
      name: 'order-id',
      pageNumber: 1
    });

    let startIndex = 5;
    let catListNumber = 1;

    vehicleArr.forEach((car) => {
      generateRtsData.cellDataArray.push({
        address: `A${startIndex}`,
        value: `${catListNumber}`,
        name: 'car-list-number',
        pageNumber: 1
      });

      generateRtsData.cellDataArray.push({
        address: `B${startIndex}`,
        value: '1',
        name: 'default-value',
        pageNumber: 1
      });

      if (car.model) {
        generateRtsData.cellDataArray.push({
          address: `C${startIndex}`,
          value: `${car.model}`,
          name: 'car-name',
          pageNumber: 1
        });
      }

      if (car.registrationNumber) {
        generateRtsData.cellDataArray.push({
          address: `D${startIndex}`,
          value: `${car.registrationNumber}`,
          name: 'car-number',
          pageNumber: 1
        });
      }
      if (car.trailerRegistrationNumber) {
        generateRtsData.cellDataArray.push({
          address: `E${startIndex}`,
          value: `${car.trailerRegistrationNumber}`,
          name: 'trailer-number',
          pageNumber: 1
        });
      }

      if (car.driverName) {
        generateRtsData.cellDataArray.push({
          address: `F${startIndex}`,
          value: `${car.driverName}`,
          name: 'driver-name',
          pageNumber: 1
        });
      }

      // TODO
      generateRtsData.cellDataArray.push({
        address: `G${startIndex}`,
        value: '-',
        name: 'driver-inn',
        pageNumber: 1
      });

      if (car.driverLicenseNumber) {
        generateRtsData.cellDataArray.push({
          address: `H${startIndex}`,
          value: `${car.driverLicenseNumber} `,
          name: 'driver-license',
          pageNumber: 1
        });
      }

      if (car.driverPassport) {
        generateRtsData.cellDataArray.push({
          address: `I${startIndex}`,
          value: `${car.driverPassport}`,
          name: 'driver-documents',
          pageNumber: 1
        });
      }

      if (order.buyer.orgName) {
        generateRtsData.cellDataArray.push({
          address: `J${startIndex}`,
          value: `${order.buyer.orgName}`,
          name: 'buyer-name',
          pageNumber: 1
        });
      }

      if (order.buyer.address) {
        generateRtsData.cellDataArray.push({
          address: `K${startIndex}`,
          value: `${order.buyer.address}`,
          name: 'buyer-address',
          pageNumber: 1
        });
      }

      if (order.buyer.inn) {
        generateRtsData.cellDataArray.push({
          address: `L${startIndex}`,
          value: `${order.buyer.inn}`,
          name: 'buyer-inn',
          pageNumber: 1
        });
      }

      if (order.buyer.kpp) {
        generateRtsData.cellDataArray.push({
          address: `M${startIndex}`,
          value: `${order.buyer.kpp}`,
          name: 'buyer-kpp',
          pageNumber: 1
        });
      }

      if (order.forwarder.orgName) {
        generateRtsData.cellDataArray.push({
          address: `N${startIndex}`,
          value: `${order.forwarder.orgName}`,
          name: 'forwarder-org-name',
          pageNumber: 1
        });
      }

      if (car.carrierBankDetails) {
        generateRtsData.cellDataArray.push({
          address: `O${startIndex}`,
          value: `${car.carrierBankDetails}`,
          name: 'forwarder-bank',
          pageNumber: 1
        });
      }

      if (order.forwarder.address) {
        generateRtsData.cellDataArray.push({
          address: `P${startIndex}`,
          value: `${order.forwarder.address}`,
          name: 'forwarder-address',
          pageNumber: 1
        });
      }

      if (order.forwarder.inn) {
        generateRtsData.cellDataArray.push({
          address: `Q${startIndex}`,
          value: `${order.forwarder.inn}`,
          name: 'forwarder-inn',
          pageNumber: 1
        });
      }

      if (!order.forwarder.ogrnip) {
        generateRtsData.cellDataArray.push({
          address: `R${startIndex}`,
          value: '-',
          name: 'forwarder-orgrnip',
          pageNumber: 1
        });
      } else {
        generateRtsData.cellDataArray.push({
          address: `R${startIndex}`,
          value: `${order.forwarder.ogrnip}`,
          name: 'forwarder-orgrnip',
          pageNumber: 1
        });
      }

      if (order.forwarder.email) {
        generateRtsData.cellDataArray.push({
          address: `S${startIndex}`,
          value: `${order.forwarder.email}`,
          name: 'forwarder-email',
          pageNumber: 1
        });
      }

      if (car.driverPhone) {
        generateRtsData.cellDataArray.push({
          address: `T${startIndex}`,
          value: `${car.driverPhone}`,
          name: 'driver-phone',
          pageNumber: 1
        });
      }

      if (order.forwarder.kpp) {
        generateRtsData.cellDataArray.push({
          address: `U${startIndex}`,
          value: `${order.forwarder.kpp}`,
          name: 'forwarder-kpp',
          pageNumber: 1
        });
      }

      if (order.forwarder.passport) {
        generateRtsData.cellDataArray.push({
          address: `V${startIndex}`,
          value: `${order.forwarder.passport.number}`,
          name: 'forwarder-passport',
          pageNumber: 1
        });

        generateRtsData.cellDataArray.push({
          address: `W${startIndex}`,
          value: `${order.forwarder.passport.issuedBy}`,
          name: 'forwarder-passport-by',
          pageNumber: 1
        });

        generateRtsData.cellDataArray.push({
          address: `X${startIndex}`,
          value: `${order.forwarder.passport.date}`,
          name: 'forwarder-passport-date',
          pageNumber: 1
        });
      } else {
        generateRtsData.cellDataArray.push({
          address: `V${startIndex}`,
          value: '-',
          name: 'forwarder-passport-empty',
          pageNumber: 1
        });

        generateRtsData.cellDataArray.push({
          address: `W${startIndex}`,
          value: '-',
          name: 'forwarder-passport-by-empty',
          pageNumber: 1
        });

        generateRtsData.cellDataArray.push({
          address: `X${startIndex}`,
          value: '-',
          name: 'forwarder-passport-date-empty',
          pageNumber: 1
        });
      }

      catListNumber += 1;
      startIndex += 1;
    });

    if (this.dateTimeService.getNowDateStringFormat()) {
      generateRtsData.cellDataArray.push({
        address: 'E5',
        value: `${this.dateTimeService.getNowDateStringFormat()}`,
        name: 'create-date',
        pageNumber: 2
      });
    }

    if (order.freightName) {
      generateRtsData.cellDataArray.push({
        address: 'E6',
        value: `${order.freightName}`,
        name: 'freight-name',
        pageNumber: 2
      });
    }

    if (order.buyer.orgName) {
      generateRtsData.cellDataArray.push({
        address: 'E7',
        value: `${order.buyer.orgName}, ${order.buyer.address}`,
        name: 'buyer-data',
        pageNumber: 2
      });
    }

    if (order.buyer.orgName) {
      generateRtsData.cellDataArray.push({
        address: 'E8',
        value: `${order.buyer.orgName}`,
        name: 'buyer-data',
        pageNumber: 2
      });
    }

    if (order.shipmentDates) {
      generateRtsData.cellDataArray.push({
        address: 'E9',
        value: `${this.dateTimeService.formatDate(order.shipmentDates[0])}`,
        name: 'start-day',
        pageNumber: 2
      });
    }

    if (order.loading.address) {
      generateRtsData.cellDataArray.push({
        address: 'E10',
        value: `${order.loading.address}`,
        name: 'order-loading-address',
        pageNumber: 2
      });
    }

    if (order.unloading.address) {
      generateRtsData.cellDataArray.push({
        address: 'E11',
        value: `${order.unloading.address}`,
        name: 'order-unloading-address',
        pageNumber: 2
      });
    }

    if (order.distance) {
      generateRtsData.cellDataArray.push({
        address: 'E12',
        value: `${order.distance}`,
        name: 'distance',
        pageNumber: 2
      });
    }

    order.generateRTSActions(generateRtsData.cellDataArray);

    return generateRtsData;
  }
}
