/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { ExcelGeneratorData } from 'src/app/dto/excel-generation-data.dto';
import { Order } from 'src/app/dto/order.dto';
import { RtsVehicle } from 'src/app/dto/get-rts-result.dto';
import { XlsxTemplateFillerServiceService } from './xlsx-template-filler-service.service';
import { DateTimeService } from './date-time.service';

@Injectable({
  providedIn: 'root'
})
export class TrnGeneratorService {
  private readonly trnTemplatePath: string = 'assets/docs/trn-template.xlsx';

  constructor(
    private xlsxTemplateFillerServiceService: XlsxTemplateFillerServiceService,
    private dateTimeService: DateTimeService
  ) {}

  async getTtn(order: Order, carInfo: RtsVehicle): Promise<void> {
    const data = await this.generateTrnDataStructure(order, carInfo);
    await this.xlsxTemplateFillerServiceService.generateAndOpenNewExcelFile(
      this.trnTemplatePath,
      data,
      `ТРН-${order.id}`
    );
  }

  private async generateTrnDataStructure(order: Order, carInfo: RtsVehicle) {
    const generateTrnData: ExcelGeneratorData = {
      cellDataArray: []
    };

    generateTrnData.cellDataArray.push({
      address: 'G9',
      value: this.dateTimeService.getNowDateStringFormat(),
      name: 'date',
      pageNumber: 1
    });

    generateTrnData.cellDataArray.push({
      address: 'AC9',
      value: `${order.id}-${Date.now().toString().slice(-5)}`,
      name: 'trn-number',
      pageNumber: 1
    });

    generateTrnData.cellDataArray.push({
      address: 'BU9',
      value: this.dateTimeService.formatDate(String(order.createdDate)),
      name: 'date-create',
      pageNumber: 1
    });

    generateTrnData.cellDataArray.push({
      address: 'CQ9:DG9',
      value: `${order.id}`,
      name: 'order-number',
      pageNumber: 1
    });

    if (order.forwarder) {
      let val = '';
      if (order.forwarder.orgName) val += order.forwarder.orgName;
      if (order.forwarder.inn) val += order.forwarder.inn;
      if (order.forwarder.address) val += order.forwarder.address;
      if (order.forwarder.phone) val += `тел. ${order.forwarder.phone}`;
      if (order.forwarder.kpp) val += `КПП. ${order.forwarder.kpp}`;

      generateTrnData.cellDataArray.push({
        address: 'B15',
        value: val,
        name: 'expeditor-data',
        pageNumber: 1
      });

      generateTrnData.cellDataArray.push({
        address: 'BF21',
        value: val,
        name: 'expeditor-data',
        pageNumber: 2
      });
    }

    if (order.buyer) {
      let val = '';
      if (order.buyer?.orgName) val += `${order.buyer.orgName} `;
      if (order.buyer?.inn) val += ` ${order.buyer.inn} `;
      if (order.buyer?.address) val += ` ${order.buyer.address} `;
      if (order.buyer?.phone) val += ` тел. ${order.buyer.phone} `;
      if (order.buyer?.kpp) val += ` КПП. ${order.buyer.kpp} `;

      generateTrnData.cellDataArray.push({
        address: 'BP15',
        value: val,
        name: 'buyer-data',
        pageNumber: 1
      });
      generateTrnData.cellDataArray.push({
        address: 'B20',
        value: val,
        name: 'buyer-data',
        pageNumber: 1
      });
    }

    generateTrnData.cellDataArray.push({
      address: 'BP17',
      value: `Агентский договор Поручение экспедитору №${
        order.id
      } от ${this.dateTimeService.formatDate(String(order.createdDate))}`,
      name: 'order-data',
      pageNumber: 1
    });

    if (order.buyer.address) {
      generateTrnData.cellDataArray.push({
        address: 'B22',
        value: ` ${order.buyer.address}`,
        name: 'buyer-address',
        pageNumber: 1
      });
    }

    if (order.freightName) {
      generateTrnData.cellDataArray.push({
        address: 'B25',
        value: `${order.freightName}`,
        name: 'freight-data',
        pageNumber: 1
      });
    }

    generateTrnData.cellDataArray.push({
      address: 'B27',
      value: `Масса (брутто) ${carInfo.loadingData.grossWeight}, тара  ${carInfo.loadingData.tareWeight}, масса (нетто)  ${carInfo.loadingData.netWeight}`,
      name: 'weight-data',
      pageNumber: 1
    });

    generateTrnData.cellDataArray.push({
      address: 'B34',
      value: `Протокол испытания номер № ${carInfo.loadingData.testingProtocolNumber}
      от ${this.dateTimeService.getDateFromTimestamp(carInfo.loadingData.testingProtocolDate)}, Декларация соответствия ${carInfo.loadingData.declarationOfConformityNumber}
      от ${this.dateTimeService.getDateFromTimestamp(carInfo.loadingData.declarationOfConformityDate)}, Карантинный сертификат ${carInfo.loadingData.quarantineCertificateNumber} от ${this.dateTimeService.getDateFromTimestamp(carInfo.loadingData.quarantineCertificateDate)}, СДИЗ ${carInfo.loadingData.sdizNumber}`,
      name: 'additional-docs',
      pageNumber: 1
    });

    generateTrnData.cellDataArray.push({
      address: 'B36',
      value: `ТТН (по форме СП-31) ${order.id}-${Date.now()
        .toString()
        .slice(
          -5
        )} от ${this.dateTimeService.getNowDateStringFormat()}, ИНН составителя - ${
        order.forwarder.inn
      }`,
      name: 'trn-info',
      pageNumber: 1
    });

    if (order.forwarder) {
      let val = '';
      if (order.forwarder.orgName) val += order.forwarder.orgName;
      if (order.forwarder.phone) val += order.forwarder.phone;
      generateTrnData.cellDataArray.push({
        address: 'BF39',
        value: `${val}`,
        name: 'manager-data',
        pageNumber: 1
      });
    }

    if (carInfo) {
      let val = '';
      if (carInfo.carrierName) val += carInfo.carrierName;
      if (carInfo.carrierInn) val += carInfo.carrierInn;
      if (carInfo.carrierAddress) val += carInfo.carrierAddress;
      if (carInfo.carrierAddress) val += ` , email :${carInfo.carrierEmail} `;

      generateTrnData.cellDataArray.push({
        address: 'B44',
        value: val,
        name: 'carrier-data',
        pageNumber: 1
      });
    }

    if (carInfo) {
      let val = '';
      if (carInfo.driverName) val += carInfo.driverName;
      if (carInfo.driverLicenseNumber) {
        val += `, ВУ ${carInfo.driverLicenseNumber}`;
      }
      if (carInfo.carrierAddress) val += `, тел.:${carInfo.driverPhone}`;

      generateTrnData.cellDataArray.push({
        address: 'BF44',
        value: val,
        name: 'driver-data',
        pageNumber: 1
      });

      let carNumVal = '';
      if (carInfo.registrationNumber) {
        carNumVal += `${carInfo.registrationNumber} rus`;
      }
      if (carInfo.trailerRegistrationNumber) {
        carNumVal += `, прицеп ${carInfo.trailerRegistrationNumber} rus`;
      }

      generateTrnData.cellDataArray.push({
        address: 'BF47',
        value: carNumVal,
        name: 'car-number',
        pageNumber: 1
      });
    }

    // ${carInfo.driver.licenseDate}

    // generateTrnData.cellDataArray.push({
    //   address: 'B47',
    //   value: `${carInfo.model}, грузоподъемностью ${
    //     carInfo?.weightCapacity !== undefined ? carInfo.weightCapacity : ''
    //   }`,
    //   name: 'car-info',
    //   pageNumber: 1
    // });

    // if (order.buyer.orgName) {
    //   generateTrnData.cellDataArray.push({
    //     address: 'BF47',
    //     value: `${order.buyer.orgName}`,
    //     name: 'acceptance-org',
    //     pageNumber: 1
    //   });
    // }

    // generateTrnData.cellDataArray.push({
    //   address: 'BO51',
    //   value: `${carInfo.owner.ownType}`,
    //   name: 'own-type',
    //   pageNumber: 1
    // });

    generateTrnData.cellDataArray.push({
      address: 'B58',
      value: `Организация ${order.loading?.owner?.name || ''} ИНН ${
        order.loading?.owner?.inn || ''
      } ${order.loading?.address || ''}`,
      name: 'loader-info',
      pageNumber: 1
    });

    if (order.loading.address) {
      generateTrnData.cellDataArray.push({
        address: 'B60',
        value: `${order.loading.address}`,
        name: 'loading-address',
        pageNumber: 1
      });
    }

    generateTrnData.cellDataArray.push({
      address: 'BF60',
      value: '',
      name: 'loading-start-time',
      pageNumber: 1
    });

    generateTrnData.cellDataArray.push({
      address: 'B64',
      value: `Масса (брутто) ${carInfo.loadingData.grossWeight}, тара  ${carInfo.loadingData.tareWeight}, масса (нетто)  ${carInfo.loadingData.netWeight} (определение разницы между массой транспортного средства после погрузки и перед погрузкой по общей массе)`,
      name: 'loading-start-time',
      pageNumber: 1
    });

    if (order.forwarder.orgName) {
      generateTrnData.cellDataArray.push({
        address: 'J70',
        value: `${order.forwarder.orgName}`,
        name: 'forwarder-sign',
        pageNumber: 1
      });
    }

    if (carInfo.driverName) {
      generateTrnData.cellDataArray.push({
        address: 'BR70',
        value: `${carInfo.driverName}`,
        name: 'driver-sign',
        pageNumber: 1
      });
    }

    if (order.unloading.address) {
      generateTrnData.cellDataArray.push({
        address: 'B3',
        value: `${order.unloading.address}`,
        name: 'unload-address',
        pageNumber: 2
      });
    }

    generateTrnData.cellDataArray.push({
      address: 'BF3',
      value: '',
      name: 'unload-end-date',
      pageNumber: 2
    });

    if (order.forwarder.orgName) {
      generateTrnData.cellDataArray.push({
        address: 'BR11',
        value: `${order.forwarder.orgName}`,
        name: 'expeditor-name',
        pageNumber: 2
      });
    }

    generateTrnData.cellDataArray.push({
      address: 'B23',
      value: `Договор перевозки Заявка №${
        order.id
      } от ${this.dateTimeService.formatDate(String(order.createdDate))}`,
      name: 'order-data',
      pageNumber: 2
    });

    generateTrnData.cellDataArray.push({
      address: 'BF23',
      value: `Договор перевозки Заявка №${
        order.id
      } от ${this.dateTimeService.formatDate(String(order.createdDate))}`,
      name: 'order-data',
      pageNumber: 2
    });

    order.generateTrnActions(generateTrnData.cellDataArray);

    return generateTrnData;
  }
}
