import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { IonItemSliding, ModalController, NavController } from '@ionic/angular';
import { AcceptNewOrderModalService } from 'src/app/services/accept-new-order.service';
import { OrderChangesService } from 'src/app/services/order-changes.service';
import { SmartSeedsService } from 'src/app/services/smart-seeds.service';
import { ErrorToastService } from 'src/app/services/error-toast.service';
import { Order } from '../../../dto/order.dto';
import { Status } from '../../../dto/status.enum';
import { OrdersService } from '../../../services/orders.service';
import { ActionsModalComponent } from '../../../shared/actions-modal/actions-modal.component';
import {
  ARCHIVED_REQUESTS,
  OPEN_REQUESTS,
  REQUESTS_IN_WORK
} from '../../../utils/constants/router.constants';
import { DaysListComponent } from '../../days-list/days-list.component';
import { OrderCommonComponent } from '../order-common/order-common.component';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent
  extends OrderCommonComponent
  implements OnInit, OnChanges {
  @ViewChild('slidingItem') slidingItem: IonItemSliding;
  @Input() order: Order;
  @Output() reloadRequests = new EventEmitter<boolean>();
  @Output() awaitLoading = new EventEmitter<boolean>();

  component: DaysListComponent;
  isAcceptable: boolean;
  isRemovable: boolean;
  inWork: boolean;
  isCompleted: boolean;
  targetUrl = window.location.pathname.split('/')[1];

  orderImagesPath: string = '';
  orderImagesBasePath: string = 'assets/images/product-types/';

  constructor(
    modalCtrl: ModalController,
    private ordersService: OrdersService,
    private orderChangesService: OrderChangesService,
    private navCtrl: NavController,
    private acceptNewOrderModalService: AcceptNewOrderModalService,
    private smartSeedsService: SmartSeedsService,
    private errorToastService: ErrorToastService
  ) {
    super(modalCtrl);
  }

  ngOnInit(): void {
    this.updateStatus(this.order.status);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['order']) {
      const { freightName, status } = this.order;
      this.orderImagesPath += this.orderImagesBasePath;
      if (freightName) {
        // TORG-283
        const freightNameWithoutClass = freightName.split(' ')[0];

        switch (freightNameWithoutClass) {
          case 'Пшеница':
            this.orderImagesPath += 'wheat.svg';
            break;
          case 'Лён':
            this.orderImagesPath += 'flax.svg';
            break;
          case 'Горох':
            this.orderImagesPath += 'peas.svg';
            break;
          case 'Кукуруза':
            this.orderImagesPath += 'corn.svg';
            break;
          case 'Рожь':
            this.orderImagesPath += 'rye.svg';
            break;
          case 'Ячмень':
            this.orderImagesPath += 'barley.svg';
            break;
          case 'Подсолнечник':
            this.orderImagesPath += 'sunflower.svg';
            break;
          default:
            this.orderImagesPath = '';
            break;
        }
      }
      if (status) {
        this.updateStatus(status);
      }
    }
  }

  getBackgroundColor(): string {
    if (this.isAcceptable) {
      return '#00bfa8';
    }
    if (this.isRemovable) {
      return '#f8627d';
    }
    return '#fff';
  }

  openDaysList(itemId: number) {
    let path;
    switch (this.order.status) {
      case Status.OPEN:
        path = OPEN_REQUESTS;
        break;
      case Status.COMPLETED:
        path = ARCHIVED_REQUESTS;
        break;
      default:
        path = REQUESTS_IN_WORK;
        break;
    }
    this.orderChangesService.setOrder(this.order, this.targetUrl);

    this.orderChangesService.getOrderSubject(this.targetUrl).subscribe(r => {
      if (r?.id === this.order.id) {
        this.order = r;
        if (r.status) {
          this.updateStatus(r.status);
        }
      }
    });

    this.navCtrl.navigateForward([`${path}/days/${itemId}`]);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  restoreRequest(event: any, id: number) {
    event.stopPropagation();
  }

  async acceptRequest(orderId: number) {
    const role = await this.acceptNewOrderModalService.accept();
    const userDbData = await this.smartSeedsService.fetchUserCredentialsForSmartSeedsAsync();

    if (role === 'confirm') {
      this.awaitLoading.emit(true);
      if (userDbData.password && userDbData.login) {
        this.ordersService
          .acceptRequest(
            orderId,
            userDbData.login,
            userDbData.password,
          )
          .subscribe({
            next: r => {
              this.awaitLoading.emit(false);
              if (!r?.success) {
                this.noContentRequest();
              }
              this.reloadRequests.emit(true);
            },
            error: error => {
              this.awaitLoading.emit(false);
              if (error.status === 412) {
                this.noContentRequest();
              } else {
                // Обработка других ошибок
              }
            }
          });
      } else {
        this.errorToastService.sendError(418);
        this.navCtrl.navigateForward([
          'smartseeds-integration'
        ]);
      }
    }
  }

  async removeRequest(orderId: number) {
    const modal = await this.modalCtrl.create({
      component: ActionsModalComponent,
      breakpoints: [0, 1],
      initialBreakpoint: 1,
      componentProps: {
        data: {
          rejectButton: true,
          rejectButtonText: 'Отказаться',
          message: `Отказываясь от заявки, вы больше не сможете продолжить работу по ней.
            Данное действие нельзя отменить. Хотите отказаться от заявки?`,
          title: 'Отказаться от заявки?',
          imageSrc: '../../../assets/images/trash.svg'
        }
      }
    });
    modal.present();

    const { role } = await modal.onWillDismiss();

    if (role === 'reject') {
      const userDbData = await this.smartSeedsService.fetchUserCredentialsForSmartSeedsAsync();

      this.ordersService
        .declineRequest(
          orderId,
          userDbData.login,
          userDbData.password,
        )
        .subscribe(() => this.reloadRequests.emit(true));
    }
  }

  updateStatus(status: Status): void {
    this.isAcceptable = status === Status.OPEN;
    this.isRemovable = status === Status.ACCEPTED;
    this.inWork = status === Status.IN_WORK;
    this.isCompleted = status === Status.COMPLETED;
  }

  calculateTotalFee() {
    return this.order.requestedWeightInTons * this.order.forwarderFee;
  }

  generateLoadingsDate() {
    const loadingDayCount = this.order.shipmentDates.length;

    if (loadingDayCount > 1) {
      return `c ${this.formatDate(this.order.shipmentDates[0])} до ${this.formatDate(this.order.shipmentDates[loadingDayCount - 1])}`;
    }

    if (loadingDayCount === 1) {
      return `${this.formatDate(this.order.shipmentDates[0])}`;
    }

    return '';
  }

  formatDate(dateString: string): string {
    const [year, month, day] = dateString.split('-');
    return `${day}.${month}.${year}`;
  }
}
