import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import {
  AppModeStatus,
  OfflineModeService
} from '../../services/offline-mode.service';
import { OrderChangesService } from '../../services/order-changes.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private interval = 15 * 60 * 1000;
  private timerId: NodeJS.Timeout | null = null;

  constructor(
    protected offlineService: OfflineModeService,
    private orderChanges: OrderChangesService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((event) => {
        if (event instanceof HttpResponse && event.status === 200) {
          // Обработка успешного ответа (HTTP 200)
          this.setStatus(AppModeStatus.ONLINE);
        }
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 0) {
          // Ошибка отсутствия соединения
          this.setStatus(AppModeStatus.OFFLINE); // Показать "Нет связи"
        } else {
          // Остальные ошибки
          this.setStatus(AppModeStatus.ONLINE); // Показать "Связь есть"
        }
        return throwError(() => error);
      })
    );
  }

  setStatus(newStatus: AppModeStatus): void {
    const curStatus = this.offlineService.appModeStatus$.getValue();
    this.offlineService.appModeStatus$.next(newStatus);
    if (curStatus !== newStatus) {
      if (newStatus === AppModeStatus.ONLINE) {
        // вышли из режима OFFLINE
        // останавливаем таймер
        if (this.timerId) {
          clearInterval(this.timerId);
          this.timerId = null;
        }
        // можно попробовать запустить синхронизацию заказов
        this.executeTask();
      } else {
        // вышли из режима ONLINE
        // запускаем таймер...для следующей проверки
        this.startTimerTask();
      }
    }
  }

  private startTimerTask(): void {
    this.timerId = setInterval(() => {
      this.executeTask();
    }, this.interval);
  }

  private executeTask() {
    // Здесь разместите код, который нужно выполнить каждые 15 минут
    // this.orderChanges.mergeOrderChanges();
    // console.log('Вызвано каждые 15 минут');
  }
}
