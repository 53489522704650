<app-header
  [showBackButton]="true"
  [headerData]="headerDataValues"
  class="full"
></app-header>
<app-offline-mode></app-offline-mode>
<ion-content *ngIf="!expeditorDocGenerating">
  <div class="content-wrapper">
    <div class="spinner" *ngIf="loading">
      <ion-spinner name="lines"></ion-spinner>
    </div>
    <ion-button
      (click)="navBack()"
      class="square light large"
      *ngIf="!endDayConfirmed"
    >
      Исправить день
    </ion-button>

    <div class="end-day-result">
      <div class="date">{{ dayData.shipmentDate.split(' ')[0] }}</div>
      <span class="note">Тонн отгружено за день:</span>
      <div class="weight-result">
        <span class="value">{{ totalWeight / 1000 }}</span>
        <!-- <span class="description">т.</span> -->
      </div>

      <div (click)="downloadExpeditorNote()" class="download-button">
        <img src="assets/icon/download-file-icon.svg" alt="" />
        <span>Расписка</span>
      </div>
    </div>

    <div *ngIf="endDayConfirmed" class="close-btn-wrapper">
      <ion-button (click)="navBack()" class="square light large">
        Закрыть
      </ion-button>
    </div>
  </div>
</ion-content>
<ion-content *ngIf="expeditorDocGenerating">
  <div class="loading-spiner-wrapper">
    <div class="spiner-wrapper">
      <img src="assets/icon/big-spiner.svg" alt="" />
      <span> Подождите... Формирую расписку Экспедитора </span>
    </div>
  </div>
</ion-content>

<ion-footer *ngIf="!endDayConfirmed">
  <app-swipe-button
    [title]="'Подтвердить и завершить'"
    (swipedEvent)="confirmDay($event)"
    [isSwiped]="endDayConfirmed"
    (clickEvent)="confirmDay(false)"
  >
  </app-swipe-button>
</ion-footer>
