import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { OrdersService } from 'src/app/services/orders.service';
import { SearchItem } from '../../dto/search-item.dto';

@Component({
  selector: 'app-search-modal',
  templateUrl: './search-modal.component.html',
  styleUrls: ['./search-modal.component.scss']
})
export class SearchModalComponent implements OnInit {
  @ViewChild('searchBar') searchBarInput: any;

  @Input() searchTerms: SearchItem[];

  selectedItems: SearchItem[] = [];

  filteredItems: SearchItem[] = [];

  constructor(
    private modalCtrl: ModalController,
    private ordersService: OrdersService
  ) {}

  ngOnInit(): void {
    this.selectedItems = [...this.searchTerms];
  }

  ionViewDidEnter() {
    this.searchBarInput.setFocus();
  }

  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  confirm() {
    return this.modalCtrl.dismiss(this.selectedItems, 'confirm');
  }

  searchbarInput(event: any) {
    this.filterList(event.target.value);
  }

  selectItem(selectedItem: SearchItem) {
    const isAlreadySelected = this.selectedItems.find(
      (item) =>
        selectedItem.district.name === item.district.name &&
        selectedItem.region.name === item.region.name
    );
    if (!isAlreadySelected) this.selectedItems.push(selectedItem);

    this.searchBarInput.value = '';
    this.searchBarInput.setFocus();
  }

  removeSelection(event: any) {
    this.selectedItems = this.selectedItems.filter(
      (item) =>
        !(
          item.district.name === event.district.name &&
          item.region.name === event.region.name
        )
    );
  }

  private filterList(searchQuery: string) {
    if (searchQuery?.length >= 2) {
      const normalizedQuery = searchQuery.toLowerCase();
      this.ordersService.filterByRegion(normalizedQuery).subscribe((res) => {
        if (res.success) {
          this.filteredItems = [...res.data];
        }
      });
    }
  }
}
