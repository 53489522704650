import { Injectable } from '@angular/core';
import { ExcelGeneratorData } from 'src/app/dto/excel-generation-data.dto';
import { Order } from 'src/app/dto/order.dto';
import { RtsDay, RtsVehicle } from 'src/app/dto/get-rts-result.dto';
import { XlsxTemplateFillerServiceService } from './xlsx-template-filler-service.service';
import { DateTimeService } from './date-time.service';

@Injectable({
  providedIn: 'root'
})
export class ForwarderNoteService {
  private readonly ttnTemplatePath: string = 'assets/docs/forwarder-note.xlsx';

  constructor(
    private xlsxTemplateFillerServiceService: XlsxTemplateFillerServiceService,
    private dateTimeService: DateTimeService
  ) {}

  async getForwarderNote(
    vehicleArr: RtsVehicle[],
    order: Order,
    day: RtsDay
  ): Promise<void> {
    const data = await this.generateForwarderNoteDataStructure(
      vehicleArr,
      order,
      day
    );
    await this.xlsxTemplateFillerServiceService.generateAndOpenNewExcelFile(
      this.ttnTemplatePath,
      data,
      `Расписка экспедитора-${order.id}`
    );
  }

  private async generateForwarderNoteDataStructure(
    vehicleArr: RtsVehicle[],
    order: Order,
    day: RtsDay
  ) {
    const generateForwarderNoteData: ExcelGeneratorData = {
      cellDataArray: [],
      additionalOptions: {
        removeCellPoints: [
          {
            pageNumber: 1,
            startRow: 21 + vehicleArr.length,
            endRow: 67
          }
        ]
      }
    };

    generateForwarderNoteData.cellDataArray.push({
      address: 'AR4',
      value: `${order.id}`,
      name: 'order-id',
      pageNumber: 1
    });

    generateForwarderNoteData.cellDataArray.push({
      address: 'BB4',
      value: `${new Date().getDate()}`,
      name: 'day',
      pageNumber: 1
    });

    generateForwarderNoteData.cellDataArray.push({
      address: 'BG4',
      value: this.dateTimeService.getMonthString(),
      name: 'month',
      pageNumber: 1
    });

    generateForwarderNoteData.cellDataArray.push({
      address: 'BR4',
      value: `${new Date().getFullYear() % 100}`,
      name: 'year',
      pageNumber: 1
    });

    generateForwarderNoteData.cellDataArray.push({
      address: 'AL7',
      value: `${order.id}`,
      name: 'order-id',
      pageNumber: 1
    });

    generateForwarderNoteData.cellDataArray.push({
      address: 'AV7',
      value: this.dateTimeService.getMonthString(),
      name: 'date-now',
      pageNumber: 1
    });

    generateForwarderNoteData.cellDataArray.push({
      address: 'BF7',
      value: `${new Date().getFullYear() % 100}`,
      name: 'year',
      pageNumber: 1
    });

    if (order?.buyer.orgName) {
      generateForwarderNoteData.cellDataArray.push({
        address: 'T9',
        value: `${order.buyer.orgName}`,
        name: 'buyer-name',
        pageNumber: 1
      });
    }

    if (order?.forwarder.orgName) {
      generateForwarderNoteData.cellDataArray.push({
        address: 'T10',
        value: `${order.forwarder.orgName}`,
        name: 'forwarder-name',
        pageNumber: 1
      });

      generateForwarderNoteData.cellDataArray.push({
        address: 'T11',
        value: `${order.forwarder.orgName}`,
        name: 'forwarder-name',
        pageNumber: 1
      });
    }

    if (order.loading.address) {
      generateForwarderNoteData.cellDataArray.push({
        address: 'T12',
        value: `${order.loading.address}`,
        name: 'loading-address',
        pageNumber: 1
      });
    }

    if (order.buyer.orgName) {
      generateForwarderNoteData.cellDataArray.push({
        address: 'T13',
        value: `${order.buyer.orgName}`,
        name: 'buyer-orgName',
        pageNumber: 1
      });
    }
    if (order.unloading.address) {
      generateForwarderNoteData.cellDataArray.push({
        address: 'T14',
        value: `${order.unloading.address}`,
        name: 'unloading-address',
        pageNumber: 1
      });
    }

    if (order.freightName) {
      generateForwarderNoteData.cellDataArray.push({
        address: 'T15',
        value: `${order.freightName}`,
        name: 'unloading-address',
        pageNumber: 1
      });
    }

    let startRowNumber = 21;
    let startIndex = 1;
    let totalWeight = 0;

    vehicleArr.forEach(car => {
      if (car.loadingData && car.loadingData.grossWeight) {
        generateForwarderNoteData.cellDataArray.push({
          address: `C${startRowNumber}`,
          value: `${startIndex}`,
          name: 'list-number',
          pageNumber: 1
        });

        generateForwarderNoteData.cellDataArray.push({
          address: `G${startRowNumber}`,
          value: `${order.id}-${startRowNumber}`,
          name: 'ttn-id',
          pageNumber: 1
        });

        if (car.trailerRegistrationNumber) {
          generateForwarderNoteData.cellDataArray.push({
            address: `V${startRowNumber}`,
            value: `${car.registrationNumber}(${car.trailerRegistrationNumber})`,
            name: 'car-trail-number',
            pageNumber: 1
          });
        } else {
          generateForwarderNoteData.cellDataArray.push({
            address: `V${startRowNumber}`,
            value: `${car.registrationNumber} без прицепа`,
            name: 'car-trail-number',
            pageNumber: 1
          });
        }

        [`AG${startRowNumber}`, `P${startRowNumber}`].forEach(cellId => {
          generateForwarderNoteData.cellDataArray.push({
            address: cellId,
            value: `${day.shipmentDate.split(' ')[0]}`,
            name: 'load-day',
            pageNumber: 1
          });
        });
        generateForwarderNoteData.cellDataArray.push({
          address: `AR${startRowNumber}`,
          value: `${car.loadingData.netWeight}`,
          name: 'gross-weight',
          pageNumber: 1
        });

        totalWeight += car.loadingData.netWeight;

        startRowNumber += 1;
        startIndex += 1;
      }
    });

    generateForwarderNoteData.cellDataArray.push({
      address: 'BO68',
      value: `${totalWeight}`,
      name: 'total-weight',
      pageNumber: 1
    });

    generateForwarderNoteData.cellDataArray.push({
      address: 'AC68',
      value: `${startIndex - 1}`,
      name: 'car-count',
      pageNumber: 1
    });

    if (order.buyer.orgName) {
      generateForwarderNoteData.cellDataArray.push({
        address: 'AV71',
        value: `${order.buyer.orgName}`,
        name: 'buyer-name',
        pageNumber: 1
      });
    }

    if (order.forwarder.orgName) {
      generateForwarderNoteData.cellDataArray.push({
        address: 'I71',
        value: `${order.forwarder.orgName}`,
        name: 'forwarder-name',
        pageNumber: 1
      });
    }
    order.generateForwarderNoteActions(generateForwarderNoteData.cellDataArray);

    return generateForwarderNoteData;
  }
}
