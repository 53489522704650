import { Pipe, PipeTransform } from '@angular/core';

// Словарь для перевода месяца на русский
const monthNames: { [key: number]: string } = {
    1: 'Январь',
    2: 'Февраль',
    3: 'Март',
    4: 'Апрель',
    5: 'Май',
    6: 'Июнь',
    7: 'Июль',
    8: 'Август',
    9: 'Сентябрь',
    10: 'Октябрь',
    11: 'Ноябрь',
    12: 'Декабрь'
};

@Pipe({
    name: 'groupDates'
})
export class GroupDatesPipe implements PipeTransform {
    transform(shipmentDates: string[]): { [key: string]: string } {
        const dateMap: { [key: string]: number[] } = {};

        shipmentDates.forEach((dateStr) => {
            const date = new Date(dateStr);
            const month = date.getMonth() + 1; // Месяцы в JavaScript начинаются с 0
            const year = date.getFullYear();
            const day = date.getDate();

            const key = `${monthNames[month]} ${year}`;

            if (!dateMap[key]) {
                dateMap[key] = [];
            }
            dateMap[key].push(day);
        });

        const result: { [key: string]: string } = {};
        Object.keys(dateMap).forEach((key) => {
            const days = dateMap[key].sort((a, b) => a - b).join(', ');
            result[key] = days;
        });

        return result;
    }
}
