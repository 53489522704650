import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-offline-modal',
  templateUrl: './offline-modal.component.html',
  styleUrls: ['./offline-modal.component.scss'],
})
export class OfflineModalComponent {
  constructor(
    private modalCtrl: ModalController
  ) { }

  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }
}
