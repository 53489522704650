import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { IonicModule } from '@ionic/angular';
import { MaskitoDirective } from '@maskito/angular';
import { ActionsModalComponent } from './actions-modal/actions-modal.component';
import { SwipeButtonComponent } from './swipe-button/swipe-button.component';
import { SwiperButtonDirective } from '../utils/directives/swiper-button.directive';
import { OfflineModeComponent } from './offline-mode/offline-mode.component';
import { OfflineModalComponent } from './offline-modal/offline-modal.component';
import { BaseInputComponent } from './base-input/base-input.component';
import { CarEditorComponent } from './car-editor/car-editor.component';
import { SdizInputComponent } from './sdiz-input/sdiz-input.component';

@NgModule({
  declarations: [
    ActionsModalComponent,
    SwipeButtonComponent,
    OfflineModeComponent,
    OfflineModalComponent,
    SwiperButtonDirective,
    BaseInputComponent,
    CarEditorComponent,
    SdizInputComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    IonicModule.forRoot(),
    ReactiveFormsModule,
    MaskitoDirective
  ],
  exports: [
    ActionsModalComponent,
    SwipeButtonComponent,
    OfflineModalComponent,
    OfflineModeComponent,
    BaseInputComponent,
    CarEditorComponent,
    FormsModule
  ]
})
export class SharedModule { }
