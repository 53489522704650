<app-header
  [showBackButton]="true"
  [headerData]="headerDataValues"
  class="full"
></app-header>
<app-offline-mode></app-offline-mode>
<ion-content>
  <div class="spinner" *ngIf="loading">
    <ion-spinner name="lines"></ion-spinner>
  </div>
  <ion-item
    [button]="isRequestInWork"
    class="item ion-no-padding"
    *ngFor="let item of carsList; index as i"
    mode="md"
    (click)="openCarDetails(item, i + 1)"
  >
    <div class="item-container">
      <div class="index">
        {{ i + 1 }}
      </div>
      <div class="car-details">
        <div class="car-number">{{ item.registrationNumber }}</div>
        <div class="info">
          <div>
            Водитель: <strong>{{ item.driverName }}</strong>
          </div>
          <div>
            Владелец: <strong>{{ item.carrierName }}</strong>
          </div>
          <div>
            Телефон:
            <strong *ngIf="item.driverPhone" class="driver-phone">{{
              item.driverPhone
            }}</strong>
          </div>
        </div>
        <div
          *ngIf="isRequestInWork && item?.loadingData?.netWeight"
          class="shipped"
        >
          <strong>Отгружено:</strong>
          <div class="tons">{{ item.loadingData.netWeight }} кг</div>
        </div>
        <!-- <div *ngIf="isRequestInWork" class="shipping-queue">
          В очереди на погрузку
        </div> -->
      </div>
    </div>
  </ion-item>
  <div
    (click)="downloadExpeditorNote()"
    *ngIf="dayDetails.completed"
    class="additional-button-wrapper"
  >
    <div class="download-button">
      <img src="assets/icon/download-file-icon.svg" alt="" />
      <span>Расписка экспедитора</span>
    </div>
  </div>
</ion-content>
<ion-footer *ngIf="isRequestInWork && !dayDetails.completed">
  <app-swipe-button
    [title]="'Начать день'"
    [swipedText]="'Завершить день'"
    [isSwiped]="isDayStarted"
    (swipedEvent)="dayStart($event)"
    (clickEvent)="dayEnd($event)"
    [disabled]="disableDayStartSwipe"
  >
  </app-swipe-button>
</ion-footer>
