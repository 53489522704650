import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, throwError, Observable } from 'rxjs';
import { BaseHttpService } from './base-http.service';
import { ConfigService } from './config.service';

export interface GetAllBackupListWithVersionResponse {
  success: boolean;
  data: {
    backupVersions: { id: number, version: number }[];
  }
}

export interface GetAllBackupListResponse {
  success: boolean; data: number[]
}

@Injectable({
  providedIn: 'root'
})
export class BackupApiService extends BaseHttpService {
  constructor(
    private superHttp: HttpClient,
    private superConfig: ConfigService,
  ) {
    super(superHttp, superConfig);
  }

  /**
   * отправка бекапа на сервер
   * @param {any} orderId:string
   * @param {any} zipBlob:Blob
   * @param {any} backupVersion:number
   * @param {any} overwrite:boolean=false
   * @param {any} idempotencyKey:string
   * @returns {any}
   */
  sendBackup(orderId: string, zipBlob: Blob, backupVersion: number, overwrite: boolean, idempotencyKey: string) {
    const headers = {
      'Content-Type': 'application/octet-stream',
      'Backup-Version': backupVersion.toString(),
    };
    return this.http.post<any>(
      `${this.baseRestURL}forwarding/backup/${orderId}/${idempotencyKey}`,
      zipBlob,
      {
        headers,
        params: { overwrite: overwrite.toString() }
      }
    );
  }

  /**
   * получение списка бекапов
   * @returns {any}
   */
  getAllBackupList() {
    return this.http.get<GetAllBackupListResponse>(`${this.baseRestURL}forwarding/backup/all`)
      .pipe(
        catchError((error) => {
          // console.error('Ошибка при выполнении запроса:', error);
          return throwError(() => new Error(error));

        })
      );
  }

  getAllBackupListWithVersion() {
    return this.http.get<GetAllBackupListWithVersionResponse>(`${this.baseRestURL}forwarding/backup/version/all`)
      .pipe(
        catchError((error) => {
          // console.error('Ошибка при выполнении запроса:', error);
          return throwError(() => new Error(error));
        })
      );
  }

  /**
   *  получение списка бекапов
   * @param {any} id:string|number
   * @returns {any}
   */
  getBackupVersionByNumber(id: string | number) {
    return this.http.get<any>(`${this.baseRestURL}forwarding/backup/version/${+id}`, { observe: 'response' })
      .pipe(
        catchError((error) => {
          if (error.status === 204) {
            // console.log('Сервер вернул статус ответа 204 (No Content). Нет данных.');
          }
          // console.error('Ошибка при выполнении запроса:', error);
          return throwError(() => new Error(error));
        })
      );
  }

  /**
   * получить содержимое бекапа по ИД
   * @param {any} id:number
   * @returns {any}
   */
  getBackupById(id: number): Observable<any> {
    return this.http.get(`${this.baseRestURL}forwarding/backup/${id}`, { responseType: 'blob', observe: 'response' })
      .pipe(
        catchError((error) => {
          if (error.status === 204) {
            // console.log('Для этой заявки нет бэкапа');
          }
          // console.error('Ошибка при выполнении запроса:', error);
          return throwError(() => new Error(error));
        })
      );
  }
}
