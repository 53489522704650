import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateTimeService {
  private months = [
    'январь',
    'февраль',
    'март',
    'апрель',
    'май',
    'июнь',
    'июль',
    'август',
    'сентябрь',
    'октябрь',
    'ноябрь',
    'декабрь'
  ];

  getMonthString() {
    const currentDate = new Date();
    const currentMonthIndex = currentDate.getMonth();
    return this.months[currentMonthIndex];
  }

  getMonthNumberString() {
    const currentDate = new Date();
    const currentMonthIndex = currentDate.getMonth();
    if (currentMonthIndex < 10) return `0${currentMonthIndex}`;
    return `${currentMonthIndex}`;
  }

  getNowDateStringFormat() {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const year = today.getFullYear();
    return `${day}.${month}.${year}`;
  }

  getDateFromTimestamp(timestamp: number) {
    const date = new Date(Number(timestamp));
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  }

  getDayOfMonthFromTimestamp(timestamp: number) {
    const date = new Date(timestamp);
    return date.getDate();
  }

  getYearFromYYYYmmDD(dateString: string) {
    const parts = dateString.split('-');
    if (parts.length === 3) {
      return parseInt(parts[0], 10);
    }
    return null;
  }

  formatDate(dateString: string) {
    const [year, month, day] = dateString.split('-');
    return `${day}-${month}-${year}`;
  }
}
