import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { initializeApp } from 'firebase/app';
import { FirebaseMessaging } from '@capacitor-firebase/messaging';
import { BackgroundFetch } from '@transistorsoft/capacitor-background-fetch';
import { Network } from '@capacitor/network';
import { IonToast, ModalController, ToastController } from '@ionic/angular';
// eslint-disable-next-line import/no-extraneous-dependencies
import { OverlayEventDetail } from '@ionic/core/components';
import { environment } from '../environments/environment';
import {
  AppModeStatus,
  OfflineModeService
} from './services/offline-mode.service';
import { SmartSeedsService } from './services/smart-seeds.service';
import { OrdersService } from './services/orders.service';
import { ErrorToastService } from './services/error-toast.service';
import { BackupService, ConflictVersionModalData } from './services/backup.service';
import { VersionConflictModalComponent } from './components/version-conflict-modal/version-conflict-modal.component';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  @ViewChild('errorToast') errorToast: IonToast;
  @ViewChild('smartSeedsToast') smartSeedsToast: IonToast;
  @ViewChild('smartSeedsError') smartSeedsError: IonToast;
  @ViewChild('notificationToast') notificationToast: IonToast;
  @ViewChild('modalContainer', { read: ViewContainerRef }) modalContainer: ViewContainerRef;

  backupModalData: ConflictVersionModalData;

  constructor(
    private smartSeedsService: SmartSeedsService,
    protected offlineService: OfflineModeService,
    private ordersService: OrdersService,
    private errorToastService: ErrorToastService,
    private toastController: ToastController,
    private backupService: BackupService,
    private modalCtrl: ModalController,
  ) {
    this.backupService.versionConflictModalTrigger$.subscribe(r => {
      if (r) {
        this.backupModalData = r;
        this.createModal(r);
      }
    });

    this.errorToastService.errorSubject.subscribe(r => {
      switch (r) {
        case 422:
          this.smartSeedsError.present();

          break;
        case 418:
          this.smartSeedsToast.present();

          break;

        case 406:
          break;
        case 409:
          break;
        case 500:
          this.presentErrorToast();
          break;
        default:
          break;
      }
    });

    this.initializeFirebase();
    if (Capacitor.isNativePlatform()) {
      this.initBackgroundFetch();
    }
    this.requestPermissions();

    Network.addListener('networkStatusChange', status => {
      if (status.connected) {
        this.offlineService.appModeStatus$.next(AppModeStatus.ONLINE);
        this.ordersService.runAllActions();
        this.backupService.executeBackupActions();
      } else {
        this.offlineService.appModeStatus$.next(AppModeStatus.OFFLINE);
      }
      // console.log('Network status changed', status);
    });

    FirebaseMessaging.addListener('notificationReceived', (event: any) => {
      // console.log(`notificationReceived2: ${JSON.stringify(event)}`);
      if (Capacitor.getPlatform() === 'android') {
        this.showNotificationToastText(`${event.notification.title} `, `${event.notification.body}`);
      }
      // TODO обработать клик по нотификации, когда приложение ios/android активно
    });

    FirebaseMessaging.addListener(
      'notificationActionPerformed',
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      (event: any) => {
        // TODO обработать клик по нотификации, когда приложение ios/android не активно
        // console.log(`notificationActionPerformed: ${JSON.stringify(event)}`);
      }
    );

    if (Capacitor.getPlatform() === 'web') {
      navigator.serviceWorker.addEventListener('message', (event: any) => {
        // console.log(`serviceWorker message: ${JSON.stringify(event.data)}`);
        const notification = new Notification(event.data.notification.title, {
          body: event.data.notification.body
        });

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        notification.onclick = (clickEvent: any) => {
          // console.log(`notification clicked: ${JSON.stringify(clickEvent)}`);
          // TODO обработать клик по нотификации web приложения
        };
      });
    }
  }

  async showNotificationToastText(header: string, text: string) {
    const toast = await this.toastController.create({
      message: text,
      header,
      duration: 4000,
      cssClass: 'notification-toast',
      icon: 'alert-circle-outline',
    });

    await toast.present();
  }

  public async initializeFirebase(): Promise<void> {
    if (Capacitor.isNativePlatform()) {
      return;
    }
    initializeApp(environment.firebase);
  }

  public async requestPermissions(): Promise<void> {
    await FirebaseMessaging.requestPermissions();
  }

  async initBackgroundFetch() {
    const status = await BackgroundFetch.configure(
      {
        minimumFetchInterval: 15
      },
      async taskId => {
        // console.log('[BackgroundFetch] EVENT:', taskId);
        // Perform your work in an awaited Promise
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const result = await this.performYourWorkHere();
        // console.log('[BackgroundFetch] work complete:', result);
        // [REQUIRED] Signal to the OS that your work is complete.
        BackgroundFetch.finish(taskId);
      },
      async taskId => {
        // The OS has signalled that your remaining background-time has expired.
        // You must immediately complete your work and signal #finish.
        console.log('[BackgroundFetch] TIMEOUT:', taskId);
        // [REQUIRED] Signal to the OS that your work is complete.
        BackgroundFetch.finish(taskId);
      }
    );

    // Checking BackgroundFetch status:
    if (status !== BackgroundFetch.STATUS_AVAILABLE) {
      // Uh-oh:  we have a problem:
      if (status === BackgroundFetch.STATUS_DENIED) {
        alert(
          'The user explicitly disabled background behavior for this app or for the whole system.'
        );
      } else if (status === BackgroundFetch.STATUS_RESTRICTED) {
        alert(
          'Background updates are unavailable and the user cannot enable them again.'
        );
      }
    }
  }

  async performYourWorkHere() {
    return new Promise(resolve => {
      this.ordersService.runAllActions();

      resolve(true);
    });
  }

  async presentErrorToast() {
    this.errorToast.present();
  }

  onWillDismiss(event: Event) {
    const ev = event as CustomEvent<OverlayEventDetail<string>>;
    if (ev.detail.role === 'confirm') {
      // console.log(ev);
    }
  }

  async createModal(data: ConflictVersionModalData) {
    const id = `modal-${Math.random().toString(36).substr(2, 9)}`;
    const modal = await this.modalCtrl.create({
      component: VersionConflictModalComponent,
      breakpoints: [0, 1],
      initialBreakpoint: 1,
      componentProps: {
        data,
        id
      },
      backdropDismiss: false,
      keyboardClose: false,
    });

    modal.present();

    // const { role } = await modal.onWillDismiss();

    // if (role === 'confirm') { }
  }
}
