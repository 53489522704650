import { Component } from '@angular/core';
import { HeaderDto } from '../../dto/header.dto';

@Component({
  selector: 'app-expeditor-terms',
  templateUrl: './expeditor-terms.component.html',
  styleUrls: ['./expeditor-terms.component.css']
})
export class ExpeditorTermsComponent {
  headerDataValues: HeaderDto = {
    title: 'Договор экспедирования',
    downloadOption: true
  };
}
