import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneNumber'
})
export class PhoneNumberPipe implements PipeTransform {
  transform(phoneNumber: string): string {
    if (!phoneNumber) {
      return '';
    }

    // Убираем все нецифровые символы
    const cleaned = phoneNumber.replace(/\D/g, '');

    // Проверяем, что номер имеет необходимую длину
    if (cleaned.length === 11) {
      // Форматируем номер
      return `+${cleaned.slice(0, 1)} (${cleaned.slice(1, 4)}) ${cleaned.slice(4, 7)}-${cleaned.slice(7, 9)}-${cleaned.slice(9, 11)}`;
    }

    // Возвращаем необработанный номер, если он не соответствует ожиданиям
    return phoneNumber;
  }
}
