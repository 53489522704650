<div class="spinner" *ngIf="loading">
  <ion-spinner name="lines"></ion-spinner>
</div>

<div class="no-data" *ngIf="noData && !loading">
  <h4>Заявки отсутствуют</h4>
</div>

<ion-list [style.display]="!loading ? 'block' : 'none'">

  <app-order
    *ngFor="let item of ordersItems"
    [order]="item"
    (reloadRequests)="reloadList()"
    (awaitLoading)="setLoadingStatus($event)"
    ></app-order>
  </ion-list>

<ion-refresher
  *ngIf="!loading"
  class="refresher"
  slot="fixed"
  [disabled]="isRefresherDisabled"
  (ionRefresh)="reloadList($event)"
>
  <ion-refresher-content
    pullingIcon=""
    refreshingSpinner="lines"
  ></ion-refresher-content>
</ion-refresher>

<ion-infinite-scroll (ionInfinite)="onIonInfinite($event)">
  <ion-infinite-scroll-content
    loadingSpinner="lines"
  ></ion-infinite-scroll-content>
</ion-infinite-scroll>

<div class="no-more-data" *ngIf="isNoMoreData && !noData && !loading">
  <div class="label">Вы посмотрели все заявки</div>
  <div><img src="../../../assets/images/cars.svg" alt="cars" /></div>
</div>
