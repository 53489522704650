<div class="container">
  <div [ngStyle]="getTitleStyles()" class="title">{{ data.title }}</div>

  <img *ngIf="data.imageSrc" src="{{ data.imageSrc }}" alt="image" />

  <div class="message" *ngIf="data.message">{{ data.message }}</div>

  <div *ngIf="data.expeditorTerms" class="expeditor-terms">
    <ion-checkbox labelPlacement="end" [(ngModel)]="acceptExpeditorTerms">
      <span> Принять </span>
    </ion-checkbox>
    <span class="pseudo-link" (click)="openExpeditorTerms()"
      >Договор экспедирования
    </span>
    <span> и </span>
    <a  (click)="openFile()" class="agent-terms">
      Агентский договор(оферта)
    </a>
  </div>

  <ion-button
    *ngIf="data.addButton"
    class="secondary large"
    mode="md"
    (click)="addCarsToDay()"
  >
    <ion-icon src="../../../assets/icon/truck-icon.svg"></ion-icon>
    Добавить машину
  </ion-button>

  <!-- <ion-button
    *ngIf="data.exportPDFButton"
    class="secondary large"
    mode="md"
    (click)="exportPDF()"
  >
    <ion-icon src="../../../assets/icon/pdf-icon.svg"></ion-icon>
    Экспорт РТС в PDF
  </ion-button> -->

  <ion-button
    *ngIf="data.exportXLSButton"
    class="secondary large"
    mode="md"
    (click)="exportXLS()"
  >
    <ion-icon src="../../../assets/icon/xls-icon.svg"></ion-icon>
    Скачать РТС в XLS
  </ion-button>

  <!-- принять заявку в работу -->
  <ion-button
    *ngIf="data.confirmButton && data.expeditorTerms"
    class="primary large"
    mode="md"
    (click)="confirm()"
    [disabled]="!acceptExpeditorTerms"
  >
    {{ data.confirmButtonText }}
  </ion-button>

  <ion-button
    *ngIf="data.confirmButton && !data.expeditorTerms"
    class="primary large {{data.confirmButtonClass}}"
    mode="md"
    (click)="confirm()"
  >
    {{ data.confirmButtonText }}
  </ion-button>
  <ion-button
    *ngIf="data.rejectButton"
    class="danger large"
    mode="md"
    (click)="reject()"
  >
    {{ data.rejectButtonText }}
  </ion-button>
  <ion-button
    *ngIf="!data.closeButton"
    class="cancel large"
    mode="md"
    (click)="cancel()"
    >Отмена</ion-button
  >
  <ion-button
    *ngIf="data.closeButton"
    class="cancel large"
    mode="md"
    (click)="cancel()"
    >Закрыть</ion-button
  >
</div>

<!-- <ion-toast
  *ngIf="exportResult"
  [cssClass]="exportResult.isSuccess ? 'success' : 'error'"
  [isOpen]="isToastOpen"
  [message]="exportResult.message"
  [duration]="1500"
  (didDismiss)="setOpen(false)"
  [animated]="true"
>
</ion-toast> -->
