import { Component } from '@angular/core';
import * as _ from 'lodash';
import { HeaderDto } from '../../../dto/header.dto';
import { OrdersListDto } from '../../../dto/orders-list.dto';
import { Status } from '../../../dto/status.enum';
import { ArchiveFilter } from '../../header/header.component';

@Component({
  selector: 'app-archived-orders',
  templateUrl: './archived-orders.component.html',
  styleUrls: ['./archived-orders.component.scss']
})
export class ArchivedOrdersComponent {
  headerDataValues: HeaderDto = {
    statuses: [Status.COMPLETED],
    totalCount: 0,
    searchTerms: [],
    title: 'Архив',
    tabRoute: 'archived-requests'
  };

  ordersListDataValues: OrdersListDto = {
    statuses: [Status.COMPLETED, Status.CLOSED],
    searchTerms: [],
    archiveListFilter: ArchiveFilter.LOADED
  };

  getTotalCount(totalCount: number) {
    this.headerDataValues = {
      ...this.headerDataValues,
      totalCount
    };
  }

  filterArchiveList(event: ArchiveFilter) {
    const newListDataValues = _.cloneDeep(this.ordersListDataValues);
    newListDataValues.archiveListFilter = event;
    this.ordersListDataValues = newListDataValues;
  }
}
