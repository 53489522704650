import { Injectable } from '@angular/core';
import { ExcelGeneratorData } from 'src/app/dto/excel-generation-data.dto';
import { Order } from 'src/app/dto/order.dto';
import { XlsxTemplateFillerServiceService } from './xlsx-template-filler-service.service';
import { DateTimeService } from './date-time.service';

@Injectable({
  providedIn: 'root'
})
export class ForwarderReportGeneratorService {
  private readonly trnTemplatePath: string =
    'assets/docs/forwarder-report.xlsx';

  constructor(
    private xlsxTemplateFillerServiceService: XlsxTemplateFillerServiceService,
    private dateTimeService: DateTimeService
  ) { }

  async getForwarderReport(order: Order): Promise<void> {
    const data = await this.generateForwarderReportDataStructure(order);
    await this.xlsxTemplateFillerServiceService.generateAndOpenNewExcelFile(
      this.trnTemplatePath,
      data,
      `Отчет экспедитора-${order.id}`
    );
  }

  private async generateForwarderReportDataStructure(order: Order) {
    const generateForwarderReportData: ExcelGeneratorData = {
      cellDataArray: []
    };

    generateForwarderReportData.cellDataArray.push({
      address: 'AT4',
      value: `${order.id}-${Date.now().toString().slice(-5)}`,
      name: 'order-id',
      pageNumber: 1
    });

    generateForwarderReportData.cellDataArray.push({
      address: 'BB4',
      value: `${this.dateTimeService.getNowDateStringFormat()}`,
      name: 'created-date',
      pageNumber: 1
    });

    generateForwarderReportData.cellDataArray.push({
      address: 'AN7',
      value: `${order.id}`,
      name: 'order-id',
      pageNumber: 1
    });
    generateForwarderReportData.cellDataArray.push({
      address: 'AF9',
      value: `${order.id}`,
      name: 'order-id',
      pageNumber: 1
    });

    generateForwarderReportData.cellDataArray.push({
      address: 'AX7',
      value: `${this.dateTimeService.getNowDateStringFormat()}`,
      name: '',
      pageNumber: 1
    });

    if (order.createdDate) {
      generateForwarderReportData.cellDataArray.push({
        address: 'AK9',
        value: `${this.dateTimeService.formatDate(String(order.createdDate))}`,
        name: '',
        pageNumber: 1
      });
    }

    if (order.freightName) {
      generateForwarderReportData.cellDataArray.push({
        address: 'J11',
        value: `${order.freightName}`,
        name: 'freight-name',
        pageNumber: 1
      });
    }

    if (order.loading.address) {
      generateForwarderReportData.cellDataArray.push({
        address: 'H17',
        value: `${order.loading.address}`,
        name: '',
        pageNumber: 1
      });
    }

    generateForwarderReportData.cellDataArray.push({
      address: 'N17',
      value: `${order.getTotalNetWeight() / 1000}`,
      name: '',
      pageNumber: 1
    });
    if (order.shipmentDates) {
      generateForwarderReportData.cellDataArray.push({
        address: 'T17',
        value: this.dateTimeService.formatDate(order.shipmentDates[0]),
        name: '',
        pageNumber: 1
      });
    }

    if (order.shipmentDates) {
      generateForwarderReportData.cellDataArray.push({
        address: 'AF17',
        value: this.dateTimeService.formatDate(order.shipmentDates[order.shipmentDates.length - 1]),
        name: '',
        pageNumber: 1
      });
    }

    if (order.unloading.address) {
      generateForwarderReportData.cellDataArray.push({
        address: 'Z17',
        value: `${order.unloading.address}`,
        name: '',
        pageNumber: 1
      });
    }

    if (order?.deliveredWeightInKilograms) {
      generateForwarderReportData.cellDataArray.push({
        address: 'AL17',
        value: `${order?.deliveredWeightInKilograms}`,
        name: '',
        pageNumber: 1
      });
    }

    if (order?.forwarder.inn) {
      generateForwarderReportData.cellDataArray.push({
        address: 'AX17',
        value: `${order?.forwarder.inn}`,
        name: '',
        pageNumber: 1
      });
    }

    if (order?.forwarder.orgName) {
      generateForwarderReportData.cellDataArray.push({
        address: 'G41',
        value: `${order?.forwarder.orgName}`,
        name: '',
        pageNumber: 1
      });
    }

    if (order?.buyer.orgName) {
      generateForwarderReportData.cellDataArray.push({
        address: 'AW41',
        value: `${order?.buyer.orgName}`,
        name: '',
        pageNumber: 1
      });
    }

    order.generateForwarderReportActions(
      generateForwarderReportData.cellDataArray
    );

    return generateForwarderReportData;
  }
}
