import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { TaxNumberValidatorService } from './tax-number-validator.service';

@Injectable({
  providedIn: 'root'
})
export class SdizNumberValidatorService {
  constructor(private readonly taxNumberValidator: TaxNumberValidatorService) {
  }

  public createValidator(): ValidatorFn {
    return (control: AbstractControl<string>): ValidationErrors | null => {
      const errors: ValidationErrors = {};
      const { value } = control;
      if (value) {
        const pattern: RegExp = /^(\d{10}\d?\d?)\/(\d{2})\/(\d{1,7})$/;
        const result: RegExpExecArray | null = pattern.exec(value);
        if (result && result.length > 3) {
          const taxNumber = result[1];
          if ((taxNumber.length !== 10 && taxNumber.length !== 12)
            || !this.taxNumberValidator.checkIfValidTaxNumber(taxNumber)) {
            errors['taxNumber'] = true;
          }
          const yearNumber = result[2];
          if (!this.isValidYearIndicator(yearNumber)) {
            errors['yearNumber'] = true;
          }
          const docNumber = result[3];
          if (!this.isValidDocNumber(docNumber)) {
            errors['docNumber'] = true;
          }
        } else {
          errors['incomplete'] = true;
        }
      }
      return errors;
    };
  }

  private isValidYearIndicator(year: string): boolean {
    let valid: boolean = false;
    if (year.length === 2) {
      const shortYear: number = new Date().getFullYear() % 1000;
      valid = Number(year) <= shortYear;
    }
    return valid;
  }

  private isValidDocNumber(docNumber: string): boolean {
    return !!docNumber.length;
  }
}
