<app-header
  [showBackButton]="false"
  [headerData]="headerDataValues"
  (closeModal)="closeModal()"
  [closableIcon]="true"
  class="full"
></app-header>
<app-car-editor
  style="width: 100%; height: 100%"
  (vehicleDataUpdated)="vehicleDataUpdated()"
  [currentCarData]="editVehicleData"
  [errorTrailerRegNumbField]="setErrorTrailerRegNumbField"
></app-car-editor>
