<div
  id="{{ swipeId }}"
  [ngClass]="{ 'button-background-disabled': disabled }"
  class="button-background"
>
  <span [ngClass]="{ 'slide-text-disabled': disabled }" class="slide-text">{{
    title
  }}</span>

  <ion-button
    [disabled]="disabled"
    class="slider large"
    appSwiper
    [swipedText]="swipedText"
    [swipeId]="swipeId"
    id="{{ swipeId + 'slider' }}"
    [isSwiped]="isSwiped"
    (click)="wasClickedEvent(true)"
    (swipedEvent)="wasSwipedEvent($event)"
  >
    <ion-icon
      class="material-icons"
      src="../../../assets/icon/swipe-icon.svg"
    ></ion-icon>
  </ion-button>
</div>
