import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Base64 } from 'js-base64';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { firstValueFrom, map, Observable, of } from 'rxjs';
import { BaseHttpService } from 'src/app/services/base-http.service';

export interface SmartSeedsCredDBInterface {
  login: string;
  password: string;
  user: string;
}
interface DefaultResponse {
  success: boolean;
  data: boolean;
}

const NO_SMART_SEEDS_CRED: SmartSeedsCredDBInterface = {
  login: '',
  password: '',
  user: '',
};

@Injectable({
  providedIn: 'root'
})
export class SmartSeedsService {
  baseRestUrl: string = this.baseHttpService.baseRestURL;
  constructor(
    private readonly http: HttpClient,
    private readonly baseHttpService: BaseHttpService,
    private readonly dbService: NgxIndexedDBService,
  ) {
  }

  fetchUserCredentialsForSmartSeeds(): Observable<SmartSeedsCredDBInterface> {
    const emailFromAuth = localStorage.getItem('login');
    if (emailFromAuth) {
      return this.dbService
        .getByIndex<SmartSeedsCredDBInterface>('SmartSeedsTable', 'user', emailFromAuth)
        .pipe(map(value => value || NO_SMART_SEEDS_CRED));
    }
    return of(NO_SMART_SEEDS_CRED);
  }

  fetchUserCredentialsForSmartSeedsAsync(): Promise<SmartSeedsCredDBInterface> {
    return firstValueFrom(this.fetchUserCredentialsForSmartSeeds());
  }

  checkLogin(user: string, password: string) {
    const encodedUser: string = Base64.encode(user);

    const encodedPassword: string = Base64.encode(password);

    const curUrl = `${this.baseRestUrl}forwarding/smartseeds/checkPassword?user=${encodedUser}&password=${encodedPassword}`;
    return this.http.get<DefaultResponse>(curUrl);
  }
}
