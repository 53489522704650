import { Injectable } from '@angular/core';

import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthService } from '../../services/auth-service.service';

@Injectable()
export class AppAuthGuard {
  constructor(protected authService: AuthService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const isLoggedIn = this.authService.isLoggedIn();

    if (!isLoggedIn) {
      if (route.routeConfig?.path === 'auth') {
        return true;
      }
      this.router.navigate(['auth']);
    }

    if (isLoggedIn) {
      if (route.routeConfig?.path !== 'auth') return true;
      this.router.navigate(['requests']);
    }

    return false;
  }
}
