<ion-content>
  <div class="screen-wrapper">
    <div class="content">
      <div>
        <div class="header">
          <h1 *ngIf="registrationFlag">Сохраните ключ шифрования!</h1>
          <h1 *ngIf="!registrationFlag">Введите ключ шифрования!</h1>
        </div>

        <div *ngIf="!registrationFlag" class="note-text green-note">
          На этой странице необходимо ввести секретный ключ, сгенерированный
          ранее. Это позволит нам восстановить ваши данные.
        </div>

        <div *ngIf="registrationFlag" class="note-text green-note">
          Мы сформировали для вас, уникальный ключ шифрования, для того, чтобы
          вы могли восстановить свои данные при переходе на другое устройство.
          Обязательно сохраните его!
        </div>

        <div class="note-text red-note">
          Восстановить утерянный ключ шифрования невозможно!
        </div>
        <div class="note-text green-note">
          <img src="assets/icon/key.svg" alt="" />
          Ключ шифрования
        </div>

        <div *ngIf="registrationFlag" class="key-area">
          <div class="key">{{ secretKey }}</div>
          <div *ngIf="!checkedCopyBtn" class="button-block">
            <img
              (click)="copyText()"
              class="img-btn copy-btn"
              src="assets/icon/copy.svg"
              alt=""
            />
          </div>
          <div *ngIf="checkedCopyBtn" class="button-block">
            <div class="text">Скопировано!</div>
            <img
              (click)="copyText()"
              class="img-btn copy-btn check-btn"
              src="assets/icon/white-check.svg"
              alt=""
            />
          </div>
        </div>

        <div *ngIf="!registrationFlag" class="key-area">
          <div class="secret-key-note">
            <img src="assets/icon/warning-icon.svg" alt="" />
            <div>
              <div style="margin-bottom: 5px; font-weight: 600">Внимание!</div>
              <div>
                Отсутствует ключ шифрования. Введите ключ чтобы восстановить
                данные!
              </div>
            </div>
          </div>

          <form [formGroup]="secretKeyForm" (keydown.enter)="saveKey()">
            <app-base-input
              [placeholder]="'Введите секретный ключ'"
              [type]="'secretKey'"
              style="width: 100%"
              [control]="secretKeyForm.get('secretKey')!"
              formControlName="secretKey"
            ></app-base-input>
            <div class="error-message">
              <span *ngIf="secretKeyForm.get('secretKey')!.errors?.['required']">
                *Поле обязательно для заполнения
              </span>
              <span *ngIf="secretKeyForm.get('secretKey')!.errors?.['minlength'] || secretKeyForm.get('secretKey')!.errors?.['maxlength']">
                Ключ шифрования содержит 64 символа
              </span>
            </div>
          </form>
        </div>
      </div>

      <div style="width: 100%; margin-top: 20px">
        <ion-button
          *ngIf="registrationFlag"
          class="square large light"
          mode="md"
          (click)="saveKey()"
        >
          <span> Продолжить </span>
        </ion-button>

        <ion-button
          *ngIf="!registrationFlag"
          (click)="setSecretKey()"
          class="square light large"
          [disabled]="secretKeyForm.invalid && secretKeyForm.dirty"
        >
          <span class="button-text"> Применить ключ шифрования </span>
        </ion-button>

        <!-- <ion-button
          (click)="setGeneratedCodeToForm()"
          *ngIf="!registrationFlag"
          class="square light large"
        >
          <span class="button-text">Сгенерировать ключ шифрования </span>
        </ion-button> -->
      </div>
    </div>
  </div>
</ion-content>
