import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { v4 as uuid } from 'uuid';

@Component({
  selector: 'app-swipe-button',
  templateUrl: './swipe-button.component.html',
  styleUrls: ['./swipe-button.component.scss']
})
export class SwipeButtonComponent implements OnInit {
  @Input() title: string;
  @Input() swipedText: string;
  @Input() isSwiped: boolean;
  @Input() disabled: boolean;
  @Output() swipedEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() clickEvent: EventEmitter<boolean> = new EventEmitter();

  swipeId: string;

  ngOnInit(): void {
    this.swipeId = uuid();
  }

  wasSwipedEvent(event: boolean) {
    this.swipedEvent.emit(event);
  }

  wasClickedEvent(event: boolean) {
    if (this.isSwiped) this.clickEvent.emit(event);
  }
}
