import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { finalize, take, tap } from 'rxjs/operators';
import { BehaviorSubject, filter, Observable, of, switchMap } from 'rxjs';
import { UserManagementService } from './user-management.service';
import { RedirectService } from './redirect.service';

export interface JWTToken {
  exp: number;
  iat: number;
  auth_time: number;
  jti: string;
  iss: string;
  aud: string;
  sub: string;
  typ: string;
  azp: string;
  nonce: string;
  session_state: string;
  acr: string;
  realm_access: {
    roles: string[];
  };
  resource_access: {
    account: {
      roles: string[];
    };
  };
  scope: string;
  sid: string;
  email_verified: boolean;
  name: string;
  preferred_username: string;
  given_name: string;
  family_name: string;
  email: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private isRefreshingToken: boolean = false;
  private tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(
    private userManagementService: UserManagementService,
    private redirectService: RedirectService,
    private router: Router
  ) { }

  public isLoggedIn(): boolean {
    return !!this.getAccessToken();
  }

  public login(token: string, refreshToken?: string, ttl?: number) {
    this.saveAccessToken(token);
    if (refreshToken && ttl) {
      this.setEmailToLocalStore();
      this.saveRefreshToken(refreshToken, ttl);
    }
    this.redirectService.checkRedirectPath();
  }

  public registrationEnd(token: string, refreshToken?: string, ttl?: number) {
    this.saveAccessToken(token);
    if (refreshToken && ttl) {
      // this.startRefreshTokenTimer();
      this.setEmailToLocalStore();
      this.saveRefreshToken(refreshToken, ttl);
    }
    this.router
      .navigate(['secret-key'], {
        queryParams: { registrationStep: true }
      })
      .then(() => {
        window.location.reload();
      });
  }

  public getAccessToken(): string {
    return localStorage.getItem('token') || '';
  }

  public logout() {
    if (localStorage.getItem('refresh-token')) {
      const refreshToken = this.getRefreshToken();
      this.userManagementService.killAuthSession(refreshToken).subscribe({
        next: () => this.logoutActions(),
        error: () => {
          this.logoutActions();
        }
      });
    } else {
      this.logoutActions();
    }
  }

  private logoutActions() {
    localStorage.setItem('login', '');
    localStorage.setItem('token', '');
    localStorage.setItem('refresh-token', '');
    localStorage.setItem('ttl', '');
    this.router.navigate(['auth']);
  }

  private parseJwt(token: string | null): JWTToken | null {
    if (!token) {
      return null;
    }
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join('')
    );
    return JSON.parse(jsonPayload);
  }

  public refreshToken(): Observable<any> {
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;
      const refreshToken = this.getRefreshToken();
      return this.userManagementService.refreshToken(refreshToken).pipe(
        tap((response) => {
          this.setEmailToLocalStore();
          this.saveAccessToken(response.data.access_token);
          this.saveRefreshToken(
            response.data.refresh_token,
            response.data.refresh_expires_in
          );
          this.tokenSubject.next(response.data.access_token);
        }),
        finalize(() => {
          this.isRefreshingToken = false;
          this.tokenSubject.next('');
        })
      );
    }
    // Если уже выполняется обновление токена, то просто ждем когда оно завершится
    // для этого подписываемся на потом и ждем когда там появится новый токен и дальше продолжаем
    return this.tokenSubject.pipe(
      filter((token) => token !== ''),
      take(1),
      switchMap((token) => of(token))
    );
  }

  private saveAccessToken(token: string): void {
    localStorage.setItem('token', `Bearer ${token}`);
  }

  private saveRefreshToken(token: string, ttl?: number): void {
    localStorage.setItem('refresh-token', `${token}`);
    localStorage.setItem('ttl', `${ttl}`);
  }

  public getRefreshToken() {
    return localStorage.getItem('refresh-token') || '';
  }

  public loadUserProfile() {
    const accessToken = this.parseJwt(this.getAccessToken());
    return {
      id: accessToken?.sid,
      username: accessToken?.preferred_username,
      email: accessToken?.email,
      firstName: accessToken?.name,
      lastName: accessToken?.family_name,
      emailVerified: accessToken?.email_verified
    };
  }

  public setEmailToLocalStore() {
    const { email } = this.loadUserProfile();
    if (email) localStorage.setItem('login', email);
  }
}
