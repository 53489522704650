<ion-tabs #tabs (ionTabsDidChange)="setCurrentTab()">
  <ion-tab-bar slot="bottom" mode="md">
    <ion-tab-button tab="requests" mode="md">
      <ion-icon
        *ngIf="selectedTab !== 'requests'; else selectedRequests"
        src="../../../assets/icon/requests-icon.svg"
      ></ion-icon>
      <ng-template #selectedRequests>
        <ion-icon
          src="../../../assets/icon/requests-selected-icon.svg"
        ></ion-icon>
      </ng-template>
      Заявки
    </ion-tab-button>
    <ion-tab-button tab="requests-in-work" mode="md">
      <ion-icon
        *ngIf="selectedTab !== 'requests-in-work'; else selectedInWork"
        src="../../../assets/icon/in-work-icon.svg"
      ></ion-icon>
      <ng-template #selectedInWork>
        <ion-icon
          src="../../../assets/icon/in-work-selected-icon.svg"
        ></ion-icon>
      </ng-template>
      В работе
    </ion-tab-button>
    <ion-tab-button tab="archived-requests" mode="md">
      <ion-icon
        *ngIf="selectedTab !== 'archived-requests'; else selectedArchived"
        src="../../../assets/icon/archive-icon.svg"
      ></ion-icon>
      <ng-template #selectedArchived>
        <ion-icon
          src="../../../assets/icon/archive-selected-icon.svg"
        ></ion-icon>
      </ng-template>
      Архив
    </ion-tab-button>
    <ion-tab-button tab="account" mode="md">
      <ion-icon
        *ngIf="selectedTab !== 'account'; else selectedAccount"
        src="../../../assets/icon/account-icon.svg"
      ></ion-icon>
      <ng-template #selectedAccount>
        <ion-icon
          src="../../../assets/icon/account-selected-icon.svg"
        ></ion-icon>
      </ng-template>
      Профиль
    </ion-tab-button>
  </ion-tab-bar>
</ion-tabs>
