import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { BackupService } from './backup.service';
import { SmartSeedsService } from './smart-seeds.service';
import { BackupApiService } from './backup-api.service';

@Injectable({
  providedIn: 'root'
})
export class RedirectService {
  constructor(
    private router: Router,
    private backupService: BackupService,
    private backupApiService: BackupApiService,
    private smartSeedsService: SmartSeedsService
  ) { }

  async checkRedirectPath() {
    try {
      const secretKeyFlag = await firstValueFrom(this.backupService.checkSecretCode());
      const smartSeedsFlag = await this.smartSeedsService.fetchUserCredentialsForSmartSeedsAsync();
      const list = await firstValueFrom(this.backupApiService.getAllBackupList());

      if (!secretKeyFlag) {
        this.router.navigate(['secret-key']);
        return;
      }

      if (list.data && list.data.length && !smartSeedsFlag.login && !smartSeedsFlag.password && !smartSeedsFlag.user) {
        this.router.navigate(['smartseeds-integration'], {
          queryParams: { loginStep: true }
        });
        return;
      }

      if ((secretKeyFlag && smartSeedsFlag.login && smartSeedsFlag.password && smartSeedsFlag.user) || (list.data && !list.data.length)) {
        this.router.navigate(['requests']).then(() => {
          this.backupService.checkUpdates();
        });
      }
    } catch (error) {
      console.log(error);
    }
  }
}
