import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ActionsModalComponent } from '../shared/actions-modal/actions-modal.component';

@Injectable({
  providedIn: 'root'
})
export class AcceptNewOrderModalService {
  constructor(private modalCtrl: ModalController) {}

  async accept() {
    const modal = await this.modalCtrl.create({
      component: ActionsModalComponent,
      breakpoints: [0, 1],
      initialBreakpoint: 1,
      componentProps: {
        data: {
          confirmButton: true,
          confirmButtonText: 'Принять заявку',
          message: `Если вы примите заявку в работу, то она станет недоступной для других пользователей системы.
            Хотите принять заявку в работу?`,
          title: 'Принять заявку в работу?',
          imageSrc: '../../../assets/images/take-in-work.svg',
          expeditorTerms: true
        }
      }
    });
    modal.present();

    const { role } = await modal.onWillDismiss();

    return role;
  }
}
