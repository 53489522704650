import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { RedirectService } from 'src/app/services/redirect.service';
import { BackupService } from 'src/app/services/backup.service';
import { Subject, switchMap, takeUntil } from 'rxjs';
import { SmartSeedsService } from '../../services/smart-seeds.service';

@Component({
  selector: 'app-smartseeds-integration',
  templateUrl: './smartseeds-integration.component.html',
  styleUrls: ['./smartseeds-integration.component.scss']
})
export class SmartSeedsIntegrationComponent implements OnInit, OnDestroy {
  registrationFlag: boolean = false;
  loginFlag: boolean = false;

  awaitFlag: boolean = false;
  completedFlag: boolean = false;
  integrationError: boolean;

  loginForm: FormGroup = new FormGroup({
    login: new FormControl('', [Validators.required]),

    password: new FormControl('', [Validators.required])
  });

  private readonly destroy$: Subject<void> = new Subject();

  constructor(
    private readonly smartSeedsService: SmartSeedsService,
    private dbService: NgxIndexedDBService,
    private router: Router,
    private route: ActivatedRoute,
    private redirectService: RedirectService,
    private backupService: BackupService
  ) {

    // if (Capacitor.getPlatform() !== 'web') {
    //   Keyboard.addListener('keyboardDidShow', async (info: KeyboardInfo) => {
    //     const contentElement = document.querySelector('ion-content');
    //     if (contentElement) {
    //       contentElement.style.height = `calc(100vmax - ${info.keyboardHeight}px)`;
    //     }
    //   });

    //   Keyboard.addListener('keyboardDidHide', async () => {
    //     const contentElement = document.querySelector('ion-content');
    //     if (contentElement) {
    //       contentElement.style.height = '100vmax';
    //     }
    //   });
    // }
  }

  ngOnInit(): void {
    this.route.queryParams.pipe(
      switchMap(params => {
        this.loginFlag = params['loginStep'] === 'true';
        this.registrationFlag = params['registrationStep'] === 'true';
        return this.smartSeedsService.fetchUserCredentialsForSmartSeeds();
      }),
      takeUntil(this.destroy$)
    ).subscribe(smartSeedsDataSnap => {
      if (smartSeedsDataSnap.login) {
        this.loginForm.get('login')?.setValue(smartSeedsDataSnap.login);
      }
      if (smartSeedsDataSnap.password) {
        this.loginForm.get('password')?.setValue(smartSeedsDataSnap.password);
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  checkData() {
    this.awaitFlag = true;

    this.smartSeedsService
      .checkLogin(
        this.loginForm.get('login')?.value,
        this.loginForm.get('password')?.value
      )
      .subscribe({
        next: r => {
          if (r.data) {
            const email = localStorage.getItem('login');
            this.saveUserData(
              email!,
              this.loginForm.get('login')?.value,
              this.loginForm.get('password')?.value,
            );

            this.integrationError = false;
          } else {
            this.loginForm.get('password')!.setValue('');
            this.loginForm.get('password')!.setErrors({ invalidData: true });
          }
          this.awaitFlag = false;
        },
        error: () => {
          this.awaitFlag = false;
          this.integrationError = true;
        }
      });
  }

  skip() {
    this.router.navigate(['requests']);
  }

  close() {
    this.router.navigate(['account']);
  }

  private saveUserData(
    user: string,
    login: string,
    password: string,

  ): void {
    const userData = { user, login, password };

    // Проверяем, существует ли пользователь с таким email
    this.dbService
      .getByIndex('SmartSeedsTable', 'user', user)
      .subscribe(existingUser => {
        if (existingUser) {
          this.dbService
            .update('SmartSeedsTable', { ...existingUser, ...userData })
            .subscribe(() => {
              this.navigateToAccount();
            });
        } else {
          this.dbService.add('SmartSeedsTable', userData).subscribe(() => {
            this.navigateToAccount();
          });
        }
      });
  }

  private navigateToAccount(): void {
    if (this.registrationFlag) {
      this.router.navigate(['secret-key'], {
        queryParams: { registrationStep: true }
      });
    }

    if (this.loginFlag) {
      this.router.navigate(['request']);
    }

    if (!this.loginFlag && !this.registrationFlag) {
      this.backupService.checkUpdates();
      this.router.navigate(['account']);
    }

    this.completedFlag = true;
    this.resetPage();
  }

  private resetPage() {
    this.registrationFlag = false;
    this.awaitFlag = false;
    this.completedFlag = false;
    this.loginForm.reset();
  }
}
