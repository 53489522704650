import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Clipboard } from '@capacitor/clipboard';
import * as CryptoJS from 'crypto-js';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { AuthService } from 'src/app/services/auth-service.service';
import { BackupService } from 'src/app/services/backup.service';
import { RedirectService } from 'src/app/services/redirect.service';

@Component({
  selector: 'app-secret-key-page',
  templateUrl: './secret-key-page.component.html',
  styleUrls: ['./secret-key-page.component.scss'],
})
export class SecretKeyPageComponent implements OnInit {
  checkedCopyBtn: boolean = false;
  secretKey = CryptoJS.lib.WordArray.random(32).toString();
  email: string = '';
  registrationFlag: boolean = false;
  secretKeyAccepted: boolean = false;
  secretKeyForm = new FormGroup({
    secretKey: new FormControl('', [
      Validators.required,
      Validators.minLength(64),
      Validators.maxLength(64)
    ]),
  });

  constructor(
    private dbService: NgxIndexedDBService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private redirectService: RedirectService,
    private backupService: BackupService
  ) {
    const { email } = this.authService.loadUserProfile();
    if (email) this.email = email;
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.registrationFlag = params['registrationStep'] === 'true';
    });
  }

  saveKey() {
    this.backupService.setSecretKey(this.secretKey).subscribe(() => {
      this.secretKeyAccepted = true;
      this.redirectService.checkRedirectPath();
    });
  }

  async copyText() {
    await Clipboard.write({
      string: this.secretKey.toString()
    });

    this.checkedCopyBtn = true;

    setTimeout(() => {
      this.checkedCopyBtn = false;
    }, 2000);
  }

  setSecretKey() {
    const secretKey = this.secretKeyForm.get('secretKey')?.value;
    console.log(secretKey);

    if (secretKey) {
      this.backupService.setSecretKey(secretKey).subscribe(() => {
        this.secretKeyAccepted = true;

        this.router.navigate(['smartseeds-integration'], {
          queryParams: { registrationStep: this.registrationFlag }
        });
      });
    }
  }
}
