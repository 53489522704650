import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';
import { first } from 'rxjs';
import { RtsDay } from 'src/app/dto/get-rts-result.dto';
import { HeaderDto } from 'src/app/dto/header.dto';
import { Order } from 'src/app/dto/order.dto';
import { ForwarderNoteService } from 'src/app/services/docs-service/forwarder-note.service';
import { OrderChangesService } from 'src/app/services/order-changes.service';

@Component({
  selector: 'app-end-day',
  templateUrl: './end-day.component.html',
  styleUrls: ['./end-day.component.scss']
})
export class EndDayComponent implements OnInit {
  targetUrl = window.location.pathname.split('/')[1];
  currentDayId: string = '';
  request: Order;
  dayData: RtsDay;
  loading: boolean = false;
  endDayConfirmed: boolean = false;
  expeditorDocGenerating: boolean = false;
  totalWeight: number = 0;
  headerDataValues: HeaderDto = {
    title: ''
  };

  constructor(
    private route: ActivatedRoute,
    private navCtrl: NavController,
    private orderChangesService: OrderChangesService,
    private forwarderNoteService: ForwarderNoteService
  ) { }

  ngOnInit(): void {
    this.route.params.pipe(first()).subscribe((parameter) => {
      this.currentDayId = parameter['id'];
    });

    this.orderChangesService
      .getOrderSubject(this.targetUrl)
      .subscribe((currentRequest) => {
        if (currentRequest) {
          this.request = currentRequest;
          const dayData = this.request.getDayById(this.currentDayId);
          if (dayData) {
            this.dayData = dayData;
            this.dataUpdate();
          }
          this.dataUpdate();
        }
      });
  }

  dataUpdate(): void {
    const dayNumber = this.request.getDayNumberById(this.dayData.orderNumber);
    this.headerDataValues.title = `День ${dayNumber}`;
    this.totalWeight = 0;
    this.dayData.vehicles.forEach((el) => {
      if (el.loadingData && el.loadingData.netWeight) {
        this.totalWeight += el.loadingData.netWeight;
      }
    });
  }

  confirmDay(event: boolean): void {
    if (event) {
      const updatedRequest = this.request.endDay(this.dayData.orderNumber);

      this.orderChangesService.updateRequests(updatedRequest, this.targetUrl);
      this.expeditorDocGenerating = true;
      this.endDayConfirmed = true;

      setTimeout(() => {
        this.expeditorDocGenerating = false;
      }, 5000);
    }
  }

  async downloadExpeditorNote() {
    // this.trnDocumentGeneratingButtonSpiner = true;
    try {
      await this.forwarderNoteService.getForwarderNote(
        this.dayData.vehicles,
        this.request,
        this.dayData
      );
    } catch (error) {
      // console.error('Error handling Excel file:', error);
    } finally {
      // this.trnDocumentGeneratingButtonSpiner = false;
    }
  }

  navBack() {
    this.navCtrl.pop();
  }
}
