import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { InfiniteScrollCustomEvent } from '@ionic/angular';
import { Subscription, first, filter } from 'rxjs';
import { OrdersMergeService } from '../../services/orders-merge.service';
import { Order, OrdersResultDto } from '../../dto/order.dto';
import { OrdersService } from '../../services/orders.service';
import { OrdersListDto } from '../../dto/orders-list.dto';
import { MenuTabChangeService } from '../../services/menu-tab-change.service';
import { OrderChangesService } from '../../services/order-changes.service';
import { ArchiveFilter } from '../header/header.component';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit, OnChanges {
  @Input() refreshData: string;

  @Output() totalCount = new EventEmitter<number>();

  @Input() ordersListData: OrdersListDto;

  @Input() tabName: string;

  ordersItems: Order[] = [];
  limit: number = 10;
  loading: boolean = false;
  noData: boolean = false;
  isNoMoreData: boolean;
  isLoadedLess: boolean;
  isRefresherDisabled: boolean;

  refreshDataSubscr: Subscription;

  private loadingIds: string = '';
  private offset: number = 0;

  constructor(
    private ordersService: OrdersService,
    private tabChangeService: MenuTabChangeService,
    private orderChangesService: OrderChangesService,
    private ordersMergeService: OrdersMergeService
  ) { }

  ngOnInit(): void {
    this.refreshDataSubscr = this.tabChangeService.refreshData
      .pipe(filter((curTabName) => this.tabName === curTabName))
      .subscribe(() => {
        this.ordersItems = [];

        this.loading = true;
        this.isRefresherDisabled = true;
        this.reloadList();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      !changes['ordersListData']?.firstChange &&
      changes['ordersListData']?.currentValue?.searchTerms !==
      changes['ordersListData']?.previousValue?.searchTerms
    ) {
      const { searchTerms } = this.ordersListData;
      if (searchTerms) {
        this.loadingIds = searchTerms
          .map((obj) => `${obj.region.id}|${obj.district.id}`)
          .join();
      } else {
        this.loadingIds = '';
      }
      if (!this.loading) {
        this.reloadList();
      }
    }
  }

  onIonInfinite(event: any) {
    this.offset += this.limit;
    if (this.isLoadedLess) {
      (event as InfiniteScrollCustomEvent).target.complete();
      this.isNoMoreData = true;
      return;
    }

    this.ordersService
      .getOrdersList(
        this.limit,
        this.offset,
        this.loadingIds,
        this.ordersListData.statuses
      )
      .pipe(first())
      .subscribe((res: OrdersResultDto) => {
        this.pushOrderToList(res.data.requests);
        this.isLoadedLess = res.data.requests.length < this.limit;
        this.isNoMoreData = res.data.requests.length === 0;
        (event as InfiniteScrollCustomEvent).target.complete();
      });
  }

  reloadList(event?: any) {
    this.offset = 0;
    this.loading = !event;
    this.isRefresherDisabled = !event;

    this.ordersService
      .getOrdersList(
        this.limit,
        this.offset,
        this.loadingIds,
        this.ordersListData.statuses
      )
      .pipe(first())
      .subscribe((res: OrdersResultDto) => {
        this.setOrderList(res.data.requests);
        this.isLoadedLess = res.data.requests.length < this.limit;
        this.totalCount.emit(res.data.total);
        this.noData = this.ordersItems.length === 0;
        event?.target?.complete();
        this.loading = false;
        this.isRefresherDisabled = false;
      });
  }

  setOrderList(data: Order[]) {
    let filteredData = [...data];
    if (this.tabName === 'archived-requests') {
      if (this.ordersListData.archiveListFilter === ArchiveFilter.LOADED) {
        filteredData = filteredData.filter(
          (el) => el.deliveredWeightInKilograms > 0
        );
      }
      if (this.ordersListData.archiveListFilter === ArchiveFilter.UNLOADED) {
        filteredData = filteredData.filter(
          (el) => el.deliveredWeightInKilograms === 0
        );
      }
    }
    this.ordersItems = filteredData;
  }

  pushOrderToList(data: Order[]) {
    let filteredData = [...data];
    if (this.tabName === 'archived-requests') {
      if (this.ordersListData.archiveListFilter === ArchiveFilter.LOADED) {
        filteredData = filteredData.filter(
          (el) => el.deliveredWeightInKilograms > 0
        );
      }
      if (this.ordersListData.archiveListFilter === ArchiveFilter.UNLOADED) {
        filteredData = filteredData.filter(
          (el) => el.deliveredWeightInKilograms === 0
        );
      }
    }
    this.ordersItems.push(...filteredData);
  }

  setLoadingStatus(status: boolean) {
    this.loading = status;
  }
}
