<ion-header class="full grey">
  <ion-toolbar>
    <div class="container">
      <div class="header">
        <h2 class="title">Поиск по региону</h2>
      </div>
      <div (click)="cancel()" class="ion-activatable ripple-parent circle">
        <img src="assets/icon/close-icon.svg" alt="" />
        <ion-ripple-effect> </ion-ripple-effect>
      </div>
      <ion-searchbar
        [debounce]="500"
        #searchBar
        class="light"
        clear-icon="close-outline"
        placeholder="Поиск"
        (ionInput)="searchbarInput($event)"
      ></ion-searchbar>
    </div>
  </ion-toolbar>
</ion-header>
<app-offline-mode></app-offline-mode>
<ion-content class="ion-padding">
  <ion-list id="modal-list" [inset]="true" *ngIf="searchBar.value">
    <ion-item *ngFor="let item of filteredItems" (click)="selectItem(item)">
      <div *ngIf="!item.district.name">
        {{ item.region.name }}
      </div>
      <div *ngIf="item.district.name">
        {{ item.district.name
        }}<span class="region-item-text">, {{ item.region.name }}</span>
      </div>
    </ion-item>
  </ion-list>
  <div *ngIf="!searchBar.value">
    <ion-chip *ngFor="let selectedItem of selectedItems">
      <ion-label>
        <ng-container *ngIf="!selectedItem.district.name">
          {{ selectedItem.region.name }}
        </ng-container>
        <ng-container *ngIf="selectedItem.district.name">
          {{ selectedItem.district.name }}
        </ng-container>
      </ion-label>
      <ion-icon
        class="remove-icon"
        name="close"
        (click)="removeSelection(selectedItem)"
      ></ion-icon>
    </ion-chip>
  </div>
</ion-content>
<ion-footer>
  <ion-button class="full light large" mode="md" (click)="confirm()">
    Применить
  </ion-button>
</ion-footer>
