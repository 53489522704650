import { PassportData } from './day.dto';
import { CellData } from './excel-generation-data.dto';
import { RtsDay, RtsVehicle } from './get-rts-result.dto';
import { Status } from './status.enum';

export interface Attribute {
  prefix: string;
  name: string;
  value: string;
  unit: string;
}

export interface ActionLog {
  action: string;
  datetime: string;
  parameters: {
    [key: string]: string;
  };
}

export interface BuyerData {
  address: string;
  id: string;
  inn: string;
  kpp: string;
  orgName: string;
  phone: string;
}

export interface LoadingPointData {
  regionName: string;
  address: string;
  districtId: number;
  regionId: number;
  hasScales: boolean;
  hasTrawls: boolean;
  heightLimits: 0;
  loadingMethod: string;
  maxTonPerDay: number;
  scaleLength: number;
  title: string;
  workFrom: string;
  workTo: string;
  owner: {
    inn: string;
    name: string;
  };
}

export interface UnloadingPointData {
  regionName: string;
  address: string;
  districtId: number;
  regionId: number;
  title: string;
}

export interface SellerData {
  id: number;
  inn: string;
  orgName: string;
  phone: string;
}

export interface ForwarderData {
  address: string;
  id: number;
  inn: string;
  orgName: string;
  phone: string;
  kpp: string;
  ogrnip?: string;
  email?: string;
  passport?: PassportData;

  bankDetails: string;
}

export class Order {
  buyer: BuyerData;
  createdDate: number;
  cropYear: number;
  smartseedsOrderId: number;
  declarationOfConformityDate: number;
  declarationOfConformityNumber: string;
  distance: number;
  forwarder: ForwarderData;
  forwarderFee: number;
  shipmentDates: string[];
  freightAttributes: Attribute[];
  freightName: string;
  id: number;
  loading: LoadingPointData;
  quarantineCertificate: boolean;
  deliveredWeightInKilograms: number;
  registry: {
    completed: boolean;
    days: RtsDay[];
  };

  // registry: {
  //   completed: boolean;
  //   days: Day[];
  //   smartSeedsId: string;
  //   // разобраться что это сделать enum
  //   smartSeedsOrderType: string;
  //   //

  //   // createdDate: number;
  //   // id: number;
  //   // updatedDate: number;
  // };

  requestedWeightInTons: number;
  sampling: boolean;
  seller: SellerData;
  status: Status;
  testingProtocolDate: number;
  testingProtocolNumber: string;
  unloading: UnloadingPointData;
  updatedDate: number;
  version: number;

  // deliveredWeightInKilograms: number;
  // loadedWeightInKilograms: number;

  log: ActionLog[];

  constructor(data: Partial<Order>) {
    Object.assign(this, data);
  }

  public startDay(dayId: string) {
    if (this.status !== Status.IN_WORK) {
      this.status = Status.IN_WORK;
    }
    this.registry.days = this.registry.days.map((day: RtsDay) => {
      if (day.orderNumber === dayId) {
        return {
          ...day,
          started: true
        };
      }
      return day;
    });
    return this;
  }

  public checkFirstDayStarted() {
    return !this.registry.days.some((day: RtsDay) => day.started);
  }

  public endDay(dayId: string) {
    this.registry.days = this.registry.days.map((day: RtsDay) => {
      if (day.orderNumber === dayId) {
        return {
          ...day,
          completed: true
        };
      }
      return day;
    });
    return this;
  }

  public getDayById(dayId: string): RtsDay | null {
    if (this.registry?.days) {
      const day = this.registry.days.find(
        (elm: any) => elm.orderNumber.replace('|', '') === dayId
      );
      return day || null;
    }

    return null;
  }

  public getVehicleById(vehicleId: string): RtsVehicle | null {
    // eslint-disable-next-line no-restricted-syntax
    for (const day of this.registry.days) {
      const vehicle = day.vehicles.find(v => v.id.toString() === vehicleId);
      if (vehicle) {
        return vehicle;
      }
    }
    return null;
  }

  public updateVehicle(dayId: string, vehicleId: string, loadData: any) {
    const day = this.registry.days.find(
      d => d.orderNumber.replace('|', '') === dayId
    );
    if (day) {
      const vehicle = day.vehicles.find(v => v.id === +vehicleId);

      if (vehicle) {
        vehicle.loadingData = {
          netWeight: loadData.netWeight,
          quarantineCertificateNumber: loadData.quarantineCertificateNumber,
          sdizNumber: loadData.sdizNumber,
          quarantineCertificateDate: loadData.quarantineCertificateDate,
          sdizDate: loadData.sdizDate,
          tareWeight: loadData.tareWeight,
          grossWeight: loadData.grossWeight,
          declarationOfConformityDate: loadData.declarationOfConformityDate,
          declarationOfConformityNumber: loadData.declarationOfConformityNumber,
          testingProtocolDate: loadData.testingProtocolDate,
          testingProtocolNumber: loadData.testingProtocolNumber,
          lastUpdate: Date.now()
        };
      }
    }
    return this;
  }

  public addVehicle(dayId: string, newVehicle: RtsVehicle) {
    const day = this.registry.days.find(
      d => d.orderNumber.replace('|', '') === dayId
    );
    if (day) {
      day.vehicles.push(newVehicle);
    }
    return this;
  }

  public editVehiclesBaseData(
    dayId: string,
    vehicleId: number,
    editVehicle: any
  ) {
    const day = this.registry.days.find(
      d => d.orderNumber.replace('|', '') === dayId
    );
    if (day) {
      const vehicle = day.vehicles.find(v => v.id === vehicleId);

      if (vehicle) {
        vehicle.registrationNumber = editVehicle.registrationNumber;
        vehicle.trailerRegistrationNumber =
          editVehicle.trailerRegistrationNumber;
        vehicle.driverName = editVehicle.driverName;
        vehicle.driverPhone = editVehicle.driverPhone;
        vehicle.carrierName = editVehicle.carrierName;
        vehicle.carrierInn = editVehicle.carrierInn;
        if (editVehicle.trailerRegistrationNumber) {
          vehicle.hasTrailer = true;
        }
      }
    }
    return this;
  }

  public generateTtnActions(data: CellData[]) {
    if (this.status === Status.ACCEPTED || Status.IN_WORK) {
      if (!this.log) {
        this.log = [];
      }

      const logObj: ActionLog = {
        action: 'ttn_generation',
        datetime: Date.now().toString(),
        parameters: {}
      };

      data.forEach((el: CellData) => {
        logObj.parameters[el.name] = el.value;
      });

      this.log.push(logObj);
    }
  }

  public generateTrnActions(data: CellData[]) {
    if (this.status === Status.ACCEPTED || Status.IN_WORK) {
      if (!this.log) {
        this.log = [];
      }

      const logObj: ActionLog = {
        action: 'trn_generation',
        datetime: Date.now().toString(),
        parameters: {}
      };

      data.forEach((el: CellData) => {
        logObj.parameters[el.name] = el.value;
      });

      this.log.push(logObj);
    }
  }

  public generateForwarderReportActions(data: CellData[]) {
    if (!this.log) {
      this.log = [];
    }

    const logObj: ActionLog = {
      action: 'forwarderReport_generation',
      datetime: Date.now().toString(),
      parameters: {}
    };

    data.forEach((el: CellData) => {
      logObj.parameters[el.name] = el.value;
    });

    this.log.push(logObj);
  }

  public generateForwarderNoteActions(data: CellData[]) {
    if (this.status === Status.ACCEPTED || Status.IN_WORK) {
      if (!this.log) {
        this.log = [];
      }

      const logObj: ActionLog = {
        action: 'forwarderNote_generation',
        datetime: Date.now().toString(),
        parameters: {}
      };

      data.forEach((el: CellData) => {
        logObj.parameters[el.name] = el.value;
      });

      this.log.push(logObj);
    }
  }

  public generateRTSActions(data: CellData[]) {
    if (this.status === Status.ACCEPTED || Status.IN_WORK) {
      if (!this.log) {
        this.log = [];
      }

      const logObj: ActionLog = {
        action: 'RTS_generation',
        datetime: Date.now().toString(),
        parameters: {}
      };

      data.forEach((el: CellData) => {
        logObj.parameters[el.name] = el.value;
      });

      this.log.push(logObj);
    }
  }

  public checkCompletedStatus() {
    if (this?.registry?.days && this?.registry?.days.length) {
      return (
        this.registry.days.every(day => day.completed) &&
        this.status === Status.IN_WORK
      );
    }
    return false;
  }

  public statusIsArchive() {
    return this.status === Status.COMPLETED || this.status === Status.CLOSED;
  }

  public getTotalNetWeight() {
    let totalWeight = 0;
    this.registry.days.forEach(day => {
      if (day.completed) {
        day.vehicles.forEach(car => {
          if (car?.loadingData?.netWeight) {
            totalWeight += car.loadingData.netWeight;
          }
        });
      }
    });
    return totalWeight;
  }

  getDayNumberById(dayId: string) {
    if (this.registry?.days) {
      return this.registry.days.findIndex(el => el.orderNumber === dayId) + 1;
    }
    return 1;
  }

  public completeOrder() {
    this.status = Status.COMPLETED;
  }
}
export interface OrdersDataDto {
  requests: Order[];
  total: number;
  currentPageSize: number;
}

export interface OrdersResultDto {
  data: OrdersDataDto;
  success: boolean;
}

export interface OrderResultDto {
  data: Order;
  success: boolean;
}
