import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AppModeStatus, OfflineModeService } from '../../services/offline-mode.service';
import { OfflineModalComponent } from '../offline-modal/offline-modal.component';

@Component({
  selector: 'app-offline-mode',
  templateUrl: './offline-mode.component.html',
  styleUrls: ['./offline-mode.component.scss'],
})
export class OfflineModeComponent implements OnInit {
  isOfflineMode: boolean;

  constructor(
    private appModeService: OfflineModeService,
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {
    this.appModeService.appModeStatus$
      .subscribe((mode: AppModeStatus) => this.isOfflineMode = mode === AppModeStatus.OFFLINE);
  }

  async openOfflineModal() {
    const modal = await this.modalCtrl.create({
      component: OfflineModalComponent,
      breakpoints: [0, 1],
      initialBreakpoint: 1,
    });
    modal.present();
  }
}
