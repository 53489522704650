import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorToastService {
  public errorSubject = new Subject<number>();
  sendError(errorCode: number) {
    this.errorSubject.next(errorCode);
  }

  getErrorObservable() {
    return this.errorSubject.asObservable();
  }
}
