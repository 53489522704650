<ion-app>
  <ion-router-outlet></ion-router-outlet>
  <ion-toast
    #errorToast
    icon="globe"
    position="middle"
    class="custom-toast"
    message="Что-то пошло не так, попробуйте позже..."
    [duration]="2000"
  ></ion-toast>

  <ion-toast
    #smartSeedsToast
    icon="close-circle-outline"
    position="middle"
    class="custom-toast"
    message="Для этого действия необходимо завершить интеграцию."
    [duration]="3000"
  ></ion-toast>

  <ion-toast
    #smartSeedsError
    icon="close-circle-outline"
    position="middle"
    class="custom-toast"
    message="Ошибка Smart Seeds. Попробуйте позже"
    [duration]="3000"
  ></ion-toast>

  <ng-container style="z-index: 999999999" #modalContainer></ng-container>
</ion-app>
